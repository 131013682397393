import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useUploadStore from '../../uploadStore/useUploadStore';
import './ProgressModal.css';
import { MdCheckCircle } from "react-icons/md";
import { IoMdRefreshCircle } from "react-icons/io";
import { RiUploadCloudFill } from "react-icons/ri";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const ProgressModal = () => {
    const [uploadProgress, setUploadProgress] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);

    const [failedUploads, setFailedUploads] = useState([]);
    const [successUploads, setSuccessUploads] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const files = useUploadStore((state) => state.files);
    const cleanFiles = useUploadStore((state) => state.cleanFiles);

    useEffect(() => {
        const newFiles = files.filter(file => !uploadProgress.hasOwnProperty(file.id));
        if (newFiles.length > 0) {
            setModalIsOpen(true);
            handleUpload(newFiles);
        }
    }, [files]);

    // useEffect(() => {
    //     if (successUploads.length === files.length) {
    //         const timer = setTimeout(() => {
    //             setModalIsOpen(false);
    //             cleanFiles();
    //         }, 2000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [successUploads]);
    

    const handleUpload = async (filesToUpload) => {
        setIsUploading(true);

        const uploadPromises = filesToUpload.map(async (fileObj) => {
            const { id, file, signedUrl } = fileObj;
            const formData = new FormData();
            formData.append('file', file);

            if (signedUrl) {
                const options = {
                    onUploadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(prevProgress => ({
                            ...prevProgress,
                            [id]: progress
                        }));
                    }, headers: {
                        'Content-Type': 'application/zip-compressed',
                    },
                };

                return axios.put(signedUrl, file, options).then(response => {
                    console.log("response", response);
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [id]: 100
                    }));
                    setSuccessUploads(prev => [...prev, id]);
                }).catch(error => {
                    console.log("error", error);
                    setFailedUploads(prev => [...prev, id]);
                });
            }
        });

        await Promise.all(uploadPromises);      
        setIsUploading(false);
    };

    const handleRetryUpload = (fileId) => {
        const fileToRetry = files.find(fileObj => fileObj.id === fileId);
        if (fileToRetry) {
            setFailedUploads(prev => prev.filter(failedId => failedId !== fileId));
            setUploadProgress(prevProgress => ({
                ...prevProgress,
                [fileId]: 0
            }));
            handleUpload([fileToRetry]);
        }
    };

    return (
        <div className='modal-container'>
            <div className={`custom-modal ${modalIsOpen ? 'open' : ''} ${isMinimized ? 'minimized' : ''}`}>
                <div className="modal-content">
                    <div className='modal-header'>
                        <span className='modal-heading'>Uploading Files</span>
                        <span className='toggle-btn' onClick={() => setIsMinimized(!isMinimized)}>
                            {isMinimized ? <FaChevronUp /> : <FaChevronDown />}
                        </span>
                    </div>
                    {!isMinimized && (
                        files && [...files].reverse().map((fileObj) => (
                            <div key={fileObj.id} className='file-content'>
                                <div className='modal-content-header'>
                                    {failedUploads.includes(fileObj.id) ? (
                                        <span className='retry-icon'>
                                            <IoMdRefreshCircle
                                                onClick={() => handleRetryUpload(fileObj.id)} />
                                        </span>
                                    ) : successUploads.includes(fileObj.id) ? (
                                        <span className='success-icon'><MdCheckCircle /></span>
                                    ) : (
                                        <span className='upload-btn'>
                                            <RiUploadCloudFill />
                                        </span>
                                    )}
                                    <span className='file-name'>{fileObj.file.name.length > 40 ? `${fileObj.file.name.slice(0, 40)}...` : fileObj.file.name}</span>
                                </div>
                                <div className='progress-result'>
                                    {failedUploads.includes(fileObj.id) ? (
                                        <div className="upload-failed">
                                            <span className="failed-message">This file upload failed</span>
                                        </div>
                                    ) : successUploads.includes(fileObj.id) ? (
                                        <div className="upload-success">This file upload was successful</div>
                                    ) : (
                                        <div className="progress-bar"
                                            style={{ width: `${uploadProgress[fileObj.id] || 0}%` }}>
                                            {uploadProgress[fileObj.id] || 0}%
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
                {isMinimized && (
                    <div className="minimized-tab" onClick={() => setIsMinimized(!isMinimized)}></div>
                )}
            </div>
        </div>
    );
};

export default ProgressModal;
