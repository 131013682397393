import react, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CInputGroup,
    CFormSelect,
    CFormLabel,
    CInputGroupText,
    CFormCheck,
    CButton,
    CFormFeedback,
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { useNavigate } from "react-router-dom";

const AddTag = (props) => {
    const navigate = useNavigate();
    const handleShow = () => {
        setFormData({
            tag_name: "",
            tag_type: ""
        });
        setFlag(false);
        setErrors({});
        setSubmitted(false);
    };

    const [formData, setFormData] = useState({ tag_name: "", tag_type: "" });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const [flag, setFlag] = useState(false);

    const handleAddTagFlag = (flag) => {
        console.log("add flag", flag);
        flag = true;
        props.addTagFlag(flag);
    }

    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setSubmitted(true);
            const user = localStorage.getItem("user");
            console.log("auth user", user)
            const token = JSON.parse(user).token;

            console.log("fields", formData);
            const response = await axios.post(`${API_BASE_URL}/add-tags`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("successfully added tag", response);
            handleAddTagFlag(setFlag(true));
            props.onHide();
        } catch (err) {
            console.log("error in adding tag", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
            }
        } finally {
            setSubmitted(false);
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Tag
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Tag Name"
                            name="tag_name"
                            onChange={handleChange}
                            isInvalid={!!errors.tag_name}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.tag_name}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="Tag Type"
                            name="tag_type"
                            onChange={handleChange}
                            isInvalid={!!errors.tag_type}
                        >
                            <option>Choose tag type</option>
                            <option>Faculty</option>
                            <option>Course</option>
                            <option>Others</option>
                        </CFormSelect>
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.tag_type}</CFormFeedback>
                    </CCol>
                </CForm>
            </Modal.Body>
            <Modal.Footer>
                <CButton style={btn} type="submit" onClick={handleSubmit} disabled={submitted}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default AddTag;
