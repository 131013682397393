import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { AuthContextProvider } from './views/context/AuthContext';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
        

createRoot(document.getElementById('root')).render(
  <AuthContextProvider>
    <Provider store={store}>
        <App />
    </Provider>
  </AuthContextProvider>
  
)
reportWebVitals()
