import React from "react";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import { SiManageiq } from "react-icons/si";

const adminItems = [
  {
    component: CNavItem,
    name: "User",
    to: "/user-list",
  },
  {
    component: CNavItem,
    name: "Programs",
    to: "/program-list",
  },
  {
    component: CNavItem,
    name: "Courses",
    to: "/course-list",
  },
  {
    component: CNavItem,
    name: "Video",
    to: "/video-list",
  },
  {
    component: CNavItem,
    name: "Tag",
    to: "/tag-list",
  },
  {
    component: CNavItem,
    name: "Faculty",
    to: "/faculty-list",
  },
  {
    component: CNavItem,
    name: "University",
    to: "/university-list",
  },
  {
    component: CNavItem,
    name: "Subscription List",
    to: "/subscription-list",
  },
  {
    component: CNavItem,
    name: "University Access",
    to: "/university-subscription-list",
  },
]

const uniAdminItems = [
  {
    component: CNavItem,
    name: "My Subscriptions",
    to: "/my-subscription-list1",
  },
]

const _nav = [
  {
    component: CNavGroup,
    name: "Management",
    icon: (
      <span style={{ fontSize: "25px", marginRight: "1vw", marginTop: "-5px" }}>
        <SiManageiq />
      </span>
    ),
    toggleable:false,
    items: adminItems,  
  },
  {
    component: CNavGroup,
    name: "Management",
    icon: (
      <span style={{ fontSize: "25px", marginRight: "1vw", marginTop: "-5px" }}>
        <SiManageiq />
      </span>
    ),
    // expanded:true,
    toggleable:false,
    items: uniAdminItems,  
  },
];

export default _nav;
