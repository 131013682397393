import react, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CFormTextarea,
    CFormSelect,
    CButton,
    CFormFeedback,
    CSpinner
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { CiCircleRemove } from "react-icons/ci";
import useUploadStore from "../../uploadStore/useUploadStore";

const EditVideo = (props) => {
    const handleShow = () => {
        setErrors({
            video_title: "",
            file: "",
            description: "",
            video_type: "",
            segment_type: "",
            addCourses: "",
            addFaculty: "",
            addTags: "",
        });
        setSubmitted(false);
    };

    const [tagsField, setTagsField] = useState(['']);
    const [tagList, setTagList] = useState();

    const [coursesField, setCoursesField] = useState(['']);
    const [courseList, setCourseList] = useState();

    const [facultyField, setFacultyField] = useState(['']);
    const [facultyList, setFacultyList] = useState();

    const videoData = props.video;
    const tagsData = props.videoTags;
    const coursesData = props.videoCourses;
    const facultyData = props.videoFaculty;

    const videoTypeMapping = {
        1: "H5P",
        2: "HTML5"
    }

    const segmentTypeMapping = {
        1: "Lecture",
        2: "Lab"
    }

    const videoTypeValue = videoData ? videoData.video_type : '';
    const videoTypeName = videoTypeMapping[videoTypeValue];

    const segmentTypeValue = videoData ? videoData.segment_type : '';
    const segmentTypeName = segmentTypeMapping[segmentTypeValue];

    const initialFormData = {
        video_title: videoData ? videoData.video_title : "",
        video_url: videoData ? videoData.video_url : "",
        description: videoData ? videoData.description : "",
        video_type: videoTypeName || "",
        segment_type: segmentTypeName || "",
        addCourses: [],
        deleteCourses: [],
        addFaculty: [],
        deleteFaculty: [],
        addTags: [],
        deleteTags: [],
        previousVideoURL: "",
        isFileUpdated: false
    };

    const [errors, setErrors] = useState({
        video_title: "",
        file: "",
        description: "",
        video_type: "",
        segment_type: "",
        addCourses: "",
        addFaculty: ""
    });

    const [formData, setFormData] = useState(initialFormData);
    useEffect(() => {
        setFormData(initialFormData);
    }, [videoData])

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("field value", name, value)
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    useEffect(() => {
        console.log("authenticated user", user)
        const token = JSON.parse(user).token;

        const getCourse = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-courses`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("course List", response);
                setCourseList(response.data.data);
            } catch (error) {
                console.error("module error", error);
            }
        };

        const getFaculty = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-faculty`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("faculty List", response);
                setFacultyList(response.data.data);
            } catch (error) {
                console.error("faculty error", error);
            }
        };

        if (user) {
            getCourse();
            getFaculty();
        }
    }, []);

    useEffect(() => {
        if (coursesData && coursesData.length > 0) {
            const videoCoursesData = coursesData.map(course => ({
                courseId: course.course_id,
                courseName: course.name,
                videoId: course.video_id
            }));
            setCoursesField(videoCoursesData);
        } else {
            setCoursesField([]);
        }
    }, [coursesData]);

    const [selectedCourseId, setSelectedCourseId] = useState();
    const handleCourseOptions = (course_id) => {
        setSelectedCourseId(course_id);
    }

    const handleCourseChange = (index, e) => {
        const selectedCourseId = parseInt(e.target.value, 10);
        const selectedCourse = courseList.find(course => course.course_id === selectedCourseId);
        console.log(selectedCourse);

        setFormData((prev) => {
            const newAddCourses = [...(prev.addCourses || [])];

            if (selectedCourse && selectedCourse.course_id) {
                newAddCourses[index] = selectedCourse.course_id;

                if (coursesField[index] && coursesField[index].courseId) {
                    const newDeleteCourses = [...(prev.deleteCourses || []), coursesField[index].courseId];
                    return {
                        ...prev,
                        addCourses: newAddCourses.filter(Boolean),
                        deleteCourses: newDeleteCourses
                    };
                }
            } else {
                console.error("Selected course is undefined or missing course_id property for addCourses");
            }

            return {
                ...prev,
                addCourses: newAddCourses.filter(Boolean),
            };
        });
        setErrors((prev) => ({ ...prev, addCourses: "" }));
    };

    const addCourse = () => {
        const newCoursesData = [...coursesField, "newCourse"];
        setCoursesField(newCoursesData);
    };

    const removeCourse = (index) => {
        console.log('index to be removed', index);
        const newCoursesData = [...coursesField];

        setFormData((prev) => {
            let courseIdTobeDeleted;
            if (coursesField[index].courseId) {
                courseIdTobeDeleted = coursesField[index].courseId;
            } else {
                courseIdTobeDeleted = formData.addCourses[index];
            }
            const newDeleteCourses = [...(prev.deleteCourses || []), courseIdTobeDeleted];
            const newAddCourses = [...(prev.addCourses || [])];
            newAddCourses.splice(index, 1);
            return {
                ...prev,
                deleteCourses: newDeleteCourses,
                addCourses: newAddCourses.filter(Boolean)
            };
        });

        newCoursesData.splice(index, 1);
        setCoursesField(newCoursesData);
    };

    useEffect(() => {
        if (facultyData && facultyData.length > 0) {
            const videoFacultyData = facultyData.map(faculty => ({
                facultyId: faculty.faculty_id,
                facultyFirstName: faculty.first_name,
                facultyLastName: faculty.last_name,
                videoId: faculty.video_id
            }));
            setFacultyField(videoFacultyData);
        } else {
            setFacultyField([]);
        }
    }, [facultyData]);

    const [selectedFacultyId, setSelectedFacultyId] = useState();
    const handleFacultyOptions = (faculty_id) => {
        setSelectedFacultyId(faculty_id);
    }

    const handleFacultyChange = (index, e) => {
        const selectedFacultyId = parseInt(e.target.value, 10);
        const selectedFaculty = facultyList.find(faculty => faculty.faculty_id === selectedFacultyId);
        console.log(selectedFaculty);

        setFormData((prev) => {
            const newAddFaculties = [...(prev.addFaculty || [])];

            if (selectedFaculty && selectedFaculty.faculty_id) {
                newAddFaculties[index] = selectedFaculty.faculty_id;

                if (facultyField[index] && facultyField[index].facultyId) {
                    const newDeleteFaculties = [...(prev.deleteFaculty || []), facultyField[index].facultyId];
                    return {
                        ...prev,
                        addFaculty: newAddFaculties.filter(Boolean),
                        deleteFaculty: newDeleteFaculties
                    };
                }
            } else {
                console.error("Selected faculty is undefined or missing faculty_id property for addFaculties");
            }

            return {
                ...prev,
                addFaculty: newAddFaculties.filter(Boolean),
            };
        });
        setErrors((prev) => ({ ...prev, addFaculty: "" }));
    };

    const addFaculty = () => {
        const newFacultyData = [...facultyField, "newFaculty"];
        setFacultyField(newFacultyData);
    };

    const removeFaculty = (index) => {
        console.log('index to be removed', index);
        const newFacultyData = [...facultyField];

        setFormData((prev) => {
            let facultyIdTobeDeleted;
            if (facultyField[index].facultyId) {
                facultyIdTobeDeleted = facultyField[index].facultyId;
            } else {
                facultyIdTobeDeleted = formData.addFaculty[index];
            }
            const newDeleteFaculties = [...(prev.deleteFaculty || []), facultyIdTobeDeleted];
            const newAddFaculties = [...(prev.addFaculty || [])];
            newAddFaculties.splice(index, 1);
            return {
                ...prev,
                deleteFaculty: newDeleteFaculties,
                addFaculty: newAddFaculties.filter(Boolean)
            };
        });

        newFacultyData.splice(index, 1);
        setFacultyField(newFacultyData);
    };

    useEffect(() => {
        console.log("auth user", user)
        const token = JSON.parse(user).token;

        const getTagList = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-tags`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("tag list", response);
                setTagList(response.data.data);

            } catch (error) {
                console.error("Error fetching tags:", error);
            }
        };
        if (user) {
            getTagList();
        }
    }, [])

    useEffect(() => {
        if (tagsData && tagsData.length > 0) {
            const videoTagsData = tagsData.map(tag => ({
                tagId: tag.tag_id,
                tagName: tag.tag_name,
                videoId: tag.video_id
            }));
            setTagsField(videoTagsData);
        } else {
            setTagsField([]);
        }
    }, [tagsData]);

    const [selectedTagId, setSelectedTagId] = useState();
    const handleOptions = (tag_id) => {
        setSelectedTagId(tag_id);
    }

    const handleTagChange = (index, e) => {
        const selectedTagId = parseInt(e.target.value, 10);
        const selectedTag = tagList.find(tag => tag.tag_id === selectedTagId);
        console.log(selectedTag);

        setFormData((prev) => {
            const newAddTags = [...(prev.addTags || [])];

            if (selectedTag && selectedTag.tag_id) {
                newAddTags[index] = selectedTag.tag_id;

                if (tagsField[index] && tagsField[index].tagId) {
                    const newDeleteTags = [...(prev.deleteTags || []), tagsField[index].tagId];
                    return {
                        ...prev,
                        addTags: newAddTags.filter(Boolean),
                        deleteTags: newDeleteTags
                    };
                }
            } else {
                console.error("Selected tag is undefined or missing tag_id property for addTags");
            }

            return {
                ...prev,
                addTags: newAddTags.filter(Boolean),
            };
        });
        setErrors((prev) => ({ ...prev, addTags: "" }));
    };

    const addTag = () => {
        const newTagsData = [...tagsField, "newTag"];
        setTagsField(newTagsData);
    };

    const removeTag = (index) => {
        console.log('index to be removed', index);
        const newTagsData = [...tagsField];

        setFormData((prev) => {
            let tagIdTobeDeleted;
            if (tagsField[index].tagId) {
                // if pre-existing tag is deleted
                tagIdTobeDeleted = tagsField[index].tagId;
            } else {
                tagIdTobeDeleted = formData.addTags[index]
            }
            const newDeleteTags = [...(prev.deleteTags || []), tagIdTobeDeleted];
            const newAddTags = [...(prev.addTags || [])];
            newAddTags.splice(index, 1);
            return {
                ...prev,
                deleteTags: newDeleteTags,
                addTags: newAddTags.filter(Boolean)
            };
        });

        newTagsData.splice(index, 1);
        setTagsField(newTagsData);
    };

    console.log('form fields to be updated', formData);

    const [flag, setFlag] = useState(false);

    const handleEditVideoFlag = (flag) => {
        console.log("edit video flag", flag)
        props.editVideoFlag(flag);
    }

    const [selectedFiles, setSelectedFiles] = useState([]);
    const uploadFiles = useUploadStore((state) => state.addFiles);
    const [signedURL, setSignedURL] = useState([]);

    const handleZipFileChange = async (e) => {
        const fileError = await fileValidate(e.target.files[0], null);
        setErrors((prev) => ({
            ...prev,
            file: fileError,
        }));

        const fileName = e.target.files[0].name.replace(/\.[^/.]+$/, "").replace(/\s+/g, '-');
        let newFileExists = false;
        if (videoData.video_url !== fileName) {
            newFileExists = true;
            setFormData((prev) => ({
                ...prev,
                previousVideoURL: videoData.video_url,
                video_url: fileName,
                isFileUpdated: newFileExists
            }));
            const newFiles = Array.from(e.target.files);
            setSelectedFiles(newFiles);
        }
    };

    const isNonEmptyField = (value, fieldName) => {
        if (!value || (typeof value === 'string' && value.trim() === '')) {
            return `${fieldName} is required`;
        }
        return null;
    };

    const fileValidate = async (file, video_id) => {
        try {
            if (!file) {
                return `Please select a file`;
            } else if (!file.name.match(/\.(zip|h5p)$/i)) {
                return `Please upload a ZIP file`;
            } else {
                const token = JSON.parse(user).token;
                const response = await axios.get(`${API_BASE_URL}/check-zipfile/${file.name}/${video_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                console.log("response for zip-check", response);
                if (response) {
                    setSignedURL(response.data.preSignedURL);
                    return null;
                }
            }
        } catch (error) {
            console.error("Error checking zip file:", error);
            if (error.response && error.response.status === 400) {
                return `Zip file already exists.`;
            }
        }
    }

    const isNotEmptyArray = (array, fieldName) => {
        console.log(array, fieldName)
        if (!Array.isArray(array) || array.length === 0) {
            return { field: fieldName, message: `${fieldName} should not be empty` };
        }
        return null;
    };

    const checkValidation = async () => {
        const videoTitleError = isNonEmptyField(formData.video_title, 'Video Title');
        const descriptionError = isNonEmptyField(formData.description, 'Description');
        const videoTypeError = isNonEmptyField(formData.video_type, 'Video Type');
        const segmentTypeError = isNonEmptyField(formData.segment_type, 'Segment Type');
        const courseTypeError = isNotEmptyArray(formData.addCourses, 'Course');
        const facultyTypeError = isNotEmptyArray(formData.addFaculty, 'Faculty');

        setErrors({
            video_title: videoTitleError,
            description: descriptionError,
            video_type: videoTypeError,
            segment_type: segmentTypeError,
            addCourses: courseTypeError,
            addFaculty: facultyTypeError,
        });

        return (
            !videoTitleError &&
            !errors.file &&
            !descriptionError &&
            !videoTypeError &&
            !segmentTypeError
            // !courseTypeError &&
            // !facultyTypeError
        );
    };

    const user = localStorage.getItem("user");
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (await checkValidation()) {
                setSubmitted(true);
                const token = JSON.parse(user).token;
                console.log("form-data to be updated", formData)
                const response = await axios.put
                    (`${API_BASE_URL}/update-video/${videoData.video_id}`, formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    });
                if (formData.isFileUpdated) {
                    const filesToAdd = selectedFiles.map((file) => ({
                        file,
                        signedUrl: signedURL,
                    }));
                    await uploadFiles(filesToAdd);
                    setSelectedFiles([]);
                    setSignedURL("");
                }
                console.log("successfully updated video", response);
                if (response) {
                    handleEditVideoFlag(setFlag(true));
                    props.onHide();
                }
            }
        } catch (err) {
            console.log("error in editing video details and videoTags", err);
        } finally {
            setSubmitted(false);
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit New Video
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Video Title"
                            name="video_title"
                            value={formData.video_title}
                            onChange={handleChange}
                            isInvalid={!!errors.video_title}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.video_title}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        {formData.video_url && (
                            <div >
                                <div>Video URL</div>
                                <div style={{ color: "black", fontSize: "14px" }}>{formData.video_url}</div>
                                <br />
                            </div>
                        )}
                        <CFormInput
                            type="file"
                            name="file"
                            onChange={handleZipFileChange}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.file}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="Video Type"
                            name="video_type"
                            value={formData.video_type}
                            onChange={handleChange}
                            isInvalid={!!errors.video_type}
                        >
                            <option>Choose video type</option>
                            <option>H5P</option>
                            <option>HTML5</option>
                        </CFormSelect>
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.video_type}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="Segment Type"
                            name="segment_type"
                            value={formData.segment_type}
                            onChange={handleChange}
                            isInvalid={!!errors.segment_type}
                        >
                            <option>Choose segment type</option>
                            <option>Lecture</option>
                            <option>Lab</option>
                        </CFormSelect>
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.segment_type}</CFormFeedback>
                    </CCol>

                    <CCol md={12}>
                        <CFormTextarea
                            rows={3}
                            label="Description"
                            name="description"
                            placeholder="Add a description for the video"
                            onChange={handleChange}
                            value={formData.description}
                            isInvalid={!!errors.description}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.description}</CFormFeedback>
                    </CCol>

                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Courses</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addCourse}
                                style={btn}
                            >Add</CButton>

                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {coursesData && coursesField.map((course, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a module"
                                            onChange={(e) => handleCourseChange(index, e)}
                                            required
                                        >
                                            <option value={course.courseId}
                                                onClick={() => handleCourseOptions(course.courseId)}>
                                                {course.courseName}</option>
                                            {courseList && courseList.map((options) => (
                                                course.courseId !== options.course_id ?
                                                    <option key={options.course_id} value={options.course_id}
                                                        onClick={() => handleCourseOptions(options.course_id)}>
                                                        {options.name}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeCourse(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Faculty</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addFaculty}
                                style={btn}
                            >Add</CButton>

                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {facultyField && facultyField.map((faculty, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a faculty"
                                            // value={tag.tagName}
                                            onChange={(e) => handleFacultyChange(index, e)}
                                            required
                                        >
                                            <option value={faculty.facultyId}
                                                onClick={() => handleFacultyOptions(faculty.facultyId)}>
                                                {faculty.facultyFirstName} {faculty.facultyLastName}</option>
                                            {facultyList && facultyList.map((options) => (
                                                faculty.facultyId !== options.faculty_id ?
                                                    <option key={options.faculty_id} value={options.faculty_id}
                                                        onClick={() => handleFacultyOptions(options.faculty_id)}>
                                                        {options.first_name} {options.last_name}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeFaculty(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Tags</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addTag}
                                style={btn}
                            >Add</CButton>

                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {tagsField && tagsField.map((tag, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a tag"
                                            // value={tag.tagName}
                                            onChange={(e) => handleTagChange(index, e)}
                                            required
                                        >
                                            <option value={tag.tagId}
                                                onClick={() => handleOptions(tag.tagId)}>
                                                {tag.tagName}</option>
                                            {tagList && tagList.map((options) => (
                                                tag.tagId !== options.tag_id ?
                                                    <option key={options.tag_id} value={options.tag_id}
                                                        onClick={() => handleOptions(options.tag_id)}>
                                                        {options.tag_name}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeTag(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>
                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit} disabled={submitted}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default EditVideo;
