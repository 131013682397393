import react, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  CForm,
  CCol,
  CFormInput,
  CInputGroup,
  CFormSelect,
  CFormLabel,
  CInputGroupText,
  CFormCheck,
  CButton,
  CFormFeedback,
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { useNavigate } from "react-router-dom";
import { CiCircleRemove } from "react-icons/ci";

const AddUniversity = (props) => {
  const navigate = useNavigate();

  const handleShow = () => {
    setFormData({
      name: "",
      domain: "",
      address1: "",
      address2: "",
      phone_number: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      addDomains: [],
      deleteDomains: [],
      updateDomains: []
    });
    setFlag(false);
    setErrors({});
    setDomainError("");
    setSubmitted(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    address1: "",
    address2: "",
    phone_number: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    addDomains: [],
    deleteDomains: [],
    updateDomains: [],
  });

  const [errors, setErrors] = useState({});

  const [countries, setCountries] = useState();
  const user = localStorage.getItem("user");

  useEffect(() => {
    const getCountries = async () => {
      try {
        console.log("auth user", user)
        const token = JSON.parse(user).token;
        const response = await axios.get(`${API_BASE_URL}/get-countries`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("countries list", response);
        setCountries(response.data.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    }

    if (user) {
      getCountries();
    }
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const addDomain = () => {
    setFormData((prev) => ({
      ...prev,
      addDomains: [...prev.addDomains, ""],
    }));
  };

  const removeDomain = (index) => {
    const newValuesArray = [...formData.addDomains];
    newValuesArray.splice(index, 1);
    setFormData((prev) => {
      return {
        ...prev,
        addDomains: newValuesArray
      }
    })
  };

  const handleDomainChange = (e, index) => {
    console.log("indexxx", index)
    const newValuesArray = [...formData.addDomains];
    newValuesArray[index] = e.target.value;
    setFormData((prev) => {
      return {
        ...prev,
        addDomains: newValuesArray
      }
    })
    domainValidation(e.target.value);
  };

  console.log("add domains change", formData.addDomains)

  const [domainError, setDomainError] = useState("");
  const domainValidation = (domain) => {
    const domainRegex = /^(?!:\/\/)(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d+)?(?:\/[^\/\s]*)?$/;
    if (!domainRegex.test(domain)) {
      setDomainError("Invalid domain format")
    } else {
      setDomainError("")
    }
  }

  const [flag, setFlag] = useState(false);

  const handleAddUniFlag = (flag) => {
    console.log("add flag", flag);
    flag = true;
    props.addUniFlag(flag);
  }

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitted(true);
      const user = localStorage.getItem("user");
      console.log("auth user", user)
      const token = JSON.parse(user).token;

      console.log("fields", formData);

      const response = await axios.post(`${API_BASE_URL}/add-university`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("successfully added university", response);
      handleAddUniFlag(setFlag(true));
      props.onHide();
    } catch (err) {
      console.log("error in adding university", err);
      if (err.response.data.errors) {
        const apiErrors = err.response.data.errors;
        const fieldErrors = {};
        Object.keys(apiErrors).forEach((field) => {
          fieldErrors[field] = apiErrors[field].join(" ");
        });
        setErrors(fieldErrors);
      }
    } finally {
      setSubmitted(false);
    }
  };

  const btn = {
    '--cui-btn-bg': "#0e3f6a",
    '--cui-btn-color': "white",
    '--cui-btn-hover-bg': "#3c97cb",
    '--cui-btn-active-bg': "#0e3f6a"
  }

  return (
    <Modal
      {...props}
      size="lg"
      onShow={handleShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{
        backgroundColor: "#0e3f6a",
        color: "white"
      }}
        closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          Add New University
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CForm className="row g-3">
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Name"
              name="name"
              onChange={handleChange}
              isInvalid={!!errors.name}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.name}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Phone Number"
              name="phone_number"
              onChange={handleChange}
              isInvalid={!!errors.phone_number}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.phone_number}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Address1"
              name="address1"
              onChange={handleChange}
              isInvalid={!!errors.address1}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.address1}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Address2"
              name="address2"
              onChange={handleChange}
            />
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="City"
              name="city"
              onChange={handleChange}
              isInvalid={!!errors.city}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.city}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="State"
              name="state"
              onChange={handleChange}
              isInvalid={!!errors.state}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.state}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormSelect
              size="sm"
              className="mb-3"
              aria-label="Small select example"
              label="Country"
              name="country"
              onChange={handleChange}
              isInvalid={!!errors.country}
            >
              <option>Choose a country</option>
              {countries && countries.map((country, index) => (
                <option key={index}>
                  {country.country_name}
                </option>
              ))
              }
            </CFormSelect>
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.country}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Zip Code"
              name="zipcode"
              onChange={handleChange}
              isInvalid={!!errors.zipcode}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.zipcode}</CFormFeedback>
          </CCol>

          <div>
            <h6 style={{ fontWeight: "normal" }}>Add Domains</h6>
            <div style={{ marginBottom: "10px" }}>
              <CButton
                onClick={addDomain}
                style={btn}
              >Add</CButton>
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}>
              {formData.addDomains && formData.addDomains.map((value, index) => (
                <div key={index}
                  style={{
                    display: "flex",
                    marginRight: "2vw",
                    marginBottom: "10px"
                  }}>
                  <CCol md={12} style={{
                    display: "flex"
                  }}>
                    <CFormInput
                      type="text"
                      className="mb-3"
                      placeholder="Add a domain"
                      onChange={(e) => handleDomainChange(e, index)}
                      value={value}
                    />
                    <span
                      onClick={() => removeDomain(index)}
                      style={{
                        color: "red",
                        fontSize: "30px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        marginTop: "-8px"
                      }}><CiCircleRemove /></span>
                  </CCol>
                </div>
              ))}
            </div>
          </div>
        </CForm>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        <CButton style={btn} type="submit" onClick={handleSubmit} disabled={submitted}>
          Submit
        </CButton>
        <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUniversity;