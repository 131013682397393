import react, { useState, useEffect } from "react";
import {
    CForm,
    CCol,
    CFormInput,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CContainer,
    CFormTextarea,
    CSpinner,
    CButton,
    CTable, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell, CTableBody
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import axios from "axios";
import { API_BASE_URL, WEB_URL } from "../../../const/const";
import { CiCircleRemove } from "react-icons/ci";
import { useNavigate, useLocation } from "react-router-dom";
import AppSidebar from "../../../components/AppSidebar";
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import EditFaculty from "./EditFaculty";
import { useSelector, useDispatch } from "react-redux";
import './Faculty.css';
import { BreadCrumb } from 'primereact/breadcrumb';

const FacultyDetails = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('faculty_id');

    const user = localStorage.getItem("user");
    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();

    const [facultyDetails, setFacultyDetails] = useState([]);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        const [navItem]= document.querySelectorAll("[href='/faculty-list']");
        navItem.classList.add("active");

        const navGroup = document.querySelector(".nav-group");
        navGroup.classList.add("show")

        console.log("auth user", user)
        const token = JSON.parse(user).token;

        const getFacultyDetails = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-faculty/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Facultys-details", response);
                setFacultyDetails(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching Faculty-details:", error);
            }
        };
        if (user) {
            getFacultyDetails();
        }
        setFlag(false);
    }, [flag]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedFaculty, setSelectedFaculty] = useState(null);

    const updateFaculty = async () => {
        setShowEditModal(true);
        setSelectedFaculty(facultyDetails);
    }

    const handleEditFacultyFlag = (editFacultyFlag) => {
        console.log("edit state", editFacultyFlag)
        setFlag(editFacultyFlag);
    }

    const [FacultyDeleteConfirmModal, setFacultyDeleteConfirmModal] = useState(false);

    const handleFacultyDeleteConfirmation = (e, facultyId) => {
        setFacultyDeleteConfirmModal(true);
        setSelectedFaculty(facultyId);
    }

    const deleteFaculty = async (e, facultyId) => {
        try {
            e.preventDefault();
            setFacultyDeleteConfirmModal(false);
            const token = JSON.parse(user).token;
            const response = await axios.delete(`${API_BASE_URL}/delete-Faculty/${facultyId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            navigate("/faculty-list")
        } catch (error) {
            console.log(error);
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    const deletebtn = {
        '--cui-btn-bg': "red",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "red",
        '--cui-btn-active-bg': "red",
        "margin-left": "1vw"
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const items = [{ label: `Faculty-Details` }];
    const home = { label: 'Home', url: `${WEB_URL}/faculty-list` }

    return (
        <>
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <CHeader className="d-flex">
                        <CContainer>
                            <CHeaderToggler
                                className="ps-1"
                                onClick={() =>
                                    dispatch({ type: "set", sidebarShow: !sidebarShow })
                                }
                            >
                                <CIcon icon={cilMenu} size="lg" />
                            </CHeaderToggler>
                            <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                                <h4 className="ms-5">Faculty Details</h4>
                            </CHeaderNav>
                        </CContainer>
                    </CHeader>
                    {isLoading ?
                        <div style={{
                            marginTop: "10vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CSpinner />
                        </div> : <div className="mt-5 mx-5">
                            <BreadCrumb model={items} home={home}/>
                            <CForm className="row g-3">
                                <CCol md={12}>
                                    <table>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="faculty-detail-label">Faculty Name:</td>
                                                <td className="faculty-detail-value">{facultyDetails && facultyDetails.first_name} {facultyDetails && facultyDetails.last_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="faculty-detail-label">Faculty Credential:</td>
                                                <td className="faculty-detail-value">{facultyDetails && facultyDetails.credential}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="faculty-detail-label">Faculty Email:</td>
                                                <td className="faculty-detail-value">{facultyDetails && facultyDetails.email}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="faculty-detail-label">Faculty Phone No:</td>
                                                <td className="faculty-detail-value">{facultyDetails && facultyDetails.phone_number}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="faculty-detail-label">Faculty Address:</td>
                                                <td className="faculty-detail-value">{facultyDetails && facultyDetails.address1} {facultyDetails && facultyDetails.address2}
                                                , {facultyDetails && facultyDetails.city}, {facultyDetails && facultyDetails.state}
                                                , {facultyDetails && facultyDetails.country}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CCol>
                            </CForm>
                            <div className="d-flex justify-content-end my-3">
                                <CButton
                                    style={btn}
                                    onClick={updateFaculty}
                                >
                                    Edit Details
                                </CButton>
                                <CButton
                                    style={deletebtn}
                                    onClick={(e) => handleFacultyDeleteConfirmation(e, facultyDetails.faculty_id)}>
                                    Delete
                                </CButton>
                            </div>
                        </div>}
                </div>
                <EditFaculty
                    show={showEditModal}
                    onHide={() => setShowEditModal(false)}
                    faculty={selectedFaculty}
                    editFacultyFlag={handleEditFacultyFlag}
                />
                <ConfirmationModal
                    show={FacultyDeleteConfirmModal}
                    handleClose={() => { setFacultyDeleteConfirmModal(false) }}
                    handleConfirm={(e) => deleteFaculty(e, selectedFaculty)}
                />
            </div>

        </>
    );
};

export default FacultyDetails;
