import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from './views/hooks/useAuthContext';
import './scss/style.scss'
import appItems from './AppItems';
import axios from 'axios';
import ProgressModal from './views/pages/progressModal/ProgressModal';

const App = () => {
  const { user } = useAuthContext();

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          localStorage.removeItem('user');
          if (window.location.pathname !== '/login') {
            window.location.replace('/login');
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route
            path="/"
            element={!user ? <Navigate to="/login" /> : <Outlet />}
          />
          {appItems.map((item, index) => (
            <Route key={index} {...item} />
          ))}
        </Routes>
      </Router>
     <ProgressModal />
    </React.Fragment>
  );
};

export default App;

