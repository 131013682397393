import react, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  CForm,
  CCol,
  CFormInput,
  CInputGroup,
  CFormSelect,
  CFormLabel,
  CInputGroupText,
  CFormCheck,
  CButton,
  CFormFeedback,
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";

const EditUser = (props) => {
  const userData = props.user;
  const handleShow = () => {
    setErrors({});
};

  console.log("user data", userData);
  const roleMapping = {
    1: 'Admin',
    2: 'University Admin',
    3: 'Faculty',
  };

  const userRoleValue = userData ? userData.role : '';
  const userRoleName = roleMapping[userRoleValue];

  const initialFormData = {
    first_name: userData ? userData.first_name : "",
    last_name: userData ? userData.last_name : "",
    email: userData ? userData.email : "",
    password: userData ? userData.password : "",
    phone_number: userData ? userData.phone_number : "",
    address1: userData ? userData.address1 : "",
    address2: userData ? userData.address2 : "",
    city: userData ? userData.city : "",
    state: userData ? userData.state : "",
    country: userData ? userData.country : "",
    zipcode: userData ? userData.zipcode : "",
    role: userRoleName || "User",
    university_id: userData ? userData.university_id : "",
  };

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    setFormData(initialFormData);
  }, [userData])

  const [countries, setCountries] = useState();
  const [university, setUniversity] = useState();
  const user = localStorage.getItem("user");

  useEffect(() => {
    const getCountries = async () => {
      try {
        console.log("auth user", user)
        const token = JSON.parse(user).token;
        const response = await axios.get(`${API_BASE_URL}/get-countries`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("countries list", response);
        setCountries(response.data.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    }

    const getUniversity = async () => {
      try {
        console.log("auth user", user)
        const token = JSON.parse(user).token;
        const response = await axios.get(`${API_BASE_URL}/get-university`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("university list", response);
        setUniversity(response.data.data);
      } catch (error) {
        console.error("Error fetching university:", error);
      }
    }

    if (user) {
      getCountries();
      getUniversity();
    }
  }, [])

  const handleUniversityChange = (uniData) => {
    console.log("uni data", uniData);

    setFormData((prev) => ({
      ...prev,
      university_id: uniData.universityId,
      //  university_name: uniData.universityName,
    }));

    setErrors((prev) => ({ ...prev, university_id: "" }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("field value", name, value)
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const [flag, setFlag] = useState(false);

  const handleEditUserFlag = (flag) => {
    console.log("flag...", flag)
    flag = true;
    props.editUserFlag(flag);
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log("auth user", user)
      const token = JSON.parse(user).token;

      console.log("fields", formData);
      const response = await axios.put(`${API_BASE_URL}/update-user/${userData.user_id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("successfully updated user", response);
      handleEditUserFlag(setFlag(true));
      props.onHide();
    } catch (err) {
      console.log("error in editing user details", err);
      if (err.response.data.errors) {
        const apiErrors = err.response.data.errors;
        const fieldErrors = {};
        Object.keys(apiErrors).forEach((field) => {
          fieldErrors[field] = apiErrors[field].join(" ");
        });
        setErrors(fieldErrors);
      }
    }
  };

  const btn = {
    '--cui-btn-bg': "#0e3f6a",
    '--cui-btn-color': "white",
    '--cui-btn-hover-bg': "#3c97cb",
    '--cui-btn-active-bg':"#0e3f6a"
  }

  return (
    <Modal
      {...props}
      size="lg"
      onShow={handleShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{
        backgroundColor: "#0e3f6a",
        color: "white"
      }}
        closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CForm className="row g-3">
          <CCol md={6}>
            <CFormInput
              type="text"
              label="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              isInvalid={!!errors.first_name}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.first_name}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              isInvalid={!!errors.last_name}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.last_name}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled
            />
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Phone Number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              isInvalid={!!errors.phone_number}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.phone_number}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Address1"
              name="address1"
              value={formData.address1}
              onChange={handleChange}
              isInvalid={!!errors.address1}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.address1}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Address2"
              name="address2"
              value={formData.address2}
              onChange={handleChange}
            />
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="City"
              name="city"
              value={formData.city}
              onChange={handleChange}
              isInvalid={!!errors.city}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.city}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="State"
              name="state"
              value={formData.state}
              onChange={handleChange}
              isInvalid={!!errors.state}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.state}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormSelect
              size="sm"
              className="mb-3"
              aria-label="Small select example"
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              isInvalid={!!errors.country}
            >
              <option>Choose a country</option>
              {countries && countries.map((country, index) => (
                <option key={index}>
                  {country.country_name}
                </option>
              ))
              }
            </CFormSelect>
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.country}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Zip Code"
              name="zipcode"
              value={formData.zipcode}
              onChange={handleChange}
              isInvalid={!!errors.zipcode}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.zipcode}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormSelect
              size="sm"
              className="mb-3"
              aria-label="Small select example"
              label="Role"
              name="role"
              value={userRoleName}
              onChange={handleChange}
            >
              <option>Choose a user role</option>
              <option>Admin</option>
              <option>University Admin</option>
              <option>Faculty</option>
            </CFormSelect>
          </CCol>
          {formData.role === "University Admin" ? <CCol md={6}>
            <CFormSelect
              size="sm"
              className="mb-3"
              aria-label="Small select example"
              label="University"
              name="university_id"
              value={formData.university_id}
              onChange={(e) =>
                handleUniversityChange({
                  universityId: e.target.value,
                  // universityName: e.target.options[e.target.selectedIndex].text,
                })
              }
            >
              <option>Choose a university</option>
              {university && university.map((university) => (
                <option key={university.university_id} value={university.university_id}>
                  {university.name}</option>
              ))}
            </CFormSelect>
          </CCol> : <></>}
        </CForm>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        <CButton style={btn} type="submit" onClick={handleSubmit}>
          Submit
        </CButton>
        <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUser;
