import react, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CFormSelect,
    CButton,
    CFormTextarea,
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { useNavigate } from "react-router-dom";

const EditUniSubs = (props) => {
    const navigate = useNavigate();
    const handleShow = () => {
        // setErrors({});
    };

    const uniSubsData = props.university_subscription;
    console.log("uni-subs data", uniSubsData);

    function changeDateFormat(dateString) {
        if (!dateString) return "";
        const [day, month, year] = dateString.split('-');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    
    const initialFormData = {
        university_id: uniSubsData ? uniSubsData.university_id : null,
        subscription_plan_id: uniSubsData ? uniSubsData.subscription_plan_id : null,
        start_date: uniSubsData ? changeDateFormat(uniSubsData.start_date) : "",
        end_date: uniSubsData ? changeDateFormat(uniSubsData.end_date) : ""
    };

    const [formData, setFormData] = useState(initialFormData);
    useEffect(() => {
        setFormData(initialFormData);
    }, [uniSubsData]);
    
    const [universities, setUniversities] = useState([]);
    const [subsList, setSubsList] = useState([]);

    useEffect(() => {
        console.log("authenticated user", user)
        const token = JSON.parse(user).token;
        const getSubscription = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-subscription`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Subscription List", response);
                setSubsList(response.data.data);
            } catch (error) {
                console.error("subs:", error);
            }
        };

        const getUniversity = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-university`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("uni List", response);
                setUniversities(response.data.data);
            } catch (error) {
                console.error("uni:", error);
            }
        }

        if (user) {
            getSubscription();
            getUniversity()
        }
    }, []);

    const handleUniChange = (uniId) => {
        console.log("uni data", uniId);

        setFormData((prev) => ({
            ...prev,
            university_id: uniId,
        }));
    };

    const handleSubsChange = (subsId) => {
        console.log("subs data", subsId)

        setFormData((prev) => ({
            ...prev,
            subscription_plan_id: subsId
        }));
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    console.log("form updated", formData);

    const [flag, setFlag] = useState(false);

    const handleEditUniSubsFlag = (flag) => {
        console.log("edit uni-subs flag", flag)
        flag = true;
        props.editUniSubsFlag(flag);
    }

    const user = localStorage.getItem("user");

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            console.log("auth user", user)
            const token = JSON.parse(user).token;

            console.log("fields", formData);
            const response = await
                axios.put(`${API_BASE_URL}/update-uni-subs/${uniSubsData.university_subscription_mapping_id}`,
                    formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            console.log("successfully updated uni-subs", response);
            handleEditUniSubsFlag(setFlag(true));
            props.onHide();
        } catch (err) {
            console.log("error in editing uni-subs details", err);
            if (err.response && err.response.status === 401) {
                navigate('/login');
            }
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit University Access
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="University"
                            name="university_id"
                            onChange={(e) => handleUniChange(e.target.value)}
                            value={formData.university_id}
                            required
                        >
                            <option>Choose an university</option>
                            {universities && universities.map((uni) => (
                                <option key={uni.university_id} value={uni.university_id}>
                                    {uni.name}</option>
                            ))}
                        </CFormSelect>
                    </CCol>
                    <CCol md={12}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="Subscription"
                            name="subscription_plan_id"
                            onChange={(e) => handleSubsChange(e.target.value)}
                            value={formData.subscription_plan_id}
                            required
                        >
                            <option>Choose a subscription plan</option>
                            {subsList && subsList.map((subs) => (
                                <option key={subs.subscription_plan_id} value={subs.subscription_plan_id}>
                                    {subs.name}</option>
                            ))}
                        </CFormSelect>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="date"
                            label="Start Date"
                            name="start_date"
                            onChange={handleDateChange}
                            value={formData.start_date}
                            required />
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="date"
                            label="End Date"
                            name="end_date"
                            onChange={handleDateChange}
                            value={formData.end_date}
                            required />
                    </CCol>
                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default EditUniSubs;
