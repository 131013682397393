import react, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  CForm,
  CCol,
  CFormInput,
  CInputGroup,
  CFormSelect,
  CFormLabel,
  CInputGroupText,
  CFormCheck,
  CButton,
  CFormFeedback,
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { useNavigate } from "react-router-dom";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";

const AddNewUser = (props) => {
  const navigate = useNavigate();
  const handleShow = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      address1: "",
      address2: "",
      phone_number: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      role: "User",
      university_id: null,
    });
    setFlag(false);
    setErrors({});
    setSubmitted(false);
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    address1: "",
    address2: "",
    phone_number: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    role: "User",
    university_id: null,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [errors, setErrors] = useState({});

  const [countries, setCountries] = useState();
  const [university, setUniversity] = useState();
  const user = localStorage.getItem("user");

  useEffect(() => {
    const getCountries = async () => {
      try {
        console.log("auth user", user)
        const token = JSON.parse(user).token;
        const response = await axios.get(`${API_BASE_URL}/get-countries`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("countries list", response);
        setCountries(response.data.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    }

    const getUniversity = async () => {
      try {
        console.log("auth user", user)
        const token = JSON.parse(user).token;
        const response = await axios.get(`${API_BASE_URL}/get-university`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("university list", response);
        setUniversity(response.data.data);
      } catch (error) {
        console.error("Error fetching university:", error);
      }
    }

    if (user) {
      getCountries();
      getUniversity();
    }
  }, [])

  const handleUniversityChange = (uniData) => {
    console.log("uni data", uniData);

    setFormData((prev) => ({
      ...prev,
      university_id: uniData.universityId,
      university_name: uniData.universityName,
    }));

    setErrors((prev) => ({ ...prev, university_id: "" }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const [flag, setFlag] = useState(false);

  const handleAddFlagState = (flag) => {
    console.log("add flag", flag);
    flag = true;
    props.addFlag(flag);
  }

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitted(true);
      console.log("auth user", user)
      const token = JSON.parse(user).token;

      console.log("fields", formData);
      const response = await axios.post(`${API_BASE_URL}/add-user`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("successfully added user", response);
      handleAddFlagState(setFlag(true));
      props.onHide();

    } catch (err) {
      console.log("error in adding user", err);
      if (err.response.data.errors) {
        const apiErrors = err.response.data.errors;
        const fieldErrors = {};
        Object.keys(apiErrors).forEach((field) => {
          fieldErrors[field] = apiErrors[field].join(" ");
        });
        setErrors(fieldErrors);
      }
    } finally {
      setSubmitted(false);
    }
  };

  const btn = {
    '--cui-btn-bg': "#0e3f6a",
    '--cui-btn-color': "white",
    '--cui-btn-hover-bg': "#3c97cb",
    '--cui-btn-active-bg': "#0e3f6a"
  }


  return (
    <Modal
      {...props}
      size="lg"
      onShow={handleShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{
        backgroundColor: "#0e3f6a",
        color: "white"
      }}
        closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          Add New User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CForm className="row g-3">
          <CCol md={6}>
            <CFormInput
              type="text"
              label="First Name"
              placeholder="John"
              name="first_name"
              onChange={handleChange}
              isInvalid={!!errors.first_name}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.first_name}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Last Name"
              placeholder="Doe"
              name="last_name"
              onChange={handleChange}
              isInvalid={!!errors.last_name}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.last_name}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Email"
              name="email"
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.email}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Phone Number"
              name="phone_number"
              onChange={handleChange}
              isInvalid={!!errors.phone_number}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.phone_number}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              onChange={handleChange}
              isInvalid={!!errors.password}
              style={{ paddingRight: "2.5rem" }}
            />
            <span 
            style={{
              fontSize: "20px",
              float:"right",
              right: "3vw",
              marginTop: "-2.5vw",
              marginRight:"0.5vw",
              cursor: "pointer"
            }}
             onClick={togglePasswordVisibility}>
              {showPassword ? <span><BiSolidHide /></span> : <span><BiSolidShow /></span>}
            </span>
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.password}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              label="Confirm Password"
              onChange={handleChange}
              isInvalid={!!errors.confirm_password}
              style={{ paddingRight: "2.5rem" }}
            />
            <span 
            style={{
              fontSize: "20px",
              float:"right",
              right: "3vw",
              marginTop: "-2.5vw",
              marginRight:"0.5vw",
              cursor: "pointer"
            }}
             onClick={toggleConfirmPasswordVisibility}>
              {showConfirmPassword ? <span><BiSolidHide /></span> : <span><BiSolidShow /></span>}
            </span>
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.confirm_password}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Address1"
              name="address1"
              onChange={handleChange}
              isInvalid={!!errors.address1}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.address1}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Address2"
              name="address2"
              onChange={handleChange}
            />
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="City"
              name="city"
              onChange={handleChange}
              isInvalid={!!errors.city}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.city}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="State"
              name="state"
              onChange={handleChange}
              isInvalid={!!errors.state}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.state}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormSelect
              size="sm"
              className="mb-3"
              aria-label="Small select example"
              label="Country"
              name="country"
              onChange={handleChange}
              isInvalid={!!errors.country}
            >
              <option>Choose a country</option>
              {countries && countries.map((country, index) => (
                <option key={index}>
                  {country.country_name}
                </option>
              ))
              }
            </CFormSelect>
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.country}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Zip Code"
              name="zipcode"
              onChange={handleChange}
              isInvalid={!!errors.zipcode}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.zipcode}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormSelect
              size="sm"
              className="mb-3"
              aria-label="Small select example"
              label="Role"
              name="role"
              onChange={handleChange}
              required
            >
              <option>Choose a user role</option>
              <option>Admin</option>
              <option>University Admin</option>
              <option>university</option>
            </CFormSelect>
          </CCol>
          {formData.role === "University Admin" ? <CCol md={6}>
            <CFormSelect
              size="sm"
              className="mb-3"
              aria-label="Small select example"
              label="University"
              name="university_id"
              onChange={(e) =>
                handleUniversityChange({
                  universityId: e.target.value,
                  universityName: e.target.options[e.target.selectedIndex].text,
                })
              }
            >
              <option>Choose a university</option>
              {university && university.map((university) => (
                <option key={university.university_id} value={university.university_id}>
                  {university.name}</option>
              ))}
            </CFormSelect>
          </CCol> : <></>}

        </CForm>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        <CButton style={btn} type="submit" onClick={handleSubmit} disabled={submitted}>
          Submit
        </CButton>
        <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
      </Modal.Footer>
    </Modal>

  );
};

export default AddNewUser;
