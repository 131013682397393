import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BiLogOut } from "react-icons/bi";

import {
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";

import { AppSidebarNav } from "./AppSidebarNav";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation from "../_nav";
import { API_BASE_URL } from "../const/const";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../views/hooks/useAuthContext";

const AppSidebar = () => {
  const visibleFn = useDispatch();

  const [navItems, setNavItems] = useState([navigation[0]]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user ? user.user_role : null;
    if (userRole === 1) {
      setNavItems([navigation[0]]);
    } else {
      setNavItems([navigation[1]]);
    }
  }, [])

  const { dispatch } = useAuthContext()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);


  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const user = localStorage.getItem("user");
      const token = JSON.parse(user).token;
      console.log(token)
      const response = await axios.post(`${API_BASE_URL}/logout`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Logout successfull", response.data);
      localStorage.removeItem('user');
      dispatch({ type: 'LOGOUT' })
      navigate("/login");
    } catch (error) {
      console.error("Logout failed", error);
      // if(error.response.status==401){
      //   navigate("/login");
      // }
      // if (error.response && error.response.data && error.response.data.error) {
      //   const errorMsg = error.response.data.error === "Token has expired"
      //     ? (
      //       <div>
      //         {error.response.data.error}
      //         <button onClick={() => navigate("/login")} style={{
      //           height: "32px",
      //           color: "darkblue",
      //           fontSize: "18px",
      //           fontWeight: "bold",
      //           backgroundColor: "white",
      //           border: "1px solid darkblue",
      //           fontFamily: "initial"
      //         }}>Login</button>
      //       </div>
      //     )
      //     : error.response.data.error;

      //   alert(errorMsg);
      // }
    }
  };

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        visibleFn({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <h5>RE Content Archive</h5>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navItems} isOpen={true}/>
        </SimpleBar>
      </CSidebarNav>
      <CSidebarFooter
        style={{ fontSize: "16px", cursor: "pointer" }}
        onClick={handleLogout}
      >
        <span style={{ marginLeft: "3vw", color: "white" }}>
          <BiLogOut style={{ marginRight: "1vw", color: "white" }} />
          Logout
        </span>
      </CSidebarFooter>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
