import react, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CFormSelect,
    CButton,
    CFormTextarea,
    CFormFeedback
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { CiCircleRemove } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const EditCourse = (props) => {
    const navigate = useNavigate()

    const handleShow = () => {
        setErrors({});
    };

    const [facultyField, setFacultyField] = useState(['']);
    const [facultyList, setFacultyList] = useState();
    const CourseData = props.course;
    console.log("course data", CourseData);
    const facultyData = props.courseFaculty;

    const initialFormData = {
        name: CourseData ? CourseData.name : null,
        description: CourseData ? CourseData.description : null,
        addFaculty: [],
        deleteFaculty: []
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormData(initialFormData);
    }, [CourseData])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    useEffect(() => {
        console.log("auth user", user)
        const token = JSON.parse(user).token;

        const getFacultyList = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-faculty`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("faculty list", response);
                setFacultyList(response.data.data);

            } catch (error) {
                console.error("Error fetching faculty:", error);
            }
        };

        if (user) {
            getFacultyList();
        }
    }, []);

    console.log('faculty-options list', facultyList);

    useEffect(() => {
        if (facultyData && facultyData.length > 0) {
            const courseFacultyData = facultyData.map(faculty => ({
                facultyId: faculty.faculty_id,
                facultyFirstName: faculty.first_name,
                facultyLastName: faculty.last_name,
                courseId: faculty.course_id
            }));
            setFacultyField(courseFacultyData);
        } else {
            setFacultyField([]);
        }
    }, [facultyData]);

    console.log('prefilled faculty data', facultyField);

    const [selectedFacultyId, setSelectedFacultyId] = useState();
    const handleOptions = (faculty_id) => {
        setSelectedFacultyId(faculty_id);
    }

    const handleFacultyChange = (index, e) => {
        const selectedFacultyId = parseInt(e.target.value, 10);
        const selectedFaculty = facultyList.find(faculty => faculty.faculty_id === selectedFacultyId);
        console.log(selectedFaculty);

        setFormData((prev) => {
            const newAddFaculties = [...(prev.addFaculty || [])];

            if (selectedFaculty && selectedFaculty.faculty_id) {
                newAddFaculties[index] = selectedFaculty.faculty_id;

                if (facultyField[index] && facultyField[index].facultyId) {
                    const newDeleteFaculties = [...(prev.deleteFaculty || []), facultyField[index].facultyId];
                    return {
                        ...prev,
                        addFaculty: newAddFaculties.filter(Boolean),
                        deleteFaculty: newDeleteFaculties
                    };
                }
            } else {
                console.error("Selected faculty is undefined or missing faculty_id property for addFaculties");
            }

            return {
                ...prev,
                addFaculty: newAddFaculties.filter(Boolean),
            };
        });
        // setErrors((prev) => ({ ...prev, addFaculty: "" }));
    };

    const addFaculty = () => {
        const newFacultiesData = [...facultyField, "newFaculty"];
        setFacultyField(newFacultiesData);
    };

    const removeFaculty = (index) => {
        console.log('index to be removed', index);
        const newFacultiesData = [...facultyField];

        setFormData((prev) => {
            let facultyIdTobeDeleted;
            if (facultyField[index].facultyId) {
                // if pre-existing faculty is deleted
                facultyIdTobeDeleted = facultyField[index].facultyId;
            } else {
                facultyIdTobeDeleted = formData.addFaculty[index];
            }
            const newDeleteFaculties = [...(prev.deleteFaculty || []), facultyIdTobeDeleted];
            const newAddFaculties = [...(prev.addFaculty || [])];
            newAddFaculties.splice(index, 1);
            return {
                ...prev,
                deleteFaculty: newDeleteFaculties,
                addFaculty: newAddFaculties.filter(Boolean)
            };
        });

        newFacultiesData.splice(index, 1);
        setFacultyField(newFacultiesData);
    };

    console.log("form updated", formData);

    const [flag, setFlag] = useState(false);

    const handleEditCourseFlag = (flag) => {
        console.log("edit course flag", flag)
        flag = true;
        props.editCourseFlag(flag);
    }

    const user = localStorage.getItem("user");

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            console.log("auth user", user)
            const token = JSON.parse(user).token;

            console.log("fields", formData);
            const response = await
                axios.put(`${API_BASE_URL}/update-course/${CourseData.course_id}`,
                    formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            console.log("successfully updated course", response);
            handleEditCourseFlag(setFlag(true));
            props.onHide();
        } catch (err) {
            console.log("error in editing course details", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
            }
            if (err.response && err.response.status === 401) {
                navigate('/login');
            }
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg':"#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Course
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Course Name"
                            name="name"
                            placeholder="Enter a course name"
                            onChange={handleChange}
                            value={formData.name}
                            isInvalid={!!errors.name}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.name}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormTextarea
                            rows={3}
                            label="Description"
                            name="description"
                            placeholder="Add a description for the course"
                            onChange={handleChange}
                            value={formData.description}
                            isInvalid={!!errors.description}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.description}</CFormFeedback>
                    </CCol>

                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Faculty</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addFaculty}
                                style={btn}
                            >Add</CButton>

                        </div>
                        <div>
                            {facultyField && facultyField.map((faculty, index) => (
                                <div key={index}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a faculty"
                                            // value={tag.tagName}
                                            onChange={(e) => handleFacultyChange(index, e)}
                                            required
                                        >
                                            <option value={faculty.facultyId}
                                                onClick={() => handleOptions(faculty.facultyId)}>
                                                {faculty.facultyFirstName} {faculty.facultyLastName}</option>
                                            {facultyList && facultyList.map((options) => (
                                                faculty.facultyId !== options.faculty_id ?
                                                    <option key={options.faculty_id} value={options.faculty_id}
                                                        onClick={() => handleOptions(options.faculty_id)}>
                                                        {options.first_name} {options.last_name}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeFaculty(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default EditCourse;
