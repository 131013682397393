import react, { useState, useEffect } from "react";
import {
    CForm,
    CCol,
    CFormInput,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CContainer,
    CFormTextarea,
    CSpinner,
    CButton,
    CTable, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell, CTableBody, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider, CDropdown
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import axios from "axios";
import { API_BASE_URL, WEB_URL } from "../../../const/const";
import { useNavigate, useLocation } from "react-router-dom";
import AppSidebar from "../../../components/AppSidebar";
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import EditSubscription from "./EditSubscription";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { useSelector, useDispatch } from "react-redux";
import { FaRegCirclePlay } from "react-icons/fa6";
import VideoPlayer from "../video/VideoPlayer";
import { CiViewList } from "react-icons/ci";
import { BreadCrumb } from "primereact/breadcrumb";
import './Subscription.css'

const SubscriptionDetails = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const user = localStorage.getItem("user");
    const token = JSON.parse(user).token;

    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();

    const [subscriptionDetails, setSubscriptionDetails] = useState([]);

    const [flag, setFlag] = useState(false);

    const [programTreeTableData, setProgramTreeTableData] = useState([]);
    const [courseTreeTableData, setCourseTreeTableData] = useState([]);
    const [videoTreeTableData, setVideoTreeTableData] = useState([]);

    const [subscriptionProgram, setSubscriptionProgram] = useState([]);
    const [subscriptionCourse, setSubscriptionCourse] = useState([]);
    const [subscriptionVideo, setSubscriptionVideo] = useState([]);

    const [programCourse, setProgramCourse] = useState([]);
    const [programVideo, setProgramVideo] = useState([]);
    const [courseVideos, setCourseVideos] = useState([]);

    useEffect(() => {
        const [navItem] = document.querySelectorAll("[href='/subscription-list']");
        navItem.classList.add("active");

        const navGroup = document.querySelector(".nav-group");
        navGroup.classList.add("show")

        const getPrograms = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-program`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Program-content", response);
                setProgramCourse(response.data.courseResults);
                // setProgramModule(response.data.moduleResults);
                setProgramVideo(response.data.videoResults);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching program details:", error);
                setIsLoading(false);
            }
        }

        const getCourseVideos = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-course-videos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("course-video details", response);
                setCourseVideos(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching course-videos:", error);
            }
        };

        const getSubscriptionDetails = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-subs-content/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Subscriptions-details", response);
                setSubscriptionDetails(response.data.data);
                setSubscriptionProgram(response.data.programResults);
                setSubscriptionCourse(response.data.courseResults);
                // setSubscriptionModule(response.data.moduleResults);
                setSubscriptionVideo(response.data.videoResults)
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching Subscription-details:", error);
            }
        };

        if (user) {
            getSubscriptionDetails();
            getPrograms();
            // getCourseModules();
            // getModuleVideos();
            getCourseVideos();
        }
        setFlag(false);
    }, [flag]);

    useEffect(() => {
        if (subscriptionProgram && subscriptionCourse && subscriptionVideo && programCourse &&
            programVideo && courseVideos) {
            setProgramTreeTableData(createProgramTreeTableData(
                subscriptionProgram, programCourse, courseVideos, programVideo
            ));
            setCourseTreeTableData(
                createCourseTreeTableData(subscriptionCourse, courseVideos)
            );

            setVideoTreeTableData(createVideoTreeTableData(subscriptionVideo));
        }
    }, [subscriptionProgram,
        subscriptionCourse,
        // subscriptionModule,
        subscriptionVideo,
        programCourse,
        // programModule,
        programVideo,
        // courseModules,
        // moduleVideos,
        courseVideos
    ]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [selectedSubscriptionProgram, setSelectedSubscriptionProgram] = useState(null);
    const [selectedSubscriptionCourse, setSelectedSubscriptionCourse] = useState(null);
    const [selectedSubscriptionVideo, setSelectedSubscriptionVideo] = useState(null);

    const updateSubscription = async () => {
        setShowEditModal(true);
        setSelectedSubscription(subscriptionDetails);
        setSelectedSubscriptionProgram(subscriptionProgram);
        setSelectedSubscriptionCourse(subscriptionCourse);
        // setSelectedSubscriptionModule(subscriptionModule);
        setSelectedSubscriptionVideo(subscriptionVideo);
    }

    const handleEditSubscriptionFlag = (editSubscriptionFlag) => {
        console.log("edit state", editSubscriptionFlag)
        setFlag(editSubscriptionFlag);
    }

    const [SubscriptionDeleteConfirmModal, setSubscriptionDeleteConfirmModal] = useState(false);

    const handleSubscriptionDeleteConfirmation = (e, subscriptionId) => {
        setSubscriptionDeleteConfirmModal(true);
        setSelectedSubscription(subscriptionId);
    }

    const deleteSubscription = async (e, subscriptionId) => {
        try {
            e.preventDefault();
            setSubscriptionDeleteConfirmModal(false);
            const token = JSON.parse(user).token;
            const response = await axios.delete(`${API_BASE_URL}/delete-Subscription/${subscriptionId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            navigate("/Subscription-list")
        } catch (error) {
            console.log(error);
        }
    };

    const createProgramTreeTableData = (programs, programCourses, programCourseVideos, programVideos) => {
        return programs.map((program, index) => {
            const filteredProgramCourses = programCourses && programCourses.filter(course =>
                course.program_id === program.content_id);
            const filteredProgramVideos = programVideos && programVideos.filter(video =>
                video.program_id === program.content_id);

            const programNode = {
                key: String(index),
                id: program.content_id,
                hasFeature: false,
                data: {
                    name: program.name.length > 35 ? `${program.name.slice(0, 35)}...` : program.name,
                    actions: {
                        text: <><CiViewList className="fs-4" /> View Program</>, onClick: () =>
                            navigate(`/program-details?program_id=${program.content_id}`)
                    }
                },
                children: [
                    ...(filteredProgramCourses && filteredProgramCourses.length > 0
                        ? filteredProgramCourses.map((course, courseIndex) => {
                            const courseChildren = [];

                            const filteredProgramCourseVideos = programCourseVideos && programCourseVideos.filter(video =>
                                video.course_id === course.content_id);

                            courseChildren.push(
                                ...(filteredProgramCourseVideos && filteredProgramCourseVideos.length > 0
                                    ? filteredProgramCourseVideos.map((video, videoIndex) => ({
                                        key: `video-${videoIndex}`,
                                        id: video.video_id,
                                        hasFeature: true,
                                        data: {
                                            name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title,
                                            actions: {
                                                text: <><CiViewList className="fs-4" /> View Video</>, onClick: () =>
                                                    navigate(`/video-details?video_id=${video.video_id}`)
                                            }
                                        },
                                        children: [],
                                    }))
                                    : []
                                )
                            );

                            return {
                                key: `course-${courseIndex}`,
                                id: course.content_id,
                                hasFeature: false,
                                data: {
                                    name: course.name.length > 35 ? `${course.name.slice(0, 35)}...` : course.name,
                                    actions: {
                                        text: <><CiViewList className="fs-4" /> View Course</>, onClick: () =>
                                            navigate(`/course-details?course_id=${course.content_id}`)
                                    }
                                },
                                children: courseChildren,
                            };
                        })
                        : []
                    ),
                    ...(filteredProgramVideos && filteredProgramVideos.length > 0
                        ? filteredProgramVideos.map((video, videoIndex) => ({
                            key: `video-${videoIndex}`,
                            id: video.content_id,
                            hasFeature: true,
                            data: {
                                name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title,
                                actions: {
                                    text: <><CiViewList className="fs-4" /> View Video</>, onClick: () =>
                                        navigate(`/video-details?video_id=${video.content_id}`)
                                }
                            },
                            children: [],
                        }))
                        : []
                    ),
                ]
            };
            return programNode;
        });
    };

    const createCourseTreeTableData = (courses, courseVideos) => {
        return courses.map((course, index) => {
            const filteredCourseVideos = courseVideos && courseVideos.filter(video =>
                video.course_id === course.content_id);
            const courseNode = {
                key: String(index),
                id: course.content_id,
                hasFeature: false,
                data: {
                    name: course.name.length > 35 ? `${course.name.slice(0, 35)}...` : course.name,
                    actions: {
                        text: <><CiViewList className="fs-4" /> View Course</>, onClick: () =>
                            navigate(`/course-details?course_id=${course.content_id}`)
                    }
                },
                children: [
                    ...(filteredCourseVideos && filteredCourseVideos.length > 0
                        ? filteredCourseVideos.map((video, videoIndex) => ({
                            key: `video-${videoIndex}`,
                            id: video.video_id,
                            hasFeature: true,
                            data: {
                                name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title,
                                actions: {
                                    text: <><CiViewList className="fs-4" /> View Video</>, onClick: () =>
                                        navigate(`/video-details?video_id=${video.video_id}`)
                                }
                            },
                            children: [],
                        }))
                        : []
                    ),
                ]
            };
            return courseNode;
        });
    };

    const createVideoTreeTableData = (videos) => {
        return videos.map((video, index) => {
            const videoNode = {
                key: String(index),
                id: video.content_id,
                data: {
                    name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title,
                    actions: {
                        text: <><CiViewList className="fs-4" /> View Video</>, onClick: () =>
                            navigate(`/video-details?video_id=${video.content_id}`)
                    },
                    playVideo: createPlayVideoColumn(video),
                },
                children: []
            };
            return videoNode;
        });
    };

    const renderActions = (rowData) => {
        const { actions } = rowData.data;

        const handleClick = () => {
            actions.onClick();
        };

        return (
            <span onClick={handleClick} style={{ color: "purple", cursor: "pointer" }}>{actions.text}</span>
        );
    };

    const [showPlayVideo, setShowPlayVideo] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const playVideo = (video) => {
        setShowPlayVideo(true);
        setSelectedVideo(video);
    }

    const createPlayVideoColumn = (video) => {
        return [
            {
                label: 'Play Video',
                command: () => playVideo(video)
            }
        ];
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    const deletebtn = {
        '--cui-btn-bg': "red",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "red",
        '--cui-btn-active-bg': "red",
        "margin-left": "1vw"
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const items = [{ label: `${subscriptionDetails && subscriptionDetails.name}` }];
    const home = { label: 'Home', url: `${WEB_URL}/subscription-list` }

    return (
        <>
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <CHeader className="d-flex">
                        <CContainer>
                            <CHeaderToggler
                                className="ps-1"
                                onClick={() =>
                                    dispatch({ type: "set", sidebarShow: !sidebarShow })
                                }
                            >
                                <CIcon icon={cilMenu} size="lg" />
                            </CHeaderToggler>
                            <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                                <h4 className="ms-5">{subscriptionDetails && subscriptionDetails.name}</h4>
                            </CHeaderNav>
                        </CContainer>
                    </CHeader>

                    {isLoading ?
                        <div style={{
                            marginTop: "10vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CSpinner />
                        </div> : <div className="mt-5 mx-5">
                            <BreadCrumb model={items} home={home} />

                            <CForm className="row g-3">
                                <CCol md={12}>
                                    <table>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="subs-detail-label">Subscription Title:</td>
                                                <td className="subs-detail-value">{subscriptionDetails && subscriptionDetails.name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="subs-detail-label">Subscription Description:</td>
                                                <td className="subs-detail-value">{subscriptionDetails && subscriptionDetails.description}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CCol>
                            </CForm>

                            {programTreeTableData && programTreeTableData.length > 0 && (<div className="mt-5 ">
                                <TreeTable value={programTreeTableData} header="Programs">
                                    <Column field="name" expander style={{ width: "40%" }}></Column>
                                    <Column field="actions" body={renderActions} style={{ width: "30%" }}></Column>
                                    <Column field="customActions" body={(rowData) => (
                                        <div>{rowData.hasFeature && (
                                            <div><span style={{
                                                color: "green",
                                                cursor: 'pointer'
                                            }} onClick={() => playVideo(rowData)}
                                            ><FaRegCirclePlay className="fs-4" /> Play Video</span>
                                            </div>
                                        )}</div>
                                    )}></Column>
                                </TreeTable>
                            </div>)}

                            {courseTreeTableData && courseTreeTableData.length > 0 && (<div className="mt-5 ">
                                <TreeTable value={courseTreeTableData} header="Courses">
                                    <Column field="name" expander style={{ width: "40%" }}></Column>
                                    <Column field="actions" body={renderActions} style={{ width: "30%" }}></Column>
                                    <Column field="customActions" body={(rowData) => (
                                        <div>{rowData.hasFeature && (
                                            <div><span style={{
                                                color: "green",
                                                cursor: 'pointer'
                                            }} onClick={() => playVideo(rowData)}
                                            ><FaRegCirclePlay className="fs-4" /> Play Video</span>
                                            </div>
                                        )}</div>
                                    )}></Column>
                                </TreeTable>
                            </div>)}

                            {videoTreeTableData && videoTreeTableData.length > 0 && (<div className="mt-5">
                                <TreeTable value={videoTreeTableData} header="Videos">
                                    <Column field="name" expander style={{ width: "40%" }}></Column>
                                    <Column field="actions" body={renderActions} style={{ width: "30%" }}></Column>
                                    <Column field="customActions" body={(rowData) => (
                                        <div><span style={{
                                            color: "green",
                                            cursor: 'pointer'
                                        }} onClick={() => playVideo(rowData)}
                                        ><FaRegCirclePlay className="fs-4" /> Play Video</span>
                                        </div>)}></Column>
                                </TreeTable>
                            </div>)}

                            <div className="d-flex justify-content-end my-3">
                                <CButton
                                    style={btn}
                                    onClick={updateSubscription}
                                >
                                    Edit Details
                                </CButton>
                                <CButton
                                    style={deletebtn}
                                    onClick={(e) => handleSubscriptionDeleteConfirmation(e, subscriptionDetails.subscription_plan_id)}>
                                    Delete
                                </CButton>
                            </div>
                        </div>}
                </div>
                {showPlayVideo && (<VideoPlayer
                    show={showPlayVideo}
                    onHide={() => setShowPlayVideo(false)}
                    video={selectedVideo}
                />)}
                <EditSubscription
                    show={showEditModal}
                    onHide={() => setShowEditModal(false)}
                    subscription={selectedSubscription}
                    subscriptionPrograms={selectedSubscriptionProgram}
                    subscriptionCourses={selectedSubscriptionCourse}
                    // subscriptionModules={selectedSubscriptionModule}
                    subscriptionVideos={selectedSubscriptionVideo}
                    editSubsFlag={handleEditSubscriptionFlag}
                />
                <ConfirmationModal
                    show={SubscriptionDeleteConfirmModal}
                    handleClose={() => { setSubscriptionDeleteConfirmModal(false) }}
                    handleConfirm={(e) => deleteSubscription(e, selectedSubscription)}
                />
            </div>

        </>
    );
};

export default SubscriptionDetails;
