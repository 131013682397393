import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BASE_URL } from '../../../const/const';
import { CSpinner } from '@coreui/react';
import './VideoPlayer.css';

const VideoPlayer = (props) => {
    const [isLoading, setIsLoading] = useState(true);

    const user = localStorage.getItem('user');
    const token = JSON.parse(user).token;

    const videoData = props.video;
    console.log("video-player-data", videoData);

    const videoId = videoData.id || videoData.video_id;
    const VideoTitle = videoData.video_title || videoData.data.name;

    const handleLoad = (e) => {
        if(videoData.video_type===1){
            setTimeout(()=>{
                setIsLoading(false);
            },4000);
        }else{
            setTimeout(()=>{
                setIsLoading(false);
            },2000);
        }
       
    };

    const videoURL = `${BASE_URL}/admin-player/${videoId}?authorization=${token}`;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ height: "auto" }}
        // style={!isLoading ? { marginTop: "2px", height: "auto" } : { height: "auto"}}
        >
            <Modal.Header style={{ backgroundColor: "#0e3f6a", color: "white" }} closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    {VideoTitle.length > 30 ? `${VideoTitle.slice(0, 30)}...` : VideoTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={!isLoading ? { height: "460px", padding: "5px" } : { display: "flex", justifyContent: "center" }}
            >
                {isLoading && <div style={{ fontSize: "20vw", padding: "15%" }}><CSpinner /></div>}
                <div style={{ height: "470px", display: isLoading ? 'none' : 'block' }}>
                    <iframe
                        id="check"
                        style={{ border: '0', width: '100%', height: '100%' }}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        src={videoURL}
                        onLoad={(e) => handleLoad(e)}
                    ></iframe>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default VideoPlayer;
