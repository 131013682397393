import react, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CInputGroup,
    CFormSelect,
    CFormLabel,
    CInputGroupText,
    CFormCheck,
    CButton,
    CFormFeedback,
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { CiCircleRemove } from "react-icons/ci";

const EditUniversity = (props) => {
    const uniData = props.university;
    const handleShow = () => {
        setErrors({});
    };

    const domainsData = props.universityDomains;
    console.log("university data", uniData);
    console.log("uni-domains", domainsData);

    const initialFormData = {
        name: uniData ? uniData.name : "",
        phone_number: uniData ? uniData.phone_number : "",
        address1: uniData ? uniData.address1 : "",
        address2: uniData ? uniData.address2 : "",
        city: uniData ? uniData.city : "",
        state: uniData ? uniData.state : "",
        country: uniData ? uniData.country : "",
        zipcode: uniData ? uniData.zipcode : "",
        addDomains: [],
        deleteDomains: [],
        updateDomains: []
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormData(initialFormData);
    }, [uniData])

    useEffect(() => {
        if (domainsData && domainsData.length > 0) {
            const uniDomainsData = domainsData.map(domain => ({
                domainId: domain.domain_id,
                domainName: domain.domain_name,
                universityId: domain.university_id
            }));
            setFormData((prev) => ({
                ...prev,
                updateDomains: uniDomainsData,
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                updateDomains: [],
            }));
        }
    }, [domainsData]);

    const [countries, setCountries] = useState();
    const user = localStorage.getItem("user");

    useEffect(() => {
        const getCountries = async () => {
            try {
                console.log("auth user", user)
                const token = JSON.parse(user).token;
                const response = await axios.get(`${API_BASE_URL}/get-countries`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("countries list", response);
                setCountries(response.data.data);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        }

        if (user) {
            getCountries();
        }
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("field value", name, value)
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    // const [domainsField, setDomainsField] = useState(['']);

    const addDomain = () => {
        setFormData((prev) => ({
            ...prev,
            addDomains: [...prev.addDomains, ""],
        }));
    };

    const removeDomain = (index, domainId) => {
        console.log("prefill domain id to be deleted", domainId);

        if (domainId) {
            formData.updateDomains.splice(index, 1);
            setFormData((prev) => {
                return {
                    ...prev,
                    deleteDomains: [...prev.deleteDomains, domainId]
                }
            })

        } else {
            const newValuesArray = [...formData.addDomains];
            newValuesArray.splice(index, 1);
            setFormData((prev) => {
                return {
                    ...prev,
                    addDomains: newValuesArray
                }
            })
        }
    };

    const handleDomainChange = (e, index, domainId) => {
        if (domainId) {
            setFormData((prev) => {
                const updatedDomains = prev.updateDomains.map((domain) => {
                    if (domain.domainId === domainId) {
                        return { ...domain, ...{ domainName: e.target.value } };
                    }
                    return domain;
                });
                return {
                    ...prev,
                    updateDomains: updatedDomains,
                };
            });

        } else {
            console.log("indexxx", index)
            const newValuesArray = [...formData.addDomains];
            newValuesArray[index] = e.target.value;
            setFormData((prev) => {
                return {
                    ...prev,
                    addDomains: newValuesArray
                }
            })
        }
        domainValidation(e.target.value);
    };

    const [domainError, setDomainError] = useState("");
    const domainValidation = (domain) => {
        const domainRegex = /^(?!:\/\/)(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d+)?(?:\/[^\/\s]*)?$/;
        if (!domainRegex.test(domain)) {
            setDomainError("Invalid domain format")
        } else {
            setDomainError("")
        }
    }

    // console.log("domains field", domainsField);
    console.log("field to be updated", formData);

    const [flag, setFlag] = useState(false);

    const handleEditUniFlag = (flag) => {
        console.log("edit uni flag", flag)
        flag = true;
        props.editUniFlag(flag);
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            console.log("auth user", user)
            const token = JSON.parse(user).token

            console.log("fields", formData);
            const response = await axios.put(`${API_BASE_URL}/update-university/${uniData.university_id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("successfully updated university", response);
            handleEditUniFlag(setFlag(true));
            props.onHide();
        } catch (err) {
            console.log("error in editing university details", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
            }
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg':"#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.name}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Phone Number"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            isInvalid={!!errors.phone_number}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.phone_number}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Address1"
                            name="address1"
                            value={formData.address1}
                            onChange={handleChange}
                            isInvalid={!!errors.address1}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.address1}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Address2"
                            name="address2"
                            value={formData.address2}
                            onChange={handleChange}
                        />
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="City"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            isInvalid={!!errors.city}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.city}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="State"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            isInvalid={!!errors.state}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.state}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="Country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            required
                            isInvalid={!!errors.country}
                        >
                            <option>Choose a country</option>
                            {countries && countries.map((country, index) => (
                                <option key={index}>
                                    {country.country_name}
                                </option>
                            ))
                            }
                        </CFormSelect>
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.country}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Zip Code"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={handleChange}
                            isInvalid={!!errors.zipcode}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.zipcode}</CFormFeedback>
                    </CCol>

                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Add Domains</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addDomain}
                                color="primary" variant="outline"
                            >Add</CButton>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                        }}>
                            {/* prefill inputs */}
                            {formData.updateDomains && formData.updateDomains.map((domain, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormInput
                                            type="text"
                                            className="mb-3"
                                            placeholder="Add a domain"
                                            onChange={(e) => handleDomainChange(e, index, domain.domainId)}
                                            value={domain.domainName}
                                        />
                                        <span
                                            onClick={() => removeDomain(index, domain.domainId)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}

                            {formData.addDomains && formData.addDomains.map((value, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormInput
                                            type="text"
                                            className="mb-3"
                                            placeholder="Add a domain"
                                            onChange={(e) => handleDomainChange(e, index, null)}
                                            value={value}
                                        />
                                        <span
                                            onClick={() => removeDomain(index, null)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                        {/* {domainError ? <CFormFeedback style={{ color: "red" }}>
                            {domainError}</CFormFeedback> : <></>} */}
                    </div>
                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit}>
                    Submit form
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal >
    );
};

export default EditUniversity;
