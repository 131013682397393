import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
    CButton,
    CButtonGroup,
    CContainer,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow, CPagination, CPaginationItem, CSpinner, CCol, CForm, CFormInput
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPeople, cilMenu } from "@coreui/icons";

import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL } from "../../../const/const";
import axios from "axios";
import AddTag from "./AddTag";
import EditTag from "./EditTag";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import '../../styling/Pagination.css';
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import { GoSearch } from "react-icons/go";

const TagList = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const [showAddTagModal, setShowAddTagModal] = useState(false);
    const [showEditTagModal, setShowEditTagModal] = useState(false);
    const [showDeleteConfirmTagModal, setShowDeleteConfirmTagModal] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);

    const [tags, setTags] = useState([]);
    const [flag, setFlag] = useState(false);
    const [searchQuery, setSearchQuery] = useState();

    const user = localStorage.getItem("user");

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("authenticated user", user)
        const token = JSON.parse(user).token;
        const getTags = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-tags`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("tag list", response);
                setTags(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching tag:", error);
            }
        };

        if (user) {
            getTags();
            setFlag(false);
        }
    }, [flag]);

    const tagTypeMapping = {
        1: "Faculty",
        2: "Course",
        3: "Others"
    }

    const handleAddTagFlag = (addTagFlag) => {
        console.log("add state", addTagFlag);
        setFlag(addTagFlag);
    }

    const handleEditTagFlag = (editTagFlag) => {
        console.log("edit state", editTagFlag)
        setFlag(editTagFlag);
    }

    const updateTag = async (e, tag) => {
        setShowEditTagModal(true);
        setSelectedTag(tag);
    }

    const handleTagDeleteConfirmation = (e, tag) => {
        setShowDeleteConfirmTagModal(true);
        setSelectedTag(tag);
    }

    const deleteTag = async (e, tag) => {
        try {
            e.preventDefault();
            setShowDeleteConfirmTagModal(false);
            const userAuth = localStorage.getItem("user");
            const token = JSON.parse(userAuth).token;
            const tag_id = tag.tag_id;
            console.log("tag id to be deleted", tag_id);
            const response = await axios.delete(`${API_BASE_URL}/delete-tags/${tag_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            console.log("success", response.data.message);
            setFlag(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [stateSearch, setStateSearch] = useState();
    const [searchError, setSearchError] = useState("");
    const [searchData, setSearchData] = useState([]);

    const handleInputChange = (e) => {
        setStateSearch(true);
        setSearchQuery(e.target.value);
        if (e.target.value.trim() === '') {
            setSearchData(displayTags);
            setSearchError("");
        } else {
            filterData(e.target.value);
        }
    };

    const filterData = (query) => {
        const tagTypeMap = {
            "faculty": 1,
            "course": 2,
            "others": 3
        };
    
        const queryKey = query.toLowerCase();   
        const partialMatches = Object.keys(tagTypeMap).filter(key =>
            key.toLowerCase().includes(queryKey)
        );
    
        const partialMatchValues = partialMatches.map(key => tagTypeMap[key]);
    
        const filteredData = displayTags.filter(item =>
            item.tag_name.toLowerCase().includes(queryKey) ||
            partialMatchValues.includes(item.tag_type)
        );
    
        if (filteredData.length === 0) {
            setSearchError("No data found");
        } else {
            setSearchData(filteredData);
            setSearchError("");
        }
    };
    

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 25;
    const pageVisited = pageNumber * itemsPerPage;

    const displayTags = tags.slice(pageVisited, pageVisited + itemsPerPage);

    const pageCount = Math.ceil(tags.length / itemsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a",
        '--cui-btn-padding-y': "5px"
    }


    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <CHeader className="d-flex">
                    <CContainer>
                        <CHeaderToggler
                            className="ps-1"
                            onClick={() =>
                                dispatch({ type: "set", sidebarShow: !sidebarShow })
                            }
                        >
                            <CIcon icon={cilMenu} size="lg" />
                        </CHeaderToggler>
                        <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                            <h4 style={{ marginRight: '3vw' }}>Tags List</h4>
                            <CCol sm={4}>
                                <CForm className="d-flex">
                                    <CFormInput
                                        type="text"
                                        placeholder="Search"
                                        className="me-2 rounded-pill"
                                        style={{ width: "20vw" }}
                                        aria-label="Search"
                                        onChange={handleInputChange}
                                        value={searchQuery}
                                    />
                                    <span style={{
                                        marginLeft: "-3vw",
                                        marginTop: "1vh"
                                    }}><GoSearch /> </span>
                                </CForm>
                            </CCol>
                        </CHeaderNav>
                        <CHeaderNav>
                            <CButtonGroup>
                                <CButton
                                    style={btn} variant="outline"
                                    onClick={() => setShowAddTagModal(true)}
                                >
                                    Add Tag
                                </CButton>
                            </CButtonGroup>
                        </CHeaderNav>
                    </CContainer>
                </CHeader>
                <div className="ms-4 mt-5 me-4">
                    <CTable striped>
                        <CTableHead style={tableHeader}>
                            <CTableRow>
                                <CTableHeaderCell style={{ width: "40%", paddingLeft: "3vw" }}>
                                    Tag Name
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: "35%" }}>
                                    Tag Type
                                </CTableHeaderCell>
                                <CTableHeaderCell>
                                    Tag Actions
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody style={{ padding: "5px" }}>
                            {stateSearch ? (searchError !== "" ?
                                (<CTableRow className="text-center">
                                    <CTableDataCell colSpan={6}>{searchError}</CTableDataCell>
                                </CTableRow>) :
                                searchData && searchData.map((tag, index) => (
                                    <CTableRow
                                        v-for="item in tableItems"
                                        key={index}>
                                        <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                            <span>
                                                {tag.tag_name}
                                            </span>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>
                                                <span>{tagTypeMapping[tag.tag_type]}</span>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div className="d-flex mt-2 cursor-pointer">
                                                <div
                                                    style={{ color: "darkgreen", marginRight: "1vw" }}
                                                    onClick={(e) => updateTag(e, tag)}
                                                >
                                                    <AiTwotoneEdit />
                                                </div>
                                                <span style={{ color: "red", marginRight: "1vw" }}
                                                    onClick={(e) => handleTagDeleteConfirmation(e, tag)}
                                                >
                                                    <AiTwotoneDelete />
                                                </span>
                                            </div>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))
                            ) : isLoading ? <CTableRow>
                                <CTableDataCell className="text-center" colSpan="6">
                                    <CSpinner />
                                </CTableDataCell>
                            </CTableRow> : displayTags.length == 0 ? <CTableRow>
                                <CTableDataCell className="text-center" colSpan="4">
                                    <span>No data to show</span>
                                </CTableDataCell>
                            </CTableRow> : displayTags.map((tag, index) => (
                                <CTableRow
                                    v-for="item in tableItems"
                                    key={index}>
                                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                        <span>
                                            {tag.tag_name}
                                        </span>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div>
                                            <span>{tagTypeMapping[tag.tag_type]}</span>
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div className="d-flex mt-2 cursor-pointer">
                                            <div
                                                style={{ color: "darkgreen", marginRight: "1vw" }}
                                                onClick={(e) => updateTag(e, tag)}
                                            >
                                                <AiTwotoneEdit />
                                            </div>
                                            <span style={{ color: "red", marginRight: "1vw" }}
                                                onClick={(e) => handleTagDeleteConfirmation(e, tag)}
                                            >
                                                <AiTwotoneDelete />
                                            </span>
                                        </div>
                                    </CTableDataCell>
                                </CTableRow>
                            ))
                            }
                        </CTableBody>
                    </CTable>
                </div>
                {displayTags.length > 0 && !searchError &&
                    (<CPagination align="center" aria-label="Page navigation example" className="cursor-pointer">
                        <CPaginationItem
                            aria-label="Previous"
                            onClick={() => setPageNumber(pageNumber - 1)}
                            disabled={pageNumber === 0}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </CPaginationItem>

                        {Array.from({ length: pageCount }, (_, index) => (
                            <CPaginationItem
                                key={index + 1}
                                active={pageNumber === index}
                                onClick={() => setPageNumber(index)}
                            >
                                {index + 1}
                            </CPaginationItem>
                        ))}

                        <CPaginationItem
                            aria-label="Next"
                            onClick={() => setPageNumber(pageNumber + 1)}
                            disabled={pageNumber === pageCount - 1}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </CPaginationItem>
                    </CPagination>)}
            </div>
            <AddTag
                show={showAddTagModal}
                onHide={() => { setShowAddTagModal(false) }}
                addTagFlag={handleAddTagFlag} />
            <EditTag
                show={showEditTagModal}
                onHide={() => setShowEditTagModal(false)}
                tag={selectedTag}
                editTagFlag={handleEditTagFlag}
            />
            <ConfirmationModal
                show={showDeleteConfirmTagModal}
                handleClose={() => { setShowDeleteConfirmTagModal(false) }}
                handleConfirm={(e) => deleteTag(e, selectedTag)}
            />
        </div>
    );
};

export default TagList;
