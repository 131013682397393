import react, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { CForm, CCol, CFormSelect, CButton, CFormInput, CFormFeedback } from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { useNavigate } from "react-router-dom";
import { Calendar } from 'primereact/calendar';

const AddUniSubs = (props) => {
    const navigate = useNavigate();

    const handleShow = () => {
        setFormData({
            university_id: "",
            university_name: "",
            subscription_plan_id: "",
            subscription_name: "",
            start_date: "",
            end_date: ""
        });
        setFlag(false);
        setErrors({});
        setSubmitted(false);
    };

    const [formData, setFormData] = useState({
        university_id: "",
        university_name: "",
        subscription_plan_id: "",
        subscription_name: "",
        start_date: "",
        end_date: ""
    });
    const user = localStorage.getItem("user");

    const [errors, setErrors] = useState({});

    const [universities, setUniversities] = useState([]);
    const [subsList, setSubsList] = useState([]);

    useEffect(() => {
        console.log("authenticated user", user)
        const token = JSON.parse(user).token;
        const getSubscription = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-subscription`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Subscription List", response);
                setSubsList(response.data.data);
            } catch (error) {
                console.error("subs:", error);
            }
        };

        const getUniversity = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-university`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("uni List", response);
                setUniversities(response.data.data);
            } catch (error) {
                console.error("uni:", error);
            }
        }

        if (user) {
            getSubscription();
            getUniversity()
        }
    }, []);

    const handleUniChange = (uniData) => {
        console.log("uni data", uniData);

        setFormData((prev) => ({
            ...prev,
            university_id: uniData.universityId,
            university_name: uniData.universityName,
        }));
        setErrors((prev) => ({ ...prev, university_id: "" }));
    };

    const handleSubsChange = (subsData) => {
        console.log("subs data", subsData)

        setFormData((prev) => ({
            ...prev,
            subscription_plan_id: subsData.subscriptionId,
            subscription_name: subsData.subscriptionName,
        }));
        setErrors((prev) => ({ ...prev, subscription_plan_id: "" }));
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, start_date: "", end_date: "" }));
    };

    const [flag, setFlag] = useState(false);

    const handleAddUniSubsFlag = (flag) => {
        console.log("add flag", flag);
        flag = true;
        props.addUniSubsFlag(flag);
    }

    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setSubmitted(true);
            console.log("auth user", user)
            const token = JSON.parse(user).token;

            console.log("fields", formData);
            const response = await axios.post(`${API_BASE_URL}/add-uni-subs`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("successfully added uni-subs", response);
            handleAddUniSubsFlag(setFlag(true));
            props.onHide();
        } catch (err) {
            console.log("error in adding uni-subs", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
            }

            if (err.response && err.response.status === 401) {
                navigate('/login');
            }
        } finally {
            setSubmitted(false);
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New University Subscription
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="University"
                            name="university_id"
                            onChange={(e) =>
                                handleUniChange({
                                    universityId: e.target.value,
                                    universityName: e.target.options[e.target.selectedIndex].text,
                                })
                            }
                            isInvalid={!!errors.university_id}
                        >
                            <option>Choose an university</option>
                            {universities && universities.map((uni) => (
                                <option key={uni.university_id} value={uni.university_id}>
                                    {uni.name}</option>
                            ))}
                        </CFormSelect>
                        <CFormFeedback type="invalid"
                            style={{ color: "red" }}>{errors.university_id}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="Subscription"
                            name="subscription_plan_id"
                            onChange={(e) =>
                                handleSubsChange({
                                    subscriptionId: e.target.value,
                                    subscriptionName: e.target.options[e.target.selectedIndex].text,
                                })
                            }
                            isInvalid={!!errors.subscription_plan_id}
                        >
                            <option>Choose a subscription plan</option>
                            {subsList && subsList.map((subs) => (
                                <option key={subs.subscription_plan_id} value={subs.subscription_plan_id}>
                                    {subs.name}</option>
                            ))}
                        </CFormSelect>
                        <CFormFeedback type="invalid"
                            style={{ color: "red" }}>{errors.subscription_plan_id}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="date"
                            label="Start Date"
                            name="start_date"
                            onChange={handleDateChange}
                            isInvalid={!!errors.start_date}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.start_date}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="date"
                            label="End Date"
                            name="end_date"
                            onChange={handleDateChange}
                            isInvalid={!!errors.end_date}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.end_date}</CFormFeedback>
                    </CCol>
                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit}  disabled={submitted}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default AddUniSubs;
