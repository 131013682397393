import React from "react";
import { Modal, Button } from 'react-bootstrap';

const ResetSuccessModal = ({ show, handleClose, handleConfirm }) => {
    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>Password Reset Successful</Modal.Header>
            <Modal.Body>
                Your password has been successfully reset.
            </Modal.Body>
            <Modal.Footer>
                <Button style={btn} onClick={handleConfirm}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ResetSuccessModal;
