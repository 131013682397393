import react, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CForm, CCol, CFormSelect, CButton, CFormInput, CFormTextarea, CFormFeedback } from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { useNavigate } from "react-router-dom";
import { CiCircleRemove } from "react-icons/ci";

const AddCourse = (props) => {
  const navigate = useNavigate();

  const handleShow = () => {
    setFormData({
      name: "",
      description: "",
      addFaculty: [],
      deleteFaculty: []
    });
    setFacultyData([]),
    setFlag(false);
    setErrors({});
    setSubmitted(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    addFaculty: [],
    deleteFaculty: []
  });
  const user = localStorage.getItem("user");

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const [facultyData, setFacultyData] = useState([]);
  const [facultyList, setFacultyList] = useState()

  useEffect(() => {
    console.log("auth user", user);
    const token = JSON.parse(user).token;

    const getFacultyList = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/get-faculty`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("faculty list", response);
        setFacultyList(response.data.data);

      } catch (error) {
        console.error("Error fetching faculty:", error);
      }
    };

    if (user) {
      getFacultyList();
    }
  }, []);

  const addFaculty = () => {
    const newFacultyData = [...facultyData, 'newFaculty'];
    setFacultyData(newFacultyData);
  };

  const removeFaculty = (index) => {
    console.log('index to be removed', index);
    const newFacultyData = [...facultyData];
    newFacultyData.splice(index, 1);

    console.log('facultyId to be removed', formData.addFaculty[index]);
    setFormData((prev) => {
      const newDeleteFaculty = [...(prev.deleteFaculty || []), formData.addFaculty[index]];
      const newAddFaculty = [...(prev.addFaculty || [])];
      newAddFaculty.splice(index, 1);
      return {
        ...prev,
        deleteFaculty: newDeleteFaculty,
        addFaculty: newAddFaculty
      };
    });

    setFacultyData(newFacultyData);
  };

  const handleFacultyChange = (index, e) => {
    const selectedFacultyId = parseInt(e.target.value, 10);
    const selectedFaculty = facultyList.find(faculty => faculty.faculty_id === selectedFacultyId);
    console.log(selectedFaculty);

    setFormData((prev) => {
      const newAddFaculty = [...(prev.addFaculty || [])];

      if (selectedFaculty && selectedFaculty.faculty_id) {
        newAddFaculty[index] = selectedFaculty.faculty_id;
      } else {
        console.error("Selected faculty is undefined or missing faculty_id property for addFaculty");
      }

      return {
        ...prev,
        addFaculty: newAddFaculty
      };
    });
    setErrors((prev) => ({ ...prev, addFaculty: "" }));
  };

  console.log('faculty data', facultyData);
  console.log('field change', formData);

  const [flag, setFlag] = useState(false);

  const handleAddCourseFlag = (flag) => {
    console.log("add flag", flag);
    flag = true;
    props.addCourseFlag(flag);
  }

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitted(true);
      console.log("auth user", user)
      const token = JSON.parse(user).token;

      console.log("fields", formData);
      const response = await axios.post(`${API_BASE_URL}/add-course`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("successfully added course", response);
      handleAddCourseFlag(setFlag(true));
      props.onHide();
    } catch (err) {
      console.log("error in adding course", err);
      if (err.response.data.errors) {
        const apiErrors = err.response.data.errors;
        const fieldErrors = {};
        Object.keys(apiErrors).forEach((field) => {
          fieldErrors[field] = apiErrors[field].join(" ");
        });
        setErrors(fieldErrors);
      }
      if (err.response && err.response.status === 401) {
        navigate('/login');
      }
    } finally {
      setSubmitted(false);
    }
  };

  const btn = {
    '--cui-btn-bg': "#0e3f6a",
    '--cui-btn-color': "white",
    '--cui-btn-hover-bg': "#3c97cb",
    '--cui-btn-active-bg': "#0e3f6a"
  }

  return (
    <Modal
      {...props}
      size="lg"
      onShow={handleShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{
        backgroundColor: "#0e3f6a",
        color: "white"
      }}
        closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Course
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CForm className="row g-3">
          <CCol md={12}>
            <CFormInput
              type="text"
              label="Course Name"
              name="name"
              placeholder="Enter a course name"
              onChange={handleChange}
              isInvalid={!!errors.name}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.name}</CFormFeedback>
          </CCol>
          <CCol md={12}>
            <CFormTextarea
              rows={3}
              label="Description"
              name="description"
              placeholder="Add a description for the course"
              onChange={handleChange}
              isInvalid={!!errors.description}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.description}</CFormFeedback>
          </CCol>

          <div>
            <h6 style={{ fontWeight: "normal" }}>Add Faculty</h6>
            <div style={{ marginBottom: "10px" }}>
              <CButton
                onClick={addFaculty}
                style={btn}
              >Add</CButton>
            </div>
            <div>
              {facultyData.map((faculty, index) => (
                <div key={index}>
                  <CCol md={12} style={{
                    display: "flex"
                  }}>
                    <CFormSelect
                      size="md"
                      className="mb-3"
                      aria-label="Small select example"
                      placeholder="Add a faculty"
                      onChange={(e) => handleFacultyChange(index, e)}
                      required
                    >
                      <option>Choose a faculty</option>
                      {facultyList.map((faculty) => (
                        <option key={faculty.faculty_id} value={faculty.faculty_id}>
                          {faculty.first_name} {faculty.last_name}
                        </option>
                      ))}
                    </CFormSelect>
                    <span
                      onClick={() => removeFaculty(index)}
                      style={{
                        color: "red",
                        fontSize: "30px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        marginTop: "-8px"
                      }}
                    ><CiCircleRemove /></span>
                  </CCol>
                </div>
              ))}
            </div>
          </div>
        </CForm>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        <CButton type="submit"
          onClick={handleSubmit}
          style={btn}
          disabled={submitted}>
          Submit
        </CButton>
        <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCourse;
