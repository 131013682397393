import react, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CInputGroup,
    CFormSelect,
    CFormLabel,
    CInputGroupText,
    CFormCheck,
    CButton,
    CFormFeedback,
    CFormTextarea
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { CiCircleRemove } from "react-icons/ci";

const AddSubscription = (props) => {
    const handleShow = () => {
        setFormData({
            name: "",
            description: "",
            price: "",
            addPrograms: [],
            deletePrograms: [],
            addCourses: [],
            deleteCourses: [],
            // addModules: [],
            // deleteModules: [],
            addVideos: [],
            deleteVideos: []
        });
        setProgramsData([]);
        setCoursesData([]);
        // setModulesData([]);
        setVideosData([])
        setFlag(false);
        setErrors({});
        setSubmitted(false);
    };

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        price: "",
        addCourses: [],
        deleteCourses: [],
        addPrograms: [],
        deletePrograms: [],
        deleteModules: [],
        // addModules: [],
        // deleteModules: [],
        addVideos: [],
        deleteVideos: []
    });

    const user = localStorage.getItem("user");

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const [programsData, setProgramsData] = useState([]);
    const [programList, setProgramList] = useState();

    useEffect(() => {
        console.log("auth user", user)
        const token = JSON.parse(user).token;

        const getProgramList = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-program`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("program list", response);
                setProgramList(response.data.data);

            } catch (error) {
                console.error("Error fetching programs:", error);
            }
        };
        if (user) {
            getProgramList();
        }
    }, []);

    const addProgram = () => {
        const newProgramsData = [...programsData, 'newProgram'];
        setProgramsData(newProgramsData);
    };

    const removeProgram = (index) => {
        console.log('index to be removed', index);
        const newProgramsData = [...programsData];
        newProgramsData.splice(index, 1);

        console.log('programId to be removed', formData.addPrograms[index]);
        setFormData((prev) => {
            const newDeletePrograms = [...(prev.deletePrograms || []), formData.addPrograms[index]];
            const newAddPrograms = [...(prev.addPrograms || [])];
            newAddPrograms.splice(index, 1);
            return {
                ...prev,
                deletePrograms: newDeletePrograms,
                addPrograms: newAddPrograms
            };
        });

        setProgramsData(newProgramsData);
    };

    const handleProgramChange = (index, e) => {
        const selectedProgramId = parseInt(e.target.value, 10);
        const selectedProgram = programList.find(program => program.program_id === selectedProgramId);
        console.log("selected program", selectedProgram);

        setFormData((prev) => {
            const newAddPrograms = [...(prev.addPrograms || [])];

            if (selectedProgram && selectedProgram.program_id) {
                newAddPrograms[index] = selectedProgram.program_id;
            } else {
                console.error("Selected program is undefined or missing program_id property for addProgram");
            }

            return {
                ...prev,
                addPrograms: newAddPrograms
            };
        });
        setErrors((prev) => ({ ...prev, addPrograms: "" }));
    };
    console.log('program data', programsData);


    const [coursesData, setCoursesData] = useState([]);
    const [courseList, setCourseList] = useState()

    useEffect(() => {
        console.log("auth user", user)
        const token = JSON.parse(user).token;

        const getCourseList = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-courses`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("course list", response);
                setCourseList(response.data.data);

            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };
        if (user) {
            getCourseList();
        }
    }, [])

    const addCourse = () => {
        const newCoursesData = [...coursesData, 'newCourse'];
        setCoursesData(newCoursesData);
    };

    const removeCourse = (index) => {
        console.log('index to be removed', index);
        const newCoursesData = [...coursesData];
        newCoursesData.splice(index, 1);

        console.log('courseId to be removed', formData.addCourses[index]);
        setFormData((prev) => {
            const newDeleteCourses = [...(prev.deleteCourses || []), formData.addCourses[index]];
            const newAddCourses = [...(prev.addCourses || [])];
            newAddCourses.splice(index, 1);
            return {
                ...prev,
                deleteCourses: newDeleteCourses,
                addCourses: newAddCourses
            };
        });

        setCoursesData(newCoursesData);
    };

    const handleCourseChange = (index, e) => {
        const selectedCourseId = parseInt(e.target.value, 10);
        const selectedCourse = courseList.find(course => course.course_id === selectedCourseId);
        console.log("selected course", selectedCourse);

        setFormData((prev) => {
            const newAddCourses = [...(prev.addCourses || [])];

            if (selectedCourse && selectedCourse.course_id) {
                newAddCourses[index] = selectedCourse.course_id;
            } else {
                console.error("Selected course is undefined or missing course_id property for addCourse");
            }

            return {
                ...prev,
                addCourses: newAddCourses
            };
        });
        setErrors((prev) => ({ ...prev, addCourses: "" }));
    };
    console.log('course data', coursesData);

    const [videosData, setVideosData] = useState([]);
    const [videoList, setVideoList] = useState()

    useEffect(() => {
        console.log("auth user", user)
        const token = JSON.parse(user).token;

        const getVideoList = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-videos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("video list", response);
                setVideoList(response.data.data);

            } catch (error) {
                console.error("Error fetching videos:", error);
            }
        };
        if (user) {
            getVideoList();
        }
    }, [])

    const addVideo = () => {
        const newVideosData = [...videosData, 'newVideo'];
        setVideosData(newVideosData);
    };

    const removeVideo = (index) => {
        console.log('index to be removed', index);
        const newVideosData = [...videosData];
        newVideosData.splice(index, 1);

        console.log('videoId to be removed', formData.addVideos[index]);
        setFormData((prev) => {
            const newDeleteVideos = [...(prev.deleteVideos || []), formData.addVideos[index]];
            const newAddVideos = [...(prev.addVideos || [])];
            newAddVideos.splice(index, 1);
            return {
                ...prev,
                deleteVideos: newDeleteVideos,
                addVideos: newAddVideos
            };
        });

        setVideosData(newVideosData);
    };

    const handleVideoChange = (index, e) => {
        const selectedVideoId = parseInt(e.target.value, 10);
        const selectedVideo = videoList.find(video => video.video_id === selectedVideoId);
        console.log("selected video", selectedVideo);

        setFormData((prev) => {
            const newAddVideos = [...(prev.addVideos || [])];

            if (selectedVideo && selectedVideo.video_id) {
                newAddVideos[index] = selectedVideo.video_id;
            } else {
                console.error("Selected video is undefined or missing video_id property for addVideos");
            }

            return {
                ...prev,
                addVideos: newAddVideos
            };
        });
        setErrors((prev) => ({ ...prev, addVideos: "" }));
    };
    console.log('video data', videosData);

    console.log('fields change', formData);

    const [flag, setFlag] = useState(false);

    const handleAddSubsFlag = (flag) => {
        console.log("add flag", flag);
        props.addSubsFlag(flag);
    }

    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setSubmitted(true);
            console.log("auth user", user)
            const token = JSON.parse(user).token;

            console.log("fields", formData);
            const response = await axios.post(`${API_BASE_URL}/add-subscription`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("successfully added subscription", response);
            handleAddSubsFlag(setFlag(true));
            props.onHide();
        } catch (err) {
            console.log("error in adding subscription", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
            }
        } finally {
            setSubmitted(false);
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Subscription
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Subscription Name"
                            name="name"
                            placeholder="Enter a subscription name"
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.name}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormTextarea
                            rows={3}
                            label="Description"
                            name="description"
                            placeholder="Add a description for the subscription"
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.description}</CFormFeedback>
                    </CCol>

                    {/* programs */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Add Programs</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addProgram}
                                style={btn}
                            >Add</CButton>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {programsData.map((program, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Add a program in subscription"
                                            onChange={(e) => handleProgramChange(index, e)}
                                        >
                                            <option>Choose a program</option>
                                            {programList.map((program) => (
                                                <option key={program.program_id} value={program.program_id}>
                                                    {program.name}</option>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeProgram(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* courses */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Add Courses</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addCourse}
                                style={btn}
                            >Add</CButton>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {coursesData.map((course, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Add a course in subscription"
                                            onChange={(e) => handleCourseChange(index, e)}
                                        >
                                            <option>Choose a course</option>
                                            {courseList.map((course) => (
                                                <option key={course.course_id} value={course.course_id}>
                                                    {course.name}</option>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeCourse(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* modules */}
                    {/* <div>
                        <h6 style={{ fontWeight: "normal" }}>Add Modules</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addModule}
                                style={btn}
                            >Add</CButton>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {modulesData.map((module, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Add a module in subscription"
                                            onChange={(e) => handleModuleChange(index, e)}
                                        >
                                            <option>Choose a module</option>
                                            {moduleList.map((module) => (
                                                <option key={module.module_id} value={module.module_id}>
                                                    {module.name}</option>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeModule(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div> */}

                    {/* videos */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Add Videos</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addVideo}
                                style={btn}
                            >Add</CButton>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {videosData.map((video, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Add a video in subscription"
                                            onChange={(e) => handleVideoChange(index, e)}
                                        >
                                            <option>Choose a video</option>
                                            {videoList.map((video) => (
                                                <option key={video.video_id} value={video.video_id}>
                                                    {video.video_title}
                                                </option>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeVideo(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit}  disabled={submitted}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default AddSubscription;
