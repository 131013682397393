import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
    CContainer,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CSpinner
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL, WEB_URL } from "../../../const/const";
import axios from "axios";
import '../../styling/Pagination.css';
import { useLocation, useNavigate } from "react-router-dom";
import copy from 'clipboard-copy';
import { IFRAME_URL } from "../../../const/iframeConst";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import "./style.css"
import { BreadCrumb } from "primereact/breadcrumb";
import { MdContentCopy } from "react-icons/md";
import { BsFiletypeCsv, BsFiletypeXls } from "react-icons/bs";

const SubsContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [subsDetails, setSubsDetails] = useState();
    const [subsContent, setSubsContent] = useState();

    const user = localStorage.getItem("user");
    const { token, university_id } = JSON.parse(user);

    const [isLoading, setIsLoading] = useState(false);

    const tableStyle = {
        minWidth: '50rem',
        backgroundColor: '#3c97cb',
        border: '1px solid #ddd',
    };

    const [programTreeTableData, setProgramTreeTableData] = useState([]);
    const [courseTreeTableData, setCourseTreeTableData] = useState([]);
    const [videoTreeTableData, setVideoTreeTableData] = useState([]);

    const [programCourse, setProgramCourse] = useState([]);
    const [programVideo, setProgramVideo] = useState([]);
    const [courseVideos, setCourseVideos] = useState([]);

    const [subsProgram, setSubsProgram] = useState([]);
    const [subsCourse, setSubsCourse] = useState([]);
    const [subsVideo, setSubsVideo] = useState([]);

    const [excelData, setExcelData] = useState([]);

    useEffect(() => {
        const navItem = document.querySelectorAll("[href='/my-subscription-list1']");
        console.log("nav", navItem);
        // navItem.classList.add("active");

        // const navGroup = document.querySelector(".nav-group");
        // navGroup.classList.add("show")

        console.log("authenticated user", user)

        const getPrograms = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-uni-subs-program`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Program-content", response);
                setProgramCourse(response.data.courseResults);
                setProgramVideo(response.data.videoResults);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching program details:", error);
                setIsLoading(false);
            }
        }

        const getCourseVideos = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-uni-subs-course-videos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Course-videos details", response);
                setCourseVideos(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching course-videos:", error);
            }
        };

        const getSubs = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-uni-subs-detail/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Subs Details", response);
                setSubsDetails(response.data.data);
                getSubsContent(response.data.data.subscription_plan_id)
            } catch (error) {
                console.error("Error fetching subscription details:", error);
            }
        };

        const getSubsContent = async (subsPlanId) => {
            try {
                console.log("subs-id", subsPlanId);
                const response = await axios.get(`${API_BASE_URL}/get-subs-content/${subsPlanId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Subs-Content", response);
                setSubsContent(response.data.data);
                setSubsProgram(response.data.programResults);
                setSubsCourse(response.data.courseResults);
                setSubsVideo(response.data.videoResults);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching subscription content:", error);
            }
        };

        if (user) {
            getPrograms();
            getCourseVideos();
            getSubs();
        }
    }, []);

    useEffect(() => {
        if (subsProgram && subsCourse && subsVideo && programCourse
            && programVideo && courseVideos) {
            setProgramTreeTableData(createProgramTreeTableData(
                subsProgram, programCourse, courseVideos, programVideo
            ));
            setCourseTreeTableData(
                createCourseTreeTableData(subsCourse, courseVideos)
            );
            setVideoTreeTableData(createVideoTreeTableData(subsVideo));

            setExcelData(prepareFileData(subsProgram, subsCourse, subsVideo,
                programCourse, programVideo, courseVideos));
        }
    }, [subsProgram,
        subsCourse,
        subsVideo,
        programCourse,
        programVideo,
        courseVideos
    ]);

    const createProgramTreeTableData = (programs, programCourses, programCourseVideos, programVideos) => {
        return programs.map((program, index) => {
            const filteredProgramCourses = programCourses && programCourses.filter(course =>
                course.program_id === program.content_id);
            const filteredProgramVideos = programVideos && programVideos.filter(video =>
                video.program_id === program.content_id);

            const programNode = {
                key: String(index),
                id: program.content_id,
                hasFeature: false,
                data: {
                    name: program.name.length > 25 ? `${program.name.slice(0, 25)}...` : program.name,
                    description: program.description
                },
                children: [
                    ...(filteredProgramCourses && filteredProgramCourses.length > 0
                        ? filteredProgramCourses.map((course, courseIndex) => {
                            const courseChildren = [];

                            const filteredProgramCourseVideos = programCourseVideos && programCourseVideos.filter(video =>
                                video.course_id === course.course_id);

                            courseChildren.push(
                                ...(filteredProgramCourseVideos && filteredProgramCourseVideos.length > 0
                                    ? filteredProgramCourseVideos.map((video, videoIndex) => ({
                                        key: `video-${videoIndex}`,
                                        id: video.video_id,
                                        hasFeature: true,
                                        data: {
                                            name: video.video_title.length > 25 ? `${video.video_title.slice(0, 25)}...` : video.video_title,
                                            description: video.description
                                        },
                                        children: [],
                                    }))
                                    : []
                                )
                            );

                            return {
                                key: `course-${courseIndex}`,
                                id: course.course_id,
                                hasFeature: false,
                                data: {
                                    name: course.name.length > 25 ? `${course.name.slice(0, 25)}...` : course.name,
                                    description: course.description
                                },
                                children: courseChildren,
                            };
                        })
                        : []
                    ),
                    ...(filteredProgramVideos && filteredProgramVideos.length > 0
                        ? filteredProgramVideos.map((video, videoIndex) => ({
                            key: `video-${videoIndex}`,
                            id: video.video_id,
                            hasFeature: true,
                            data: {
                                name: video.video_title.length > 25 ? `${video.video_title.slice(0, 25)}...` : video.video_title,
                                description: video.description
                            },
                            children: [],
                        }))
                        : []
                    ),
                ]
            };
            return programNode;
        });
    };

    const createCourseTreeTableData = (courses, courseVideos) => {
        return courses.map((course, index) => {
            const filteredCourseVideos = courseVideos && courseVideos.filter(video =>
                video.course_id === course.content_id);
            const courseNode = {
                key: String(index),
                id: course.content_id,
                hasFeature: false,
                data: {
                    name: course.name.length > 25 ? `${course.name.slice(0, 25)}...` : course.name,
                    description: course.description
                },
                children: [
                    ...(filteredCourseVideos && filteredCourseVideos.length > 0
                        ? filteredCourseVideos.map((video, videoIndex) => ({
                            key: `video-${videoIndex}`,
                            id: video.video_id,
                            hasFeature: true,
                            data: {
                                name: video.video_title.length > 25 ? `${video.video_title.slice(0, 25)}...` : video.video_title,
                                description: video.description
                            },
                            children: [],
                        }))
                        : []
                    ),
                ]
            };
            return courseNode;
        });
    };

    const createVideoTreeTableData = (videos) => {
        return videos.map((video, index) => {
            const videoNode = {
                key: String(index),
                id: video.content_id,
                data: {
                    name: video.video_title.length > 25 ? `${video.video_title.slice(0, 25)}...` : video.video_title,
                    description: video.description,
                    actions: createActionsColumn(video.content_id, video.video_title),
                },
                children: []
            };
            return videoNode;
        });
    };

    const [isCopied, setIsCopied] = useState({});

    const handleCopyClick = (video_id, video_title) => {

        const embedCode = `<iframe width="560" height="400" src="${IFRAME_URL}/player/${university_id}/${video_id}" title="${video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`;

        copy(embedCode);
        setIsCopied((prevState) => ({ ...prevState, [video_id]: true }));

        setTimeout(() => {
            setIsCopied((prevState) => ({ ...prevState, [video_id]: false }));
        }, 5000);
    };

    const createActionsColumn = (video_id, video_title) => {
        return [
            {
                label: 'Copy Embed Code',
                icon: 'pi pi-copy',
                command: () => handleCopyClick(video_id, video_title)
            }
        ];
    };

    const handleXlsxDownload = async () => {
        try {
            const fileName = subsDetails.subscription_name;
            const data = fileData(excelData);
            const response = await axios.post(`${API_BASE_URL}/xlsx-download`,
                { data, fileName, headers }, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}.xlsx`;

            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.log('Error downloading xlsx-file:', error);
        }
    }

    const handleCsvDownload = async () => {
        try {
            const fileName = subsDetails.subscription_name;
            const response = await axios.post(`${API_BASE_URL}/csv-download`,
                { excelData, fileName, headers }, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            );

            console.log("csv-response", response)

            const blob = new Blob([response.data], { type: 'text/csv' });

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);

        } catch (error) {
            console.log('Error downloading csv-file:', error);
        }
    }

    const prepareFileData = (subscriptionProgram, subscriptionCourse, subscriptionVideo,
        programCourse, programVideo, courseVideos) => {
        const programData = subscriptionProgram.map(program => ({
            program_title: program.name,
            program_id: program.content_id
        }));

        const courseData = subscriptionCourse.map(course => ({
            course_title: course.name,
            course_id: course.content_id
        }));

        const videoData = subscriptionVideo.map(video => ({
            video_title: video.video_title,
            video_id: video.content_id
        }));

        const excelData = [];

        let index = 1;

        videoData.forEach(video => {
            excelData.push({
                sno: index++,
                program_title: '',
                course_title: '',
                video_title: video.video_title,
                embed_code: `<iframe width="560" height="400" src="${IFRAME_URL}/player/${university_id}/${video.video_id}" title="${video.video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`
            });
        });

        courseData.forEach(course => {
            const matchingVideos = courseVideos.filter(video => video.course_id === course.course_id);
            matchingVideos.forEach(video => {
                excelData.push({
                    sno: index++,
                    program_title: '',
                    course_title: course.course_title,
                    video_title: video.video_title,
                    embed_code: `<iframe width="560" height="400" src="${IFRAME_URL}/player/${university_id}/${video.video_id}" title="${video.video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`
                });
            });
        });

        programData.forEach(program => {
            const matchingCourses = programCourse.filter(course => course.program_id === program.program_id);
            matchingCourses.forEach(course => {
                const matchingVideos = courseVideos.filter(video => video.course_id === course.course_id);
                matchingVideos.forEach(video => {
                    excelData.push({
                        sno: index++,
                        program_title: program.program_title,
                        course_title: course.name,
                        video_title: video.video_title,
                        embed_code: `<iframe width="560" height="400" src="${IFRAME_URL}/player/${university_id}/${video.video_id}" title="${video.video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`
                    });
                });
            });

            const matchingVideos = programVideo.filter(video => video.program_id === program.program_id);
            matchingVideos.forEach(video => {
                excelData.push({
                    sno: index++,
                    program_title: program.program_title,
                    course_title: '',
                    video_title: video.video_title,
                    embed_code: `<iframe width="560" height="400" src="${IFRAME_URL}/player/${university_id}/${video.content_id}" title="${video.video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`
                });
            });
        });

        return excelData;
    };

    const fileData = (arrayOfObjects) => {
        if (!arrayOfObjects || !arrayOfObjects.length) {
            return [];
        }

        const arrayOfArrays = [];

        arrayOfObjects.forEach(obj => {
            const values = Object.values(obj);
            arrayOfArrays.push(values);
        });

        return arrayOfArrays;
    };

    const headers = [
        { label: 'S.No', key: 'sno' },
        { label: 'Program Title', key: 'program_title' },
        { label: 'Course Title', key: 'course_title' },
        { label: 'Video Title', key: 'video_title' },
        { label: 'Embed Code', key: 'embed_code' },
    ];

    const items = [{ label: `${subsDetails && subsDetails.subscription_name}` }];
    const home = { label: 'Home', url: `${WEB_URL}/my-subscription-list1` }

    return (
        <div>
            <AppSidebar />
            <div className="wrapper min-vh-100 bg-light">
                <CHeader className="d-flex">
                    <CContainer>
                        <CHeaderToggler
                            className="ps-1"
                            onClick={() =>
                                dispatch({ type: "set", sidebarShow: !sidebarShow })
                            }
                        >
                            <CIcon icon={cilMenu} size="lg" />
                        </CHeaderToggler>
                        <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                            <h4 className="ms-5">
                                {subsDetails ? subsDetails.subscription_name : "Subs Content"}
                            </h4>
                        </CHeaderNav>
                        <CHeaderNav>
                        </CHeaderNav>
                    </CContainer>
                </CHeader>

                {isLoading ? <div style={{
                    marginTop: "10vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CSpinner />
                </div> :
                    <div className="mt-5 mx-5">
                        <div className="d-flex justify-content-between">
                            <BreadCrumb model={items} home={home} />
                            <div style={{ marginRight: "2vw" }}>
                                <CDropdown variant="input-group">
                                    <CDropdownToggle style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        border: "none",
                                        cursor: 'pointer'
                                    }}>Download</CDropdownToggle>
                                    <CDropdownMenu className="cursor-pointer">
                                        <CDropdownItem
                                            onClick={handleXlsxDownload}><BsFiletypeXls />  xlsx format</CDropdownItem>
                                        <CDropdownItem
                                            onClick={handleCsvDownload}><BsFiletypeCsv />  csv format</CDropdownItem>
                                    </CDropdownMenu>
                                </CDropdown>
                            </div>
                        </div>

                        {programTreeTableData && programTreeTableData.length > 0 && (<div className="mt-5">
                            <TreeTable value={programTreeTableData} header="Programs"
                                className="table-header">
                                <Column field="name" header="Name" expander></Column>
                                <Column field="description" header="Description"></Column>
                                <Column field="customActions" body={(rowData) => (
                                    <div>{rowData.hasFeature && (
                                        <span style={{
                                            color: isCopied[rowData.id] ? 'green' : 'blue',
                                            cursor: 'pointer',
                                        }} onClick={() => handleCopyClick(rowData.id, rowData.data.name)}
                                        >{isCopied[rowData.id] ? 'Copied!' : <><MdContentCopy className="fs-4" /> Copy Embed Code</>}
                                        </span>)}</div>
                                )}
                                ></Column>
                            </TreeTable>
                        </div>)}

                        {courseTreeTableData && courseTreeTableData.length > 0 && (<div className="mt-5">
                            <TreeTable value={courseTreeTableData} header="Courses">
                                <Column field="name" header="Name" expander></Column>
                                <Column field="description" header="Description"></Column>
                                <Column field="customActions" body={(rowData) => (
                                    <div>{rowData.hasFeature && (
                                        <span style={{
                                            color: isCopied[rowData.id] ? 'green' : 'blue',
                                            cursor: 'pointer',
                                        }} onClick={() => handleCopyClick(rowData.id, rowData.data.name)}
                                        >{isCopied[rowData.id] ? 'Copied!' : <><MdContentCopy className="fs-4" /> Copy Embed Code</>}
                                        </span>)}</div>
                                )}
                                ></Column>
                            </TreeTable>
                        </div>)}

                        {videoTreeTableData && videoTreeTableData.length > 0 && (<div className="mt-5">
                            <TreeTable value={videoTreeTableData} header="Videos">
                                <Column field="name" header="Name" expander></Column>
                                <Column field="description" header="Description"></Column>
                                <Column
                                    field="actions"
                                    header="Actions"
                                    body={(rowData) => (
                                        <span
                                            style={{
                                                color: isCopied[rowData.id] ? 'green' : 'blue',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleCopyClick(rowData.id, rowData.data.name)}
                                        >
                                            {isCopied[rowData.id] ? 'Copied!' : <><MdContentCopy className="fs-4" /> Copy Embed Code</>}
                                        </span>
                                    )}
                                ></Column>
                            </TreeTable>
                        </div>)}

                    </div>
                }
            </div>
        </div>
    )
};

export default SubsContent