import react, { useState, useEffect } from "react";
import {
    CForm,
    CCol,
    CFormInput,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CContainer,
    CFormTextarea,
    CSpinner,
    CButton,
    CTable, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell, CTableBody, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import axios from "axios";
import { API_BASE_URL, WEB_URL } from "../../../const/const";
import { useNavigate, useLocation } from "react-router-dom";
import AppSidebar from "../../../components/AppSidebar";
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import EditUniSubs from "./EditUniSubs";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import SubscriptionConfirmModal from "../../confirmation/SubscriptionConfirmModal";
import { useSelector, useDispatch } from "react-redux";
import { BreadCrumb } from "primereact/breadcrumb";
import { IFRAME_URL } from "../../../const/iframeConst";
import { BsFiletypeCsv, BsFiletypeXls } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";
import copy from 'clipboard-copy';
import './UniSubs.css'
import { FaRegCirclePlay } from "react-icons/fa6";
import VideoPlayer from "../video/VideoPlayer";

const UniSubsDetails = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const user = localStorage.getItem("user");
    console.log("auth user", user)
    const token = JSON.parse(user).token;

    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();

    const [subscriptionDetails, setSubscriptionDetails] = useState();

    const [flag, setFlag] = useState(false);

    const [uniSubsDetails, setUniSubsDetails] = useState([]);

    const [programTreeTableData, setProgramTreeTableData] = useState([]);
    const [courseTreeTableData, setCourseTreeTableData] = useState([]);
    const [videoTreeTableData, setVideoTreeTableData] = useState([]);

    const [subscriptionProgram, setSubscriptionProgram] = useState([]);
    const [subscriptionCourse, setSubscriptionCourse] = useState([]);
    const [subscriptionVideo, setSubscriptionVideo] = useState([]);

    const [programCourse, setProgramCourse] = useState([]);
    const [programVideo, setProgramVideo] = useState([]);

    const [courseVideos, setCourseVideos] = useState([]);

    const [checkedVideos, setCheckedVideos] = useState({});

    const [subsVideoDetails, setSubsVideoDetails] = useState([]);

    const [excelData, setExcelData] = useState([]);

    useEffect(() => {
        const [navItem] = document.querySelectorAll("[href='/university-subscription-list']");
        navItem.classList.add("active");

        const navGroup = document.querySelector(".nav-group");
        navGroup.classList.add("show")

        const getUniSubs = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-uni-subs-detail/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("UniSubs list", response);
                setUniSubsDetails(response.data.data);
                setIsLoading(false);
                getSubsVideo(response.data.data.university_id, response.data.data.subscription_plan_id);
                getSubscriptionDetails(response.data.data.subscription_plan_id);
            } catch (error) {
                console.error("Error fetching UniSubs:", error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };

        const getSubsVideo = async (uniId, subsId) => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-subs-video/${uniId}/${subsId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("SubsVideo List", response);
                setSubsVideoDetails(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching UniSubs:", error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };

        const getPrograms = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-program`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Program-content", response);
                setProgramCourse(response.data.courseResults);
                setProgramVideo(response.data.videoResults);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching program details:", error);
                setIsLoading(false);
            }
        }

        const getCourseVideos = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-course-videos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("course-video details", response);
                setCourseVideos(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching course-videos:", error);
            }
        };

        const getSubscriptionDetails = async (subsId) => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-subs-content/${subsId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Subscriptions-details", response);
                setSubscriptionDetails(response.data.data);
                setSubscriptionProgram(response.data.programResults);
                setSubscriptionCourse(response.data.courseResults);
                setSubscriptionVideo(response.data.videoResults)
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching Subscription-details:", error);
            }
        };

        if (user) {
            getUniSubs();
            getPrograms();
            getCourseVideos();
        }
    }, []);

    useEffect(() => {
        if (subscriptionProgram && subscriptionCourse && subscriptionVideo && programCourse &&
            programVideo && courseVideos && subsVideoDetails) {
            setProgramTreeTableData(createProgramTreeTableData(
                subscriptionProgram, programCourse, courseVideos, programVideo
            ));
            setCourseTreeTableData(
                createCourseTreeTableData(subscriptionCourse, courseVideos));

            setVideoTreeTableData(createVideoTreeTableData(subscriptionVideo));

            const initialCheckedState = subsVideoDetails.reduce((acc, video) => {
                acc[video.video_id] = video.is_subscribed === 1 ? true : false;
                return acc;
            }, {});

            console.log("initial-state", initialCheckedState)
            setCheckedVideos(initialCheckedState);

            setExcelData(prepareFileData(subscriptionProgram, subscriptionCourse, subscriptionVideo,
                programCourse, programVideo, courseVideos));
        }
    }, [subscriptionProgram,
        subscriptionCourse,
        subscriptionVideo,
        programCourse,
        programVideo,
        courseVideos,
        subsVideoDetails
    ]);

    const [showEditUniSubsDetailPageModal, setShowEditUniSubsDetailPageModal] = useState(false);
    const [showUniSubsDeleteConfirmDetailPageModal, setShowUniSubsDeleteConfirmDetailPageModal] = useState(false);
    const [selectedUniSubsDetailPage, setSelectedUniSubsDetailPage] = useState(null);

    const handleEditUniSubsFlagDetailPage = (editUniSubsFlag) => {
        console.log("edit state", editUniSubsFlag)
        setFlag(editUniSubsFlag);
    }

    const updateUniSubsDetailPage = async (e, uni_subs) => {
        setShowEditUniSubsDetailPageModal(true);
        setSelectedUniSubsDetailPage(uni_subs);
    }

    const handleUniSubsDeleteConfirmationDetailPage = (e, uni_subs) => {
        setShowUniSubsDeleteConfirmDetailPageModal(true);
        setSelectedUniSubsDetailPage(uni_subs);
    }

    const deleteUniSubsDetailPage = async (e, uni_subs) => {
        try {
            e.preventDefault();
            setShowUniSubsDeleteConfirmDetailPageModal(false);
            const userAuth = localStorage.getItem("user");
            const token = JSON.parse(userAuth).token;
            const uniSubsId = uni_subs.university_subscription_mapping_id;
            const universityId = uni_subs.university_id;
            const subscriptionPlanId = uni_subs.subscription_plan_id;

            const response = await axios.delete(`${API_BASE_URL}/delete-uni-subs/${uniSubsId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    universityId: universityId,
                    subscriptionPlanId: subscriptionPlanId
                }
            });
            console.log("success", response.data.message);
            setFlag(true);
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    }

    const createProgramTreeTableData = (programs, programCourses, programCourseVideos, programVideos) => {
        return programs.map((program, index) => {
            const filteredProgramCourses = programCourses && programCourses.filter(course =>
                course.program_id === program.content_id);
            const filteredProgramVideos = programVideos && programVideos.filter(video =>
                video.program_id === program.content_id);

            const programNode = {
                key: String(index),
                id: program.content_id,
                hasFeature1: true,
                hasFeature2: false,
                type: "program",
                data: {
                    name: program.name.length > 35 ? `${program.name.slice(0, 35)}...` : program.name,
                },
                children: [
                    ...(filteredProgramCourses && filteredProgramCourses.length > 0
                        ? filteredProgramCourses.map((course, courseIndex) => {
                            const courseChildren = [];

                            const filteredProgramCourseVideos = programCourseVideos && programCourseVideos.filter(video =>
                                video.course_id === course.content_id);

                            courseChildren.push(
                                ...(filteredProgramCourseVideos && filteredProgramCourseVideos.length > 0
                                    ? filteredProgramCourseVideos.map((video, videoIndex) => ({
                                        key: `video-${videoIndex}`,
                                        id: video.video_id,
                                        hasFeature1: true,
                                        hasFeature2: true,
                                        type: "video",
                                        data: {
                                            name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title,
                                        },
                                        children: [],
                                    }))
                                    : []
                                )
                            );

                            return {
                                key: `course-${courseIndex}`,
                                id: course.content_id,
                                hasFeature1: true,
                                hasFeature2: false,
                                type: "course",
                                programId: program.content_id,
                                data: {
                                    name: course.name.length > 35 ? `${course.name.slice(0, 35)}...` : course.name
                                },
                                children: courseChildren,
                            };
                        })
                        : []
                    ),
                    ...(filteredProgramVideos && filteredProgramVideos.length > 0
                        ? filteredProgramVideos.map((video, videoIndex) => ({
                            key: `video-${videoIndex}`,
                            id: video.content_id,
                            hasFeature1: true,
                            hasFeature2: true,
                            type: "video",
                            data: {
                                name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title
                            },
                            children: [],
                        }))
                        : []
                    ),
                ]
            };
            return programNode;
        });
    };

    const createCourseTreeTableData = (courses, courseVideos) => {
        return courses.map((course, index) => {
            const filteredCourseVideos = courseVideos && courseVideos.filter(video =>
                video.course_id === course.content_id);
            const courseNode = {
                key: String(index),
                id: course.content_id,
                hasFeature1: true,
                hasFeature2: false,
                type: "course",
                data: {
                    name: course.name.length > 35 ? `${course.name.slice(0, 35)}...` : course.name,
                },
                children: [
                    ...(filteredCourseVideos && filteredCourseVideos.length > 0
                        ? filteredCourseVideos.map((video, videoIndex) => ({
                            key: `video-${videoIndex}`,
                            id: video.video_id,
                            hasFeature1: true,
                            hasFeature2: true,
                            type: "video",
                            data: {
                                name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title,
                            },
                            children: [],
                        }))
                        : []
                    ),
                ]
            };
            return courseNode;
        });
    };

    const createVideoTreeTableData = (videos) => {
        return videos.map((video, index) => {
            const videoNode = {
                key: String(index),
                id: video.content_id,
                type: "video",
                data: {
                    name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title,
                    actions: createActionsColumn(video, "video"),
                },
                playVideo: createPlayVideoColumn(video),
                copyEmbedCode: createCopyActionsColumn(video.content_id,video.video_title),
                children: []
            };
            return videoNode;
        });
    };

    const [isCopied, setIsCopied] = useState({});

    const handleCopyClick = (video_id, video_title) => {

        const embedCode = `<iframe width="560" height="400" src="${IFRAME_URL}/player/${uniSubsDetails.university_id}/${video_id}" title="${video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`;

        copy(embedCode);
        setIsCopied((prevState) => ({ ...prevState, [video_id]: true }));

        setTimeout(() => {
            setIsCopied((prevState) => ({ ...prevState, [video_id]: false }));
        }, 5000);
    };

    const createCopyActionsColumn = (video_id, video_title) => {
        return [
            {
                label: 'Copy Embed Code',
                icon: 'pi pi-copy',
                command: () => handleCopyClick(video_id, video_title)
            }
        ];
    };

    const [showPlayVideo, setShowPlayVideo] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const playVideo = (video) => {
        setShowPlayVideo(true);
        setSelectedVideo(video);
    }

    const createPlayVideoColumn = (video) => {
        return [
            {
                label: 'Play Video',
                command: () => playVideo(video)
            }
        ];
    };

    const handleXlsxDownload = async () => {
        try {
            const fileName = `${uniSubsDetails.university_name}'s ${uniSubsDetails.subscription_name}`;
            const data = fileData(excelData);
            const response = await axios.post(`${API_BASE_URL}/xlsx-download`,
                { data, fileName, headers }, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            console.log("xlsx-response", response)

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}.xlsx`;

            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error downloading xlsx-file:', error);
        }
    }

    const handleCsvDownload = async () => {
        try {
            const fileName = `${uniSubsDetails.university_name}'s ${uniSubsDetails.subscription_name}`;
            const response = await axios.post(`${API_BASE_URL}/csv-download`,
                { excelData, fileName, headers }, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            );

            console.log("csv-response", response)

            const blob = new Blob([response.data], { type: 'text/csv' });

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error downloading csv-file:', error);
        }
    }

    const prepareFileData = (subscriptionProgram, subscriptionCourse, subscriptionVideo,
        programCourse, programVideo, courseVideos) => {

        const programData = subscriptionProgram.map(program => ({
            program_title: program.name,
            program_id: program.content_id
        }));

        const courseData = subscriptionCourse.map(course => ({
            course_title: course.name,
            course_id: course.content_id
        }));

        const videoData = subscriptionVideo.map(video => ({
            video_title: video.video_title,
            video_id: video.content_id
        }));

        const excelData = [];

        let index = 1;

        videoData.forEach(video => {
            excelData.push({
                sno: index++,
                program_title: '',
                course_title: '',
                video_title: video.video_title,
                embed_code: `<iframe width="560" height="400" src="${IFRAME_URL}/player/${uniSubsDetails.university_id}/${video.video_id}" title="${video.video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`
            });
        });

        courseData.forEach(course => {
            const matchingVideos = courseVideos.filter(video => video.course_id === course.course_id);
            matchingVideos.forEach(video => {
                excelData.push({
                    sno: index++,
                    program_title: '',
                    course_title: course.course_title,
                    video_title: video.video_title,
                    embed_code: `<iframe width="560" height="400" src="${IFRAME_URL}/player/${uniSubsDetails.university_id}/${video.video_id}" title="${video.video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`
                });
            });
        });

        programData.forEach(program => {
            const matchingCourses = programCourse.filter(course => course.program_id === program.program_id);
            matchingCourses.forEach(course => {
                const matchingVideos = courseVideos.filter(video => video.course_id === course.content_id);
                matchingVideos.forEach(video => {
                    excelData.push({
                        sno: index++,
                        program_title: program.program_title,
                        course_title: course.name,
                        video_title: video.video_title,
                        embed_code: `<iframe width="560" height="400" src="${IFRAME_URL}/player/${uniSubsDetails.university_id}/${video.video_id}" title="${video.video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`
                    });
                });
            });

            const matchingVideos = programVideo.filter(video => video.program_id === program.program_id);
            matchingVideos.forEach(video => {
                excelData.push({
                    sno: index++,
                    program_title: program.program_title,
                    course_title: '',
                    video_title: video.video_title,
                    embed_code: `<iframe width="560" height="400" src="${IFRAME_URL}/player/${uniSubsDetails.university_id}/${video.content_id}" title="${video.video_title}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`
                });
            });
        });

        return excelData;
    };

    const fileData = (arrayOfObjects) => {
        if (!arrayOfObjects || !arrayOfObjects.length) {
            return [];
        }

        const arrayOfArrays = [];

        arrayOfObjects.forEach(obj => {
            const values = Object.values(obj);
            arrayOfArrays.push(values);
        });

        return arrayOfArrays;
    };

    const headers = [
        { label: 'S.No', key: 'sno' },
        { label: 'Program Title', key: 'program_title' },
        { label: 'Course Title', key: 'course_title' },
        { label: 'Video Title', key: 'video_title' },
        { label: 'Embed Code', key: 'embed_code' },
    ];

    const [showSubscriptionConfirmModal, setShowSubscriptionConfirmModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemCheckedState, setSelectedItemCheckedState] = useState(null);

    const handleSubscriptionConfirmModal = (item, checkedState) => {
        setShowSubscriptionConfirmModal(true);
        setSelectedItem(item);
        setSelectedItemCheckedState(checkedState);
    }

    const checkProgramCheckedStatus = (programId) => {
        const [program] = programTreeTableData.filter((program) => {
            return program.id == programId;
        })
        const programContent = program.children;
        let isProgramSubscribed = false;
        programContent.forEach((content) => {
            if (content.type === 'course') {
                content.children.forEach((course_video) => {
                    if (checkedVideos[course_video.id]) {
                        isProgramSubscribed = true;
                        return;
                    }
                })
            } else {
                if (checkedVideos[content.id]) {
                    isProgramSubscribed = true;
                    return;
                }
            }
        })
        return isProgramSubscribed;
    }

    const checkProgramCourseCheckedStatus = (programId, courseId) => {
        const [program] = programTreeTableData.filter((program) => {
            return program.id == programId;
        })

        const [course] = program.children.filter((item) => {
            return item.id == courseId && item.type === "course";
        })

        let isCourseSubscribed = false;
        course.children.forEach((video) => {
            if (checkedVideos[video.id]) {
                isCourseSubscribed = true;
                return;
            }
        })
        return isCourseSubscribed;
    }

    const checkCourseCheckedStatus = (courseId) => {
        const [course] = courseTreeTableData.filter((course) => {
            return course.id == courseId;
        })
        let isCourseSubscribed = false;
        course.children.forEach((video) => {
            if (checkedVideos[video.id]) {
                isCourseSubscribed = true;
                return;
            }
        })
        return isCourseSubscribed;
    }

    const handleCheckboxChange = async (item, checkedState) => {
        try {
            const itemId = item.id;
            const itemType = item.type;
            console.log("item-id", itemId, " AND item-type", itemType, "AND checked-state", checkedState);

            setCheckedVideos(prevState => ({
                ...prevState,
                [itemId]: checkedState
            }));

            if (itemType === "program") {
                const [program] = programTreeTableData.filter((program) => {
                    return program.id == itemId;
                })
                const programContent = program.children;
                programContent.forEach((content) => {
                    if (content.type === 'course') {
                        content.children && content.children.length > 0 ?
                            content.children.forEach((course_video) => {
                                setCheckedVideos(prevState => ({
                                    ...prevState,
                                    [course_video.id]: checkedState
                                }));
                            }) : null;
                    } else {
                        setCheckedVideos(prevState => ({
                            ...prevState,
                            [content.id]: checkedState
                        }));
                    }
                })
            }

            if (itemType === 'course') {
                if (item.programId) {
                    const [program] = programTreeTableData.filter((program) => {
                        return program.id == item.programId;
                    })

                    const [course] = program.children.filter((item) => {
                        return item.id == itemId && itemType === "course";
                    })

                    course.children.forEach((video) => {
                        setCheckedVideos(prevState => ({
                            ...prevState,
                            [video.id]: checkedState
                        }));
                    })
                } else {
                    const [course] = courseTreeTableData.filter((course) => {
                        return course.id == itemId;
                    })
                    const courseVideos = course.children;
                    courseVideos.forEach((video) => {
                        setCheckedVideos(prevState => ({
                            ...prevState,
                            [video.id]: checkedState
                        }));
                    })
                }
            }

            const uniId = uniSubsDetails.university_id;
            const subsId = uniSubsDetails.subscription_plan_id;

            const response = await axios.put(`${API_BASE_URL}/update-subs-video/${uniId}/${subsId}`, {
                item_id: itemId,
                item_type: itemType,
                is_subscribed: checkedState ? "1" : "0"
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log("successfully updated subs-video", response);
            setShowSubscriptionConfirmModal(false);
        } catch (err) {
            console.log("error in editing subs-video", err);
        }
    };

    const createActionsColumn = (video) => {
        return (
            <Checkbox
                style={{ marginLeft: "1vw" }}
                onChange={e => handleSubscriptionConfirmModal(video, e.checked)}
                checked={checkedVideos[video.video_id] || false}
            />
        );
    }

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    const deletebtn = {
        '--cui-btn-bg': "red",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "red",
        '--cui-btn-active-bg': "red",
        "margin-left": "1vw"
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const items = [{ label: `University-Access` }];
    const home = { label: 'Home', url: `${WEB_URL}/university-subscription-list` }

    return (
        <>
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <CHeader className="d-flex">
                        <CContainer>
                            <CHeaderToggler
                                className="ps-1"
                                onClick={() =>
                                    dispatch({ type: "set", sidebarShow: !sidebarShow })
                                }
                            >
                                <CIcon icon={cilMenu} size="lg" />
                            </CHeaderToggler>
                            <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                                <h4 className="ms-5">University Access</h4>
                            </CHeaderNav>
                        </CContainer>
                    </CHeader>
                    {isLoading ?
                        <div style={{
                            marginTop: "10vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CSpinner />
                        </div> : <div className="mt-5 mx-5">
                            <div className="d-flex justify-content-between">
                                <BreadCrumb model={items} home={home} />
                                <div style={{ marginRight: "2vw" }}>
                                    <CDropdown variant="input-group">
                                        <CDropdownToggle style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            border: "none",
                                            cursor: 'pointer'
                                        }}>Download</CDropdownToggle>
                                        <CDropdownMenu className="cursor-pointer">
                                            <CDropdownItem
                                                onClick={handleXlsxDownload}><BsFiletypeXls />  xlsx format</CDropdownItem>
                                            <CDropdownItem
                                                onClick={handleCsvDownload}><BsFiletypeCsv />  csv format</CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                            </div>

                            <CForm className="row g-3">
                                <CCol md={12}>
                                    <table>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="uni-subs-detail-label">University Name:</td>
                                                <td className="uni-subs-detail-value">{uniSubsDetails && uniSubsDetails.university_name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="uni-subs-detail-label">Subscription Name:</td>
                                                <td className="uni-subs-detail-value">{uniSubsDetails && uniSubsDetails.subscription_name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CCol>
                            </CForm>

                            {programTreeTableData && programTreeTableData.length > 0 && (<div className="mt-5 ">
                                <TreeTable value={programTreeTableData} header="Programs">
                                    <Column field="customActions"
                                        style={{ width: "10px" }}
                                        body={(rowData) => {
                                            return (<div>{rowData.hasFeature1 && (
                                                <Checkbox style={{ marginLeft: "1vw" }}
                                                    onChange={(e) => handleSubscriptionConfirmModal(rowData, e.checked)}
                                                    checked={rowData.type === "program"
                                                        ? checkProgramCheckedStatus(rowData.id)
                                                        : rowData.type === "course"
                                                            ? checkProgramCourseCheckedStatus(rowData.programId, rowData.id)
                                                            : checkedVideos[rowData.id]
                                                    }
                                                />)}
                                            </div>);
                                        }} />
                                    <Column field="name" header="Name" style={{ width: "40%" }} expander></Column>
                                    <Column field="videoActions" body={(rowData) => (
                                        <div>{rowData.hasFeature2 && (
                                            <div><span style={{
                                                color: "green",
                                                cursor: 'pointer'
                                            }} onClick={() => playVideo(rowData)}
                                            ><FaRegCirclePlay className="fs-4" /> Play Video</span>
                                                <span style={{
                                                    color: isCopied[rowData.id] ? 'green' : 'blue',
                                                    cursor: 'pointer',
                                                    marginLeft:"10vw"
                                                }} onClick={() => handleCopyClick(rowData.id, rowData.data.name)}
                                                >{isCopied[rowData.id] ? 'Copied!' : <><MdContentCopy className="fs-4" /> Copy Embed Code</>}
                                                </span>
                                            </div>
                                        )}</div>
                                    )}></Column>
                                </TreeTable>
                            </div>)}

                            {courseTreeTableData && courseTreeTableData.length > 0 && (<div className="mt-5 ">
                                <TreeTable value={courseTreeTableData} header="Courses">
                                    <Column field="customActions"
                                        style={{ width: "10px" }}
                                        body={(rowData) => (
                                            <div>{rowData.hasFeature1 && (
                                                <Checkbox
                                                    style={{ marginLeft: "1vw" }}
                                                    onChange={e => handleSubscriptionConfirmModal(rowData, e.checked)}
                                                    checked={rowData.type === "course" ? checkCourseCheckedStatus(rowData.id) : checkedVideos[rowData.id]}
                                                />
                                            )}</div>
                                        )}
                                    ></Column>
                                    <Column field="name" header="Name" style={{ width: "40%" }} expander></Column>
                                    <Column field="videoActions" body={(rowData) => (
                                        <div>{rowData.hasFeature2 && (
                                            <div><span style={{
                                                color: "green",
                                                cursor: 'pointer'
                                            }} onClick={() => playVideo(rowData)}
                                            ><FaRegCirclePlay className="fs-4" /> Play Video</span>
                                                <span style={{
                                                    color: isCopied[rowData.id] ? 'green' : 'blue',
                                                    cursor: 'pointer',
                                                    marginLeft:"10vw"
                                                }} onClick={() => handleCopyClick(rowData.id, rowData.data.name)}
                                                >{isCopied[rowData.id] ? 'Copied!' : <><MdContentCopy className="fs-4" /> Copy Embed Code</>}
                                                </span>
                                            </div>
                                        )}</div>
                                    )}></Column>
                                </TreeTable>
                            </div>)}

                            {videoTreeTableData && videoTreeTableData.length > 0 && (<div className="mt-5">
                                <TreeTable value={videoTreeTableData} header="Videos">
                                    <Column
                                        field="customActions"
                                        style={{ width: "10px" }}
                                        body={(rowData) => (
                                            <Checkbox
                                                style={{ marginLeft: "1vw" }}
                                                onChange={e => handleSubscriptionConfirmModal(rowData, e.checked)}
                                                checked={checkedVideos[rowData.id] || false}
                                            />
                                        )}
                                    />
                                    <Column field="name" header="Name" style={{ width: "40%" }} expander></Column>
                                    <Column field="videoActions" header="Actions"
                                     body={(rowData) => (
                                        <div><span style={{
                                            color: "green",
                                            cursor: 'pointer'
                                        }} onClick={() => playVideo(rowData)}
                                        ><FaRegCirclePlay className="fs-4" /> Play Video</span>
                                            <span style={{
                                                color: isCopied[rowData.id] ? 'green' : 'blue',
                                                cursor: 'pointer',
                                                marginLeft:"10vw"
                                            }} onClick={() => handleCopyClick(rowData.id, rowData.data.name)}
                                            >{isCopied[rowData.id] ? 'Copied!' : <><MdContentCopy className="fs-4" /> Copy Embed Code</>}
                                            </span>
                                        </div>
                                    )}
                                ></Column>
                                </TreeTable>
                            </div>)}

                            <div className="d-flex justify-content-end my-3">
                                <CButton
                                    style={btn}
                                    onClick={(e) => updateUniSubsDetailPage(e, uniSubsDetails)}
                                >
                                    Edit Details
                                </CButton>
                                <CButton
                                    style={deletebtn}
                                    onClick={(e) => handleUniSubsDeleteConfirmationDetailPage(e, uniSubsDetails)}
                                >
                                    Delete
                                </CButton>
                            </div>
                        </div>}
                </div>
                {showPlayVideo && (<VideoPlayer
                    show={showPlayVideo}
                    onHide={() => setShowPlayVideo(false)}
                    video={selectedVideo}
                />)}
                <EditUniSubs
                    show={showEditUniSubsDetailPageModal}
                    onHide={() => setShowEditUniSubsDetailPageModal(false)}
                    university_subscription={selectedUniSubsDetailPage}
                    editUniSubsFlag={handleEditUniSubsFlagDetailPage}
                />
                <ConfirmationModal
                    show={showUniSubsDeleteConfirmDetailPageModal}
                    handleClose={() => { setShowUniSubsDeleteConfirmDetailPageModal(false) }}
                    handleConfirm={(e) => deleteUniSubsDetailPage(e, selectedUniSubsDetailPage)}
                />
                <SubscriptionConfirmModal
                    show={showSubscriptionConfirmModal}
                    handleClose={() => { setShowSubscriptionConfirmModal(false) }}
                    handleConfirm={() => handleCheckboxChange(selectedItem, selectedItemCheckedState)}
                    text={selectedItemCheckedState ? "Are you sure you want to subscribe to this content?"
                        : "Are you sure you want to unsubscribe this content?"}
                />
            </div>

        </>
    );
};

export default UniSubsDetails;
