import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
    CButton,
    CButtonGroup,
    CContainer,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow, CPagination, CPaginationItem, CSpinner, CCol, CForm, CFormInput
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";

import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL } from "../../../const/const";
import axios from "axios";
import AddSubscription from "./AddSubscription";
import EditSubscription from "./EditSubscription";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import '../../styling/Pagination.css'
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import MouseOverPopup from '../../mouseOver/MouseOverPopup';
import { CiViewList } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { GoSearch } from "react-icons/go";

const SubscriptionList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSubsDeleteConfirmModal, setShowSubsDeleteConfirmModal] = useState(false);

    const [selectedSubs, setSelectedSubs] = useState(null);
    const [selectedSubsProgram, setSelectedSubsProgram] = useState(null);
    const [selectedSubsCourse, setSelectedSubsCourse] = useState(null);
    const [selectedSubsVideo, setSelectedSubsVideo] = useState(null);

    const [subscription, setSubscription] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [courses, setCourses] = useState([]);
    const [videos, setVideos] = useState([]);

    const [flag, setFlag] = useState(false);
    const [searchQuery, setSearchQuery] = useState();

    const user = localStorage.getItem("user");

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("auth user", user)
        const token = JSON.parse(user).token;

        const getSubscription = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-subscription`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("subscription list", response);
                setSubscription(response.data.data);
                setPrograms(response.data.programResults);
                setCourses(response.data.courseResults);
                setVideos(response.data.videoResults);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching subscription:", error);
            }
        };
        if (user) {
            getSubscription();
        }
        setFlag(false);
    }, [flag]);

    console.log("videos list", videos);

    const statusMapping = {
        1: "Active",
        2: "Inactive",
        3: "Deleted"
    }

    const handleAddSubsFlag = (addSubsFlag) => {
        console.log("add state", addSubsFlag)
        setFlag(addSubsFlag);
    }

    const handleEditSubsFlag = (editSubsFlag) => {
        console.log("edit state", editSubsFlag)
        setFlag(editSubsFlag);
    }

    const videosToBeUpdated = [];
    const coursesTobeUpdated = [];
    const programsToBeUpdated = [];

    const updateSubscription = async (e, subscription) => {
        programs.map((program) => (
            program.content_id !== null &&
                subscription.subscription_plan_id === program.subscription_plan_id ?
                programsToBeUpdated.push(program) : <></>
        ))

        courses.map((course) => (
            course.content_id !== null &&
                subscription.subscription_plan_id === course.subscription_plan_id ?
                coursesTobeUpdated.push(course) : <></>
        ))

        // modules.map((module) => (
        //     module.content_id !== null &&
        //         subscription.subscription_plan_id === module.subscription_plan_id ?
        //         modulesTobeUpdated.push(module) : <></>
        // ))

        videos.map((video) => (
            video.content_id !== null &&
                subscription.subscription_plan_id === video.subscription_plan_id ?
                videosToBeUpdated.push(video) : <></>
        ))

        console.log('subs videos', videosToBeUpdated);
        console.log('subs modules', videosToBeUpdated);
        // console.log('subs courses', coursesTobeUpdated);
        console.log('subs programs', programsToBeUpdated);

        setShowEditModal(true);
        setSelectedSubs(subscription);
        setSelectedSubsProgram(programsToBeUpdated);
        setSelectedSubsVideo(videosToBeUpdated);
        setSelectedSubsCourse(coursesTobeUpdated);
        // setSelectedSubsModule(modulesTobeUpdated);
    }

    const handleSubsDeleteConfirmation = (e, subscription) => {
        setShowSubsDeleteConfirmModal(true);
        setSelectedSubs(subscription);
    }

    const deleteSubscription = async (e, subscription) => {
        try {
            e.preventDefault();
            setShowSubsDeleteConfirmModal(false);
            const token = JSON.parse(user).token;
            const subscription_plan_id = subscription.subscription_plan_id;
            console.log("subscription id to be deleted", subscription_plan_id);
            const response = await axios.delete(`${API_BASE_URL}/delete-subscription/${subscription_plan_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log("success", response.data.message);
            setFlag(true);
        } catch (error) {
            console.log(error);
        }
    };

    const [stateSearch, setStateSearch] = useState();
    const [searchError, setSearchError] = useState();
    const [searchData, setSearchData] = useState([]);

    const handleInputChange = (e) => {
        setStateSearch(true);
        setSearchQuery(e.target.value);
        if (e.target.value.trim() === '') {
            setSearchData(displaySubscription);
            setSearchError("");
        } else {
            filterData(e.target.value);
        }
    };

    const filterData = (query) => {
        const statusMap = {
            "active": 1,
            "inactive": 2,
            "deleted": 3
        };

        const queryKey = query.toLowerCase();
        const partialMatches = Object.keys(statusMap).filter(key =>
            key.toLowerCase().includes(queryKey)
        );

        const partialMatchValues = partialMatches.map(key => statusMap[key]);

        const filteredData = displaySubscription.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase()) ||
            item.description.toLowerCase().includes(query.toLowerCase()) ||
            partialMatchValues.includes(item.status)
        );

        if (filteredData.length === 0) {
            setSearchError("No data found");
        } else {
            setSearchData(filteredData);
            setSearchError("");
        }
    };

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 25;
    const pageVisited = pageNumber * itemsPerPage;

    const displaySubscription = subscription.slice(pageVisited, pageVisited + itemsPerPage);

    const pageCount = Math.ceil(subscription.length / itemsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    const truncateText = (text, wordsCount) => {
        const words = text.split(' ');
        const truncatedText = words.slice(0, wordsCount).join(' ');
        return truncatedText + (words.length > wordsCount ? '...' : '');
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a",
        '--cui-btn-padding-y': "5px"
    }

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <CHeader className="d-flex">
                    <CContainer>
                        <CHeaderToggler
                            className="ps-1"
                            onClick={() =>
                                dispatch({ type: "set", sidebarShow: !sidebarShow })
                            }
                        >
                            <CIcon icon={cilMenu} size="lg" />
                        </CHeaderToggler>
                        <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                            <h4 style={{ marginRight: '3vw' }}>Subscription List</h4>
                            <CCol sm={4}>
                                <CForm className="d-flex">
                                    <CFormInput
                                        type="text"
                                        placeholder="Search"
                                        className="me-2 rounded-pill"
                                        style={{ width: "20vw" }}
                                        aria-label="Search"
                                        onChange={handleInputChange}
                                        value={searchQuery}
                                    />
                                    <span style={{
                                        marginLeft: "-3vw",
                                        marginTop: "1vh"
                                    }}><GoSearch /> </span>
                                </CForm>
                            </CCol>
                        </CHeaderNav>

                        <CHeaderNav>
                            <CButtonGroup>
                                <CButton
                                    style={btn} variant="outline"
                                    onClick={() => setShowAddModal(true)}
                                >
                                    Add Subscription
                                </CButton>
                            </CButtonGroup>
                        </CHeaderNav>
                    </CContainer>
                </CHeader>
                <div className="ms-3 mt-5 me-4" >
                    <CTable striped>
                        <CTableHead style={tableHeader}>
                            <CTableRow>
                                <CTableHeaderCell style={{ width: "40%", paddingLeft: "3vw" }}>
                                    Subscription Name
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: "25%" }}>
                                    Decription
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: "20%" }}>
                                    Status
                                </CTableHeaderCell>
                                <CTableHeaderCell>
                                    Actions
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody style={{ padding: "5px" }}>
                            {stateSearch ? (searchError !== "" ?
                                (<CTableRow className="text-center">
                                    <CTableDataCell colSpan={6}>{searchError}</CTableDataCell>
                                </CTableRow>) :
                                searchData && searchData.map((subscription, index) => {
                                    return (
                                        <CTableRow
                                            v-for="item in tableItems"
                                            key={index}>
                                            <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                                <span>{subscription.name}</span>
                                            </CTableDataCell>
                                            <CTableDataCell style={{
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                maxWidth: '15vw'
                                            }}>
                                                <MouseOverPopup description={subscription.description}>
                                                    <span>{truncateText(subscription.description, 4)}</span>
                                                </MouseOverPopup>
                                            </CTableDataCell>
                                            <CTableDataCell style={{ cursor: "pointer" }}>
                                                <span>{statusMapping[subscription.status]}</span>
                                            </CTableDataCell>
                                            <CTableDataCell>
                                                <div className="d-flex mt-2 cursor-pointer">
                                                    <div
                                                        style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                        onClick={(e) => navigate(`/subscription-details?id=${subscription.subscription_plan_id}`)}
                                                    >
                                                        <CiViewList />
                                                    </div>
                                                    <div
                                                        style={{ color: "darkgreen", marginRight: "1vw" }}
                                                        onClick={(e) => updateSubscription(e, subscription)}
                                                    >
                                                        <AiTwotoneEdit />
                                                    </div>
                                                    <span
                                                        style={{ color: "red", marginRight: "1vw" }}
                                                        onClick={(e) => handleSubsDeleteConfirmation(e, subscription)}
                                                    >
                                                        <AiTwotoneDelete />
                                                    </span>
                                                </div>
                                            </CTableDataCell>
                                        </CTableRow>
                                    )
                                })) : isLoading ? <CTableRow>
                                    <CTableDataCell className="text-center" colSpan="6">
                                        <CSpinner />
                                    </CTableDataCell>
                                </CTableRow> : displaySubscription.length === 0 ? <CTableRow>
                                    <CTableDataCell className="text-center" colSpan="5">
                                        <span>No data to show</span>
                                    </CTableDataCell>
                                </CTableRow> :
                                displaySubscription.map((subscription, index) => {
                                    return (
                                        <CTableRow
                                            v-for="item in tableItems"
                                            key={index}>
                                            <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                                <span>{subscription.name}</span>
                                            </CTableDataCell>
                                            <CTableDataCell style={{
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                maxWidth: '15vw'
                                            }}>
                                                <MouseOverPopup description={subscription.description}>
                                                    <span>{truncateText(subscription.description, 4)}</span>
                                                </MouseOverPopup>
                                            </CTableDataCell>
                                            <CTableDataCell style={{ cursor: "pointer" }}>
                                                <span>{statusMapping[subscription.status]}</span>
                                            </CTableDataCell>
                                            <CTableDataCell>
                                                <div className="d-flex mt-2 cursor-pointer">
                                                    <div
                                                        style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                        onClick={(e) => navigate(`/subscription-details?id=${subscription.subscription_plan_id}`)}
                                                    >
                                                        <CiViewList />
                                                    </div>
                                                    <div
                                                        style={{ color: "darkgreen", marginRight: "1vw" }}
                                                        onClick={(e) => updateSubscription(e, subscription)}
                                                    >
                                                        <AiTwotoneEdit />
                                                    </div>
                                                    <span
                                                        style={{ color: "red", marginRight: "1vw" }}
                                                        onClick={(e) => handleSubsDeleteConfirmation(e, subscription)}
                                                    >
                                                        <AiTwotoneDelete />
                                                    </span>
                                                </div>
                                            </CTableDataCell>
                                        </CTableRow>
                                    )
                                })
                            }
                        </CTableBody>
                    </CTable>
                </div>
                {displaySubscription.length > 0 && !searchError &&
                    (<CPagination align="center" aria-label="Page navigation example" className="cursor-pointer">
                        <CPaginationItem
                            aria-label="Previous"
                            onClick={() => setPageNumber(pageNumber - 1)}
                            disabled={pageNumber === 0}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </CPaginationItem>

                        {Array.from({ length: pageCount }, (_, index) => (
                            <CPaginationItem
                                key={index + 1}
                                active={pageNumber === index}
                                onClick={() => setPageNumber(index)}
                            >
                                {index + 1}
                            </CPaginationItem>
                        ))}

                        <CPaginationItem
                            aria-label="Next"
                            onClick={() => setPageNumber(pageNumber + 1)}
                            disabled={pageNumber === pageCount - 1}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </CPaginationItem>
                    </CPagination>)}
            </div>

            <AddSubscription
                show={showAddModal}
                onHide={() => setShowAddModal(false)}
                addSubsFlag={handleAddSubsFlag}
            />
            <EditSubscription
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                subscription={selectedSubs}
                subscriptionPrograms={selectedSubsProgram}
                subscriptionCourses={selectedSubsCourse}
                subscriptionVideos={selectedSubsVideo}
                editSubsFlag={handleEditSubsFlag}
            />
            <ConfirmationModal
                show={showSubsDeleteConfirmModal}
                handleClose={() => { setShowSubsDeleteConfirmModal(false) }}
                handleConfirm={(e) => deleteSubscription(e, selectedSubs)}
            />
        </div>
    );
};

export default SubscriptionList;
