import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
    CButton,
    CButtonGroup,
    CContainer,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow, CPagination, CPaginationItem, CSpinner, CCol, CForm, CFormInput
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";

import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL } from "../../../const/const";
import axios from "axios";
import AddUniversity from "./AddUniversity";
import EditUniversity from "./EditUniversity";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import '../../styling/Pagination.css';
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import MouseOverPopup from '../../mouseOver/MouseOverPopup';
import { GoSearch } from "react-icons/go";

const UniversityList = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const [showAddUniModal, setShowAddUniModal] = useState(false);
    const [showEditUniModal, setShowEditUniModal] = useState(false);
    const [showDeleteConfirmUniModal, setShowDeleteConfirmUniModal] = useState(false);
    const [selectedUni, setSelectedUni] = useState(null);
    const [selectedUniDomains, setSelectedUniDomains] = useState(null);

    const [universities, setUniversities] = useState([]);
    const [flag, setFlag] = useState(false);
    const [searchQuery, setSearchQuery] = useState();
    const [domains, setDomains] = useState([]);

    const user = localStorage.getItem("user");

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("authenticated user", user)
        const token = JSON.parse(user).token;

        const getUniversities = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-university`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("university list", response);
                setUniversities(response.data.data);
                setDomains(response.data.universityDomains);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching university:", error);
            }
        };

        if (user) {
            getUniversities();
            setFlag(false);
        }
    }, [flag]);

    const handleAddUniFlag = (addUniFlag) => {
        console.log("add state", addUniFlag);
        setFlag(addUniFlag);
    }

    const handleEditUniFlag = (editUniFlag) => {
        console.log("edit state", editUniFlag)
        setFlag(editUniFlag);
    }

    const domainsToBeUpdated = []
    const updateUniversity = async (e, university) => {
        domains.map((domain) => (
            university.university_id === domain.university_id ?
                domainsToBeUpdated.push(domain) : <></>
        ))

        console.log("uni domains-->", domainsToBeUpdated);

        setShowEditUniModal(true);
        setSelectedUni(university);
        setSelectedUniDomains(domainsToBeUpdated);
    }

    const handleUniDeleteConfirmation = (e, university) => {
        setShowDeleteConfirmUniModal(true);
        setSelectedUni(university);
    }

    const deleteUniversity = async (e, university) => {
        try {
            e.preventDefault();
            setShowDeleteConfirmUniModal(false);
            const userAuth = localStorage.getItem("user");
            const token = JSON.parse(userAuth).token;
            const university_id = university.university_id;
            console.log("university id to be deleted", university_id);
            const response = await axios.delete(`${API_BASE_URL}/delete-university/${university_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            console.log("success", response.data.message);
            setFlag(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [stateSearch, setStateSearch] = useState();
    const [searchError, setSearchError] = useState("");
    const [searchData, setSearchData] = useState([]);

    console.log(domains)
    const handleInputChange = (e) => {
        setStateSearch(true);
        setSearchQuery(e.target.value);
        if (e.target.value.trim() === '') {
            setSearchData(displayUniversities);
            setSearchError("");
        } else {
            filterData(e.target.value);
        }
    };

    const filterData = (query) => {
        const filteredData = displayUniversities.filter(university => {
            const filteredDomains = domains.filter(domain => domain.university_id === university.university_id);
    
            const domainNames = filteredDomains.map(domain => domain.domain_name.toLowerCase());
    
            return (
                university.name.toLowerCase().includes(query.toLowerCase()) ||
                university.phone_number.toString().toLowerCase().includes(query.toLowerCase()) ||
                domainNames.some(name => name.includes(query.toLowerCase()))
            );
        });
    
        if (filteredData.length === 0) {
            setSearchError("No data found");
        } else {
            setSearchData(filteredData);
            setSearchError("");
        }
    };

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 25;
    const pageVisited = pageNumber * itemsPerPage;

    const displayUniversities = universities.slice(pageVisited, pageVisited + itemsPerPage);

    const pageCount = Math.ceil(universities.length / itemsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    const truncateText = (text, wordsCount) => {
        const words = text.split(' ');
        const truncatedText = words.slice(0, wordsCount).join(' ');
        return truncatedText + (words.length > wordsCount ? '...' : '');
    }

    console.log("domain-list", domains);

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a",
        '--cui-btn-padding-y': "5px"
    }

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <CHeader className="d-flex">
                    <CContainer>
                        <CHeaderToggler
                            className="ps-1"
                            onClick={() =>
                                dispatch({ type: "set", sidebarShow: !sidebarShow })
                            }
                        >
                            <CIcon icon={cilMenu} size="lg" />
                        </CHeaderToggler>
                        <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                            <h4 style={{ marginRight: '3vw' }}>University List</h4>
                            <CCol sm={4}>
                                <CForm className="d-flex">
                                    <CFormInput
                                        type="text"
                                        placeholder="Search"
                                        className="me-2 rounded-pill"
                                        style={{ width: "20vw" }}
                                        aria-label="Search"
                                        onChange={handleInputChange}
                                        value={searchQuery}
                                    />
                                    <span style={{
                                        marginLeft: "-3vw",
                                        marginTop: "1vh"
                                    }}><GoSearch /> </span>
                                </CForm>
                            </CCol>
                        </CHeaderNav>
                        <CHeaderNav>
                            <CButtonGroup>
                                <CButton
                                    style={btn} variant="outline"
                                    onClick={() => setShowAddUniModal(true)}
                                >
                                    Add University
                                </CButton>
                            </CButtonGroup>
                        </CHeaderNav>
                    </CContainer>
                </CHeader>
                <div className="ms-4 mt-5 me-4">
                    <CTable
                        striped >
                        <CTableHead style={tableHeader}>
                            <CTableRow>
                                <CTableHeaderCell style={{ width: '25%', paddingLeft: "3vw" }}>
                                    Name
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: '30%' }}>
                                    Domains
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: '15%' }}>
                                    Address
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: '20%' }}>
                                    Phone Number
                                </CTableHeaderCell>
                                <CTableHeaderCell>
                                    Actions
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody style={{ padding: "5px" }}>
                            {stateSearch ? (searchError !== "" ?
                                (<CTableRow className="text-center">
                                    <CTableDataCell colSpan={6}>{searchError}</CTableDataCell>
                                </CTableRow>) :
                                searchData.length > 0 && searchData.map((university, index) => (
                                    <CTableRow
                                        v-for="item in tableItems"
                                        key={index}>
                                        <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                            <span>
                                                {university.name}
                                            </span>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>{domains && domains
                                                .filter(domain => university.university_id === domain.university_id)
                                                .map((domain, index, array) => (
                                                    <span key={index}>
                                                        {domain.domain_name}
                                                        {index !== array.length - 1 && ', '}
                                                    </span>
                                                ))
                                            }</div>
                                        </CTableDataCell>

                                        <CTableDataCell>
                                            <MouseOverPopup description={`${university.address1} 
                                                ${university.address2}, ${university.city},
                                                ${university.state} ${university.country}`}>
                                                <div>
                                                    <span>
                                                        {truncateText(
                                                            `${university.address1} 
                                                            ${university.address2}, ${university.city},
                                                             ${university.state} ${university.country}`, 20)}
                                                    </span>
                                                </div>
                                            </MouseOverPopup>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <span>{university.phone_number}</span>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div className="d-flex mt-2 cursor-pointer">
                                                <div
                                                    style={{ color: "darkgreen", marginRight: "1vw" }}
                                                    onClick={(e) => updateUniversity(e, university)}
                                                >
                                                    <AiTwotoneEdit />
                                                </div>
                                                <span style={{ color: "red", marginRight: "1vw" }}
                                                    onClick={(e) => handleUniDeleteConfirmation(e, university)}
                                                >
                                                    <AiTwotoneDelete />
                                                </span>
                                            </div>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))
                            ) : isLoading ? <CTableRow>
                                <CTableDataCell className="text-center" colSpan="6">
                                    <CSpinner />
                                </CTableDataCell>
                            </CTableRow> : displayUniversities.length === 0 ? <CTableRow>
                                <CTableDataCell className="text-center" colSpan="6">
                                    <span>No data to show</span>
                                </CTableDataCell>
                            </CTableRow> : displayUniversities.map((university, index) => (
                                <CTableRow
                                    v-for="item in tableItems"
                                    key={index}>
                                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                        <span>
                                            {university.name}
                                        </span>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div>{domains && domains
                                            .filter(domain => university.university_id === domain.university_id)
                                            .map((domain, index, array) => (
                                                <span key={index}>
                                                    {domain.domain_name}
                                                    {index !== array.length - 1 && ', '}
                                                </span>
                                            ))
                                        }</div>
                                    </CTableDataCell>

                                    <CTableDataCell>
                                        <MouseOverPopup description={`${university.address1} 
                                                ${university.address2}, ${university.city},
                                                ${university.state} ${university.country}`}>
                                            <div>
                                                <span>
                                                    {truncateText(
                                                        `${university.address1} 
                                                            ${university.address2}, ${university.city},
                                                             ${university.state} ${university.country}`, 20)}
                                                </span>
                                            </div>
                                        </MouseOverPopup>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <span>{university.phone_number}</span>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div className="d-flex mt-2 cursor-pointer">
                                            <div
                                                style={{ color: "darkgreen", marginRight: "1vw" }}
                                                onClick={(e) => updateUniversity(e, university)}
                                            >
                                                <AiTwotoneEdit />
                                            </div>
                                            <span style={{ color: "red", marginRight: "1vw" }}
                                                onClick={(e) => handleUniDeleteConfirmation(e, university)}
                                            >
                                                <AiTwotoneDelete />
                                            </span>
                                        </div>
                                    </CTableDataCell>
                                </CTableRow>
                            ))
                            }
                        </CTableBody>
                    </CTable>
                </div>
                {displayUniversities.length > 0 && !searchError &&
                    (<CPagination align="center" aria-label="Page navigation example" className="cursor-pointer">
                        <CPaginationItem
                            aria-label="Previous"
                            onClick={() => setPageNumber(pageNumber - 1)}
                            disabled={pageNumber === 0}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </CPaginationItem>

                        {Array.from({ length: pageCount }, (_, index) => (
                            <CPaginationItem
                                key={index + 1}
                                active={pageNumber === index}
                                onClick={() => setPageNumber(index)}
                            >
                                {index + 1}
                            </CPaginationItem>
                        ))}

                        <CPaginationItem
                            aria-label="Next"
                            onClick={() => setPageNumber(pageNumber + 1)}
                            disabled={pageNumber === pageCount - 1}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </CPaginationItem>
                    </CPagination>)}
            </div>
            <AddUniversity
                show={showAddUniModal}
                onHide={() => { setShowAddUniModal(false) }}
                addUniFlag={handleAddUniFlag} />
            <EditUniversity
                show={showEditUniModal}
                onHide={() => setShowEditUniModal(false)}
                university={selectedUni}
                universityDomains={selectedUniDomains}
                editUniFlag={handleEditUniFlag}
            />
            <ConfirmationModal
                show={showDeleteConfirmUniModal}
                handleClose={() => { setShowDeleteConfirmUniModal(false) }}
                handleConfirm={(e) => deleteUniversity(e, selectedUni)}
            />
        </div>
    );
};

export default UniversityList;
