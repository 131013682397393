import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
    CButton,
    CButtonGroup,
    CContainer,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow, CPagination, CPaginationItem, CSpinner, CCol, CForm, CFormInput
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";

import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL } from "../../../const/const";
import axios from "axios";
import AddUniSubs from "./AddUniSubs";
import EditUniSubs from "./EditUniSubs";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import '../../styling/Pagination.css';
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { CiViewList } from "react-icons/ci";
import { GoSearch } from "react-icons/go";

const UniSubsList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const [showAddUniSubsModal, setShowAddUniSubsModal] = useState(false);
    const [showEditUniSubsModal, setShowEditUniSubsModal] = useState(false);
    const [showUniSubsDeleteConfirmModal, setShowUniSubsDeleteConfirmModal] = useState(false);
    const [selectedUniSubs, setSelectedUniSubs] = useState(null);

    const [uniSubs, setUniSubs] = useState([]);
    const [flag, setFlag] = useState(false);
    const [searchQuery, setSearchQuery] = useState();

    const [showUniSubsPopup, setShowUniSubsPopup] = useState(false);
    const [displayUniSubsDetails, setDisplayUniSubsDetails] = useState([]);

    const user = localStorage.getItem("user");

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("authenticated user", user)
        const token = JSON.parse(user).token;
        const getUniSubs = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-uni-subs`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("UniSubs list", response);
                setUniSubs(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching UniSubs:", error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };

        if (user) {
            getUniSubs();
            setFlag(false);
        }
    }, [flag]);

    const handleUniSubsPopup = (uni_subs) => {
        setShowUniSubsPopup(true);
        setDisplayUniSubsDetails(uni_subs);
    }

    const handleAddUniSubsFlag = (addUniSubsFlag) => {
        console.log("add state", addUniSubsFlag);
        setFlag(addUniSubsFlag);
    }

    const handleEditUniSubsFlag = (editUniSubsFlag) => {
        console.log("edit state", editUniSubsFlag)
        setFlag(editUniSubsFlag);
    }

    const updateUniSubs = async (e, uni_subs) => {
        console.log("rtyui", uni_subs)
        setShowEditUniSubsModal(true);
        setSelectedUniSubs(uni_subs);
    }

    const handleUniSubsDeleteConfirmation = (e, uni_subs) => {
        setShowUniSubsDeleteConfirmModal(true);
        setSelectedUniSubs(uni_subs);
    }

    const deleteUniSubs = async (e, uni_subs) => {
        try {
            e.preventDefault();
            setShowUniSubsDeleteConfirmModal(false);
            const userAuth = localStorage.getItem("user");
            const token = JSON.parse(userAuth).token;
            const uniSubsId = uni_subs.university_subscription_mapping_id;
            const universityId = uni_subs.university_id;
            const subscriptionPlanId = uni_subs.subscription_plan_id;

            const response = await axios.delete(`${API_BASE_URL}/delete-uni-subs/${uniSubsId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    universityId: universityId,
                    subscriptionPlanId: subscriptionPlanId
                }
            });
            console.log("success", response.data.message);
            setFlag(true);
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    }

    const [stateSearch, setStateSearch] = useState();
    const [searchError, setSearchError] = useState();
    const [searchData, setSearchData] = useState([]);

    const handleInputChange = (e) => {
        setStateSearch(true);
        setSearchQuery(e.target.value);
        if (e.target.value.trim() === '') {
            setSearchData(displayUniSubs);
            setSearchError("");
        } else {
            filterData(e.target.value);
        }
    };

    const filterData = (query) => {
        const filteredData = displayUniSubs.filter(item =>
            item.university_name.toLowerCase().includes(query.toLowerCase()) ||
            item.subscription_name.toLowerCase().includes(query.toLowerCase()) ||
            item.start_date.toString().toLowerCase().includes(query.toLowerCase()) ||
            item.end_date.toString().toLowerCase().includes(query.toLowerCase())
        );

        if (filteredData.length === 0) {
            setSearchError("No data found");
        } else {
            setSearchData(filteredData);
            setSearchError("");
        }
    };

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 25;
    const pageVisited = pageNumber * itemsPerPage;

    const displayUniSubs = uniSubs.slice(pageVisited, pageVisited + itemsPerPage);

    const pageCount = Math.ceil(uniSubs.length / itemsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a",
        '--cui-btn-padding-y': "5px"
    }

    const formattedDate = (inputDate) => {
        const [day, month, year] = inputDate.split('-');

        const abbreviatedMonth = new Date(Date.parse(`${year}-${month}-${day}`)).toLocaleDateString('en-US', { month: 'short' });
        const formatted_date = `${parseInt(day, 10)} ${abbreviatedMonth} ${year}`;

        return formatted_date;
    }

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <CHeader className="d-flex">
                    <CContainer>
                        <CHeaderToggler
                            className="ps-1"
                            onClick={() =>
                                dispatch({ type: "set", sidebarShow: !sidebarShow })
                            }
                        >
                            <CIcon icon={cilMenu} size="lg" />
                        </CHeaderToggler>
                        <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                            <h4 style={{ marginRight: '3vw' }}>University Access List</h4>
                            <CCol sm={4}>
                                <CForm className="d-flex">
                                    <CFormInput
                                        type="text"
                                        placeholder="Search"
                                        className="me-2 rounded-pill"
                                        style={{ width: "20vw" }}
                                        aria-label="Search"
                                        onChange={handleInputChange}
                                        value={searchQuery}
                                    />
                                    <span style={{
                                        marginLeft: "-3vw",
                                        marginTop: "1vh"
                                    }}><GoSearch /> </span>
                                </CForm>
                            </CCol>
                        </CHeaderNav>
                        <CHeaderNav>
                            <CButtonGroup>
                                <CButton
                                    style={btn} variant="outline"
                                    onClick={() => setShowAddUniSubsModal(true)}
                                >
                                    Add University Access
                                </CButton>
                            </CButtonGroup>
                        </CHeaderNav>
                    </CContainer>
                </CHeader>
                <div className="ms-4 mt-5 me-4">
                    <CTable striped>
                        <CTableHead style={tableHeader}>
                            <CTableRow>
                                <CTableHeaderCell style={{ width: '25%', paddingLeft: "3vw" }}>
                                    University Name
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: '30%' }}>
                                    Content Access
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: '15%' }}>
                                    Start Date
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: '15%' }}>
                                    End Date
                                </CTableHeaderCell>
                                <CTableHeaderCell>
                                    Actions
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody style={{ padding: "5px" }}>
                            {stateSearch ? (searchError !== "" ?
                                (<CTableRow className="text-center">
                                    <CTableDataCell colSpan={6}>{searchError}</CTableDataCell>
                                </CTableRow>) :
                                searchData && searchData.map((uni_subs, index) => (
                                    <CTableRow
                                        v-for="item in tableItems"
                                        key={index} >
                                        <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                            {uni_subs.university_name}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>
                                                <span>{uni_subs.subscription_name}</span>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>
                                                <span>{uni_subs.start_date}</span>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div>
                                                <span>{uni_subs.end_date}</span>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                            <div className="d-flex mt-2 cursor-pointer">
                                                <div
                                                    style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                    onClick={(e) => navigate(`/uni-subs-details?id=${uni_subs.university_subscription_mapping_id}`)}
                                                >
                                                    <CiViewList />
                                                </div>
                                                <div
                                                    style={{ color: "darkgreen", marginRight: "1vw" }}
                                                    onClick={(e) => updateUniSubs(e, uni_subs)}
                                                >
                                                    <AiTwotoneEdit />
                                                </div>
                                                <span style={{ color: "red", marginRight: "1vw" }}
                                                    onClick={(e) =>
                                                        handleUniSubsDeleteConfirmation(e, uni_subs)}
                                                >
                                                    <AiTwotoneDelete />
                                                </span>
                                            </div>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))
                            ) : isLoading ? <CTableRow>
                                <CTableDataCell className="text-center" colSpan="6">
                                    <CSpinner />
                                </CTableDataCell>
                            </CTableRow> : displayUniSubs.length === 0 ? <CTableRow>
                                <CTableDataCell className="text-center" colSpan="6">
                                    <span>No data to show</span>
                                </CTableDataCell>
                            </CTableRow> : displayUniSubs.map((uni_subs, index) => (
                                <CTableRow
                                    v-for="item in tableItems"
                                    key={index} >
                                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                        {uni_subs.university_name}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div>
                                            <span>{uni_subs.subscription_name}</span>
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div>
                                            <span>{formattedDate(uni_subs.start_date)}</span>
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div>
                                            <span>{formattedDate(uni_subs.end_date)}</span>
                                        </div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div className="d-flex mt-2 cursor-pointer">
                                            <div
                                                style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                onClick={(e) => navigate(`/uni-subs-details?id=${uni_subs.university_subscription_mapping_id}`)}
                                            >
                                                <CiViewList />
                                            </div>
                                            <div
                                                style={{ color: "darkgreen", marginRight: "1vw" }}
                                                onClick={(e) => updateUniSubs(e, uni_subs)}
                                            >
                                                <AiTwotoneEdit />
                                            </div>
                                            <span style={{ color: "red", marginRight: "1vw" }}
                                                onClick={(e) =>
                                                    handleUniSubsDeleteConfirmation(e, uni_subs)}
                                            >
                                                <AiTwotoneDelete />
                                            </span>
                                        </div>
                                    </CTableDataCell>
                                </CTableRow>
                            ))
                            }
                        </CTableBody>
                    </CTable>
                </div>
                {displayUniSubs.length > 0 && !searchError &&
                    (<CPagination align="center" aria-label="Page navigation example" className="cursor-pointer">
                        <CPaginationItem
                            aria-label="Previous"
                            onClick={() => setPageNumber(pageNumber - 1)}
                            disabled={pageNumber === 0}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </CPaginationItem>

                        {Array.from({ length: pageCount }, (_, index) => (
                            <CPaginationItem
                                key={index + 1}
                                active={pageNumber === index}
                                onClick={() => setPageNumber(index)}
                            >
                                {index + 1}
                            </CPaginationItem>
                        ))}

                        <CPaginationItem
                            aria-label="Next"
                            onClick={() => setPageNumber(pageNumber + 1)}
                            disabled={pageNumber === pageCount - 1}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </CPaginationItem>
                    </CPagination>)}
            </div>
            <AddUniSubs
                show={showAddUniSubsModal}
                onHide={() => { setShowAddUniSubsModal(false) }}
                addUniSubsFlag={handleAddUniSubsFlag}
            />
            <EditUniSubs
                show={showEditUniSubsModal}
                onHide={() => setShowEditUniSubsModal(false)}
                university_subscription={selectedUniSubs}
                editUniSubsFlag={handleEditUniSubsFlag}
            />
            <ConfirmationModal
                show={showUniSubsDeleteConfirmModal}
                handleClose={() => { setShowUniSubsDeleteConfirmModal(false) }}
                handleConfirm={(e) => deleteUniSubs(e, selectedUniSubs)}
            />
        </div>
    );
};

export default UniSubsList;
