import react, { useState, useEffect } from "react";
import {
    CForm,
    CCol,
    CFormInput,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CContainer,
    CFormTextarea,
    CSpinner,
    CButton,
    CTable, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell, CTableBody
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import axios from "axios";
import { API_BASE_URL, WEB_URL } from "../../../const/const";
import { useNavigate, useLocation } from "react-router-dom";
import AppSidebar from "../../../components/AppSidebar";
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import EditVideo from "./EditVideo";
import VideoPlayer from "./VideoPlayer";
import { useSelector, useDispatch } from "react-redux";
import './Video.css';
import { BreadCrumb } from 'primereact/breadcrumb';


const VideoDetails = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('video_id');

    const user = localStorage.getItem("user");
    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();

    const videoTypeMapping = {
        1: "H5P",
        2: "HTML5"
    }

    const segmentTypeMapping = {
        1: "Lecture",
        2: "Lab"
    }

    const [videoDetails, setVideoDetails] = useState([]);
    const [videoCourses, setVideoCourses] = useState([]);
    const [videoFaculty, setVideoFaculty] = useState([]);
    const [videoTags, setVideoTags] = useState([]);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        const [navItem] = document.querySelectorAll("[href='/video-list']");
        navItem.classList.add("active");

        const navGroup = document.querySelector(".nav-group");
        navGroup.classList.add("show")

        console.log("auth user", user)
        const token = JSON.parse(user).token;

        const getVideoDetails = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/get-videos/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("videos-details", response);
                setVideoDetails(response.data.data);
                setVideoCourses(response.data.videoCourseData);
                setVideoFaculty(response.data.videoFacultyData);
                setVideoTags(response.data.videoTagsData);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching video-details:", error);
            }
        };
        if (user) {
            getVideoDetails();
        }
        setFlag(false);
    }, [flag]);


    const [showPlayVideo, setShowPlayVideo] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const playVideo = async () => {
        setShowPlayVideo(true);
        setSelectedVideo(videoDetails);
    }

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedVideoTags, setSelectedVideoTags] = useState(null);
    const [selectedVideoCourses, setSelectedVideoCourses] = useState(null);
    const [selectedVideoFaculty, setSelectedVideoFaculty] = useState(null);

    const updateVideo = async () => {
        setShowEditModal(true);
        setSelectedVideo(videoDetails);
        setSelectedVideoCourses(videoCourses);
        setSelectedVideoFaculty(videoFaculty);
        setSelectedVideoTags(videoTags);
    }

    const handleEditVideoFlag = (editVideoFlag) => {
        console.log("edit state", editVideoFlag)
        setFlag(editVideoFlag);
    }

    const [videoDeleteConfirmModal, setVideoDeleteConfirmModal] = useState(false);

    const handleVideoDeleteConfirmation = (e, videoId) => {
        setVideoDeleteConfirmModal(true);
        setSelectedVideo(videoId);
    }

    const deleteVideo = async (e, videoId) => {
        try {
            e.preventDefault();
            setVideoDeleteConfirmModal(false);
            const token = JSON.parse(user).token;
            const response = await axios.delete(`${API_BASE_URL}/delete-video/${videoId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            navigate("/video-list")
        } catch (error) {
            console.log(error);
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a",
        "margin-left": "1vw"
    }

    const playbtn = {
        '--cui-btn-bg': "green",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "green",
        '--cui-btn-active-bg': "green"
    }

    const deletebtn = {
        '--cui-btn-bg': "red",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "red",
        '--cui-btn-active-bg': "red",
        "margin-left": "1vw"
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const items = [{ label: `${videoDetails.video_title}` }];
    const home = { label: 'Home', url: `${WEB_URL}/video-list` }

    return (
        <>
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <CHeader className="d-flex">
                        <CContainer>
                            <CHeaderToggler
                                className="ps-1"
                                onClick={() =>
                                    dispatch({ type: "set", sidebarShow: !sidebarShow })
                                }
                            >
                                <CIcon icon={cilMenu} size="lg" />
                            </CHeaderToggler>
                            <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                                <h4 className="ms-5">{videoDetails && videoDetails.video_title}</h4>
                            </CHeaderNav>
                        </CContainer>
                    </CHeader>
                    {isLoading ?
                        <div style={{
                            marginTop: "10vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CSpinner />
                        </div> : <div className="mt-5 mx-5">
                            <BreadCrumb model={items} home={home} />
                            <CForm className="row g-3">
                                <CCol md={12}>
                                    <table>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="video-detail-label">Video Title:</td>
                                                <td className="video-detail-value">{videoDetails && videoDetails.video_title}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="video-detail-label">Video URL:</td>
                                                <td className="video-detail-value">{videoDetails && videoDetails.video_url}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="video-detail-label">Video Type:</td>
                                                <td className="video-detail-value">{videoDetails && videoTypeMapping[videoDetails.video_type]}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="video-detail-label">Segment Type:</td>
                                                <td className="video-detail-value">{videoDetails && segmentTypeMapping[videoDetails.segment_type]}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="video-detail-label">Video Description:</td>
                                                <td className="video-detail-value" style={{ marginLeft: "5vw" }}>{videoDetails && videoDetails.description}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CCol>


                                {videoCourses && videoCourses.length > 0 && (<div>
                                    <span style={{ fontWeight: "bold" }}>Video Courses:</span>
                                    <CTable striped style={{ width: '50vw', marginTop: "1vw" }}>
                                        <CTableHead style={tableHeader}>
                                            <CTableRow>
                                                <CTableHeaderCell style={{ width: '70%', paddingLeft: "3vw" }}>
                                                    Course Title
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    Actions
                                                </CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody style={{ padding: "5px" }}>
                                            {videoCourses && videoCourses.map((course, index) => (
                                                <CTableRow
                                                    v-for="item in tableItems"
                                                    key={index} >
                                                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                                        <div>
                                                            <span>{course.name}</span>
                                                        </div>
                                                    </CTableDataCell>
                                                    <CTableDataCell>
                                                        <div style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                            onClick={(e) => navigate(`/course-details?course_id=${course.course_id}`)}>
                                                            View
                                                        </div>
                                                    </CTableDataCell>
                                                </CTableRow>
                                            ))
                                            }
                                        </CTableBody>
                                    </CTable>
                                </div>
                                )}

                                {videoFaculty && videoFaculty.length > 0 && (<div>
                                    <span style={{ fontWeight: "bold" }}>Video Faculty:</span>
                                    <CTable striped style={{ width: '50vw', marginTop: "1vw" }}>
                                        <CTableHead style={tableHeader}>
                                            <CTableRow>
                                                <CTableHeaderCell style={{ width: '70%', paddingLeft: "3vw" }}>
                                                    Faculty Name
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    Actions
                                                </CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody style={{ padding: "5px" }}>
                                            {videoFaculty && videoFaculty.map((faculty, index) => (
                                                <CTableRow
                                                    v-for="item in tableItems"
                                                    key={index} >
                                                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                                        <div>
                                                            <span>{`${faculty.first_name} ${faculty.last_name}`}</span>
                                                        </div>
                                                    </CTableDataCell>
                                                    <CTableDataCell>
                                                        <div style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                            onClick={(e) => navigate(`/faculty-details?faculty_id=${faculty.faculty_id}`)}>
                                                            View
                                                        </div>
                                                    </CTableDataCell>
                                                </CTableRow>
                                            ))
                                            }
                                        </CTableBody>
                                    </CTable>
                                </div>
                                )}

                                {videoTags && videoTags.length > 0 && (<table>
                                    <tbody>
                                        <tr className="table-row">
                                            <td className="video-detail-label">Video Tags:</td>
                                            <td className="video-detail-value">
                                                {videoTags.map((tag, index) => (
                                                    <div key={index}>
                                                        {tag.tag_name}
                                                        {index < videoTags.length - 1 && ', '}
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>)}

                            </CForm>
                            <div className="d-flex justify-content-end my-3">
                                {videoDetails.status === 1 && <CButton
                                    style={playbtn}
                                    onClick={playVideo}
                                >Play video
                                </CButton>}
                                <CButton
                                    style={btn}
                                    onClick={updateVideo}
                                >
                                    Edit Details
                                </CButton>
                                <CButton
                                    style={deletebtn}
                                    onClick={(e) => handleVideoDeleteConfirmation(e, videoDetails.video_id)}>
                                    Delete
                                </CButton>
                            </div>
                        </div>}
                </div>
                {showPlayVideo && (<VideoPlayer
                    show={showPlayVideo}
                    onHide={() => setShowPlayVideo(false)}
                    video={selectedVideo}
                />)}

                <EditVideo
                    show={showEditModal}
                    onHide={() => setShowEditModal(false)}
                    video={selectedVideo}
                    videoCourses={selectedVideoCourses}
                    videoFaculty={selectedVideoFaculty}
                    videoTags={selectedVideoTags}
                    editVideoFlag={handleEditVideoFlag}
                />
                <ConfirmationModal
                    show={videoDeleteConfirmModal}
                    handleClose={() => { setVideoDeleteConfirmModal(false) }}
                    handleConfirm={(e) => deleteVideo(e, selectedVideo)}
                />
            </div>

        </>
    );
};

export default VideoDetails;
