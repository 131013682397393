import react, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CInputGroup,
    CFormSelect,
    CFormLabel,
    CInputGroupText,
    CFormCheck,
    CButton,
    CFormFeedback,
} from "@coreui/react";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";
import { useNavigate } from "react-router-dom";

const EditFaculty = (props) => {
    const navigate = useNavigate()

    const handleShow = () => {
        setErrors({});
    };

    const FacultyData = props.faculty;
    console.log("Faculty data", FacultyData);

    const initialFormData = {
        credential: FacultyData ? FacultyData.credential : "",
        first_name: FacultyData ? FacultyData.first_name : "",
        last_name: FacultyData ? FacultyData.last_name : "",
        email: FacultyData ? FacultyData.email : "",
        phone_number: FacultyData ? FacultyData.phone_number : "",
        address1: FacultyData ? FacultyData.address1 : "",
        address2: FacultyData ? FacultyData.address2 : "",
        city: FacultyData ? FacultyData.city : "",
        state: FacultyData ? FacultyData.state : "",
        country: FacultyData ? FacultyData.country : "",
        zipcode: FacultyData ? FacultyData.zipcode : ""
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormData(initialFormData);
    }, [FacultyData])

    const [countries, setCountries] = useState();
    const user = localStorage.getItem("user");

    useEffect(() => {
        const getCountries = async () => {
            try {
                console.log("auth user", user)
                const token = JSON.parse(user).token;
                const response = await axios.get(`${API_BASE_URL}/get-countries`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("countries list", response);
                setCountries(response.data.data);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        }

        if (user) {
            getCountries();
        }
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("field value", name, value)
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const [flag, setFlag] = useState(false);

    const handleEditFacultyFlag = (flag) => {
        console.log("edit Faculty flag", flag)
        flag = true;
        props.editFacultyFlag(flag);
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            console.log("auth user", user)
            const token = JSON.parse(user).token;

            console.log("fields", formData);
            const response = await axios.put(`${API_BASE_URL}/update-faculty/${FacultyData.faculty_id}`,
                formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("successfully updated Faculty", response);
            handleEditFacultyFlag(setFlag(true));
            props.onHide();
        } catch (err) {
            console.log("error in editing Faculty details", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
            }
            if (err.response && err.response.status === 401) {
                navigate('/login');
            }
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <Modal
            {...props}
            onShow={handleShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
                style={{
                    backgroundColor: "#0e3f6a",
                    color: "white"
                }}
                closeButton
                closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="First Name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            isInvalid={!!errors.first_name}
                        />
                        <CFormFeedback type="invalid"
                            style={{ color: "red" }}>{errors.first_name}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Last Name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            isInvalid={!!errors.last_name}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.last_name}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Faculty Credential"
                            name="credential"
                            value={formData.credential}
                            onChange={handleChange}
                            isInvalid={!!errors.credential}
                        />
                        <CFormFeedback type="invalid"
                            style={{ color: "red" }}>{errors.credential}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="email"
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            disabled
                        />
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Phone Number"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            isInvalid={!!errors.phone_number}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.phone_number}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Address1"
                            name="address1"
                            value={formData.address1}
                            onChange={handleChange}
                            isInvalid={!!errors.address1}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.address1}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Address2"
                            name="address2"
                            value={formData.address2}
                            onChange={handleChange}
                        />
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="City"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            isInvalid={!!errors.city}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.city}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="State"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            isInvalid={!!errors.state}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.state}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="Country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            isInvalid={!!errors.country}
                        >
                            <option>Choose a country</option>
                            {countries && countries.map((country, index) => (
                                <option key={index}>
                                    {country.country_name}
                                </option>
                            ))
                            }
                        </CFormSelect>
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.country}</CFormFeedback>
                    </CCol>
                    <CCol md={6}>
                        <CFormInput
                            type="text"
                            label="Zip Code"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={handleChange}
                            isInvalid={!!errors.zipcode}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.zipcode}</CFormFeedback>
                    </CCol>
                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default EditFaculty;
