import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import axios from "axios";
import { API_BASE_URL } from "../../../const/const";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async (e) => {
    try {
      e.preventDefault();
      const response = await axios.post(`${API_BASE_URL}/forgot-password`, {
        email,
      });
      console.log(response);
      localStorage.setItem("reset-token", response.data.resetToken);
      setMessage(response.data.message);
      setLink(response.data.emailLink);
    } catch (error) {
      setError("Email not found");
    }
  };

  const btn = {
    '--cui-btn-bg': "#0e3f6a",
    '--cui-btn-color': "white",
    '--cui-btn-hover-bg': "#3c97cb",
    '--cui-btn-active-bg': "#0e3f6a"
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-6">
                {message.length != 0 ? (
                  <div style={{ color: "green", fontSize: "24px", display: 'flex', flexDirection: "column" }}>
                  <h3>{message}</h3>
                  <span style={{ color: "black", fontSize: "16px" }}>
                    Please click <a href={link} style={{ cursor: "pointer", 
                    color: "blue" }}>here</a> to reset your password
                  </span>
                </div>
                ) : (
                  <CCardBody>
                    <CForm onSubmit={handleForgotPassword}>
                      <h3>Forgot Password</h3>
                      <p className="text-medium-emphasis">
                        Please enter your email
                      </p>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                      <CInputGroup className="mb-3">
                        <CInputGroupText style={{ backgroundColor: "#0e3f6a", color: "white" }}>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={handleEmailChange}
                        />
                      </CInputGroup>

                      <CRow>
                        <CCol xs={6}>
                          <CButton
                            style={btn}
                            className="px-4"
                            type="submit"
                          >
                            Reset Password
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                )}
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
