import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
  CButton,
  CButtonGroup,
  CContainer,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CPagination,
  CPaginationItem,
  CSpinner,
  CCol,
  CForm,
  CFormInput
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPeople, cilMenu } from "@coreui/icons";

import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL } from "../../../const/const";
import axios from "axios";
import AddCourse from "./AddCourse";
import EditCourse from "./EditCourse";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import '../../styling/Pagination.css';
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import MouseOverPopup from '../../mouseOver/MouseOverPopup';
import { useNavigate } from "react-router-dom";
import { CiViewList } from "react-icons/ci";
import { GoSearch } from "react-icons/go";

const CoursesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const [showAddCourseModal, setShowAddCourseModal] = useState(false);
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);
  const [showCourseDeleteConfirmModal, setShowCourseDeleteConfirmModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseFaculty, setSelectedCourseFaculty] = useState(null);

  const [courses, setCourses] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [flag, setFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState();

  const user = localStorage.getItem("user");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("authenticated user", user)
    const token = JSON.parse(user).token;

    const getCourses = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_BASE_URL}/get-courses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Courses list", response);
        if (response) {
          setCourses(response.data.data);
          setFaculty(response.data.courseFacultyData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching Courses:", error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    if (user) {
      getCourses();
      setFlag(false);
    }
  }, [flag]);

  console.log("faculty list", faculty);

  const handleAddCourseFlag = (addCourseFlag) => {
    console.log("add state", addCourseFlag);
    setFlag(addCourseFlag);
  }

  const handleEditCourseFlag = (editCourseFlag) => {
    console.log("edit state", editCourseFlag)
    setFlag(editCourseFlag);
  }

  const facultyToBeUpdated = [];
  const updateCourse = async (e, course) => {
    faculty.map((faculty) => (
      course.course_id === faculty.course_id ?
        facultyToBeUpdated.push(faculty) : <></>
    ))

    console.log('course faculty', facultyToBeUpdated)
    setShowEditCourseModal(true);
    setSelectedCourse(course);
    setSelectedCourseFaculty(facultyToBeUpdated);
  }

  const handleCourseDeleteConfirmation = (e, course) => {
    setShowCourseDeleteConfirmModal(true);
    setSelectedCourse(course);
  }

  const deleteCourse = async (e, course) => {
    try {
      e.preventDefault();
      setShowCourseDeleteConfirmModal(false);
      const userAuth = localStorage.getItem("user");
      const token = JSON.parse(userAuth).token;
      const CourseId = course.course_id;
      console.log("Course id to be deleted", CourseId);
      const response = await axios.delete(`${API_BASE_URL}/delete-course/${CourseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log("success", response.data.message);
      setFlag(true);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  }

  const [stateSearch, setStateSearch] = useState();
  const [searchError, setSearchError] = useState("");
  const [searchData, setSearchData] = useState([]);

  const handleInputChange = (e) => {
    setStateSearch(true);
    setSearchQuery(e.target.value);
    if (e.target.value.trim() === '') {
      setSearchData(displayCourses);
      setSearchError("");
    } else {
      filterData(e.target.value);
    }
  };

  const filterData = (query) => {
    const filteredData = displayCourses.filter(item => {
      const filteredFaculty = faculty.filter(faculty => faculty.course_id === item.course_id);

      const facultyNames = filteredFaculty.map(faculty => `${faculty.first_name} ${faculty.last_name}`.toLowerCase());
      return (
        item.name.toLowerCase().includes(query.toLowerCase()) ||
        item.description.toLowerCase().includes(query.toLowerCase()) ||
        facultyNames.some(name => name.includes(query.toLowerCase()))
      )
    });

    if (filteredData.length === 0) {
      setSearchError("No data found");
    } else {
      setSearchData(filteredData);
      setSearchError("");
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 25;
  const pageVisited = pageNumber * itemsPerPage;

  const displayCourses = courses.slice(pageVisited, pageVisited + itemsPerPage);

  const pageCount = Math.ceil(courses.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }

  const truncateText = (text, wordsCount) => {
    const words = text.split(' ');
    const truncatedText = words.slice(0, wordsCount).join(' ');
    return truncatedText + (words.length > wordsCount ? '...' : '');
  }

  const tableHeader = {
    '--cui-table-bg': "#0e3f6a",
    '--cui-table-color': "white"
  }

  const btn = {
    '--cui-btn-bg': "#0e3f6a",
    '--cui-btn-color': "white",
    '--cui-btn-hover-bg': "#3c97cb",
    '--cui-btn-active-bg': "#0e3f6a",
    '--cui-btn-padding-y': "5px",
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <CHeader className="d-flex">
          <CContainer>
            <CHeaderToggler
              className="ps-1"
              onClick={() =>
                dispatch({ type: "set", sidebarShow: !sidebarShow })
              }
            >
              <CIcon icon={cilMenu} size="lg" />
            </CHeaderToggler>
            <CHeaderNav className="d-flex me-auto ms-3 mt-2">
              <h4 style={{ marginRight: '3vw' }}>Courses List</h4>
              <CCol sm={4}>
                <CForm className="d-flex">
                  <CFormInput
                    type="text"
                    placeholder="Search"
                    className="me-2 rounded-pill"
                    style={{ width: "20vw" }}
                    aria-label="Search"
                    onChange={handleInputChange}
                    value={searchQuery}
                  />
                  <span style={{
                    marginLeft: "-3vw",
                    marginTop: "1vh"
                  }}><GoSearch /> </span>
                </CForm>
              </CCol>
            </CHeaderNav>
            <CHeaderNav>
              <CButtonGroup>
                <CButton
                  style={btn} variant="outline"
                  onClick={() => setShowAddCourseModal(true)}
                >
                  Add Course
                </CButton>
              </CButtonGroup>
            </CHeaderNav>
          </CContainer>
        </CHeader>
        <div className="ms-4 mt-5 me-4" >
          <CTable striped>
            <CTableHead style={tableHeader}>
              <CTableRow>
                <CTableHeaderCell style={{ width: '30%', paddingLeft: "3vw" }}>
                  Course Name
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '30%' }}>
                  Description
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '25%' }}>
                  Course Faculty
                </CTableHeaderCell>
                <CTableHeaderCell>
                  Actions
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody style={{ padding: "5px" }}>
              {stateSearch ? (searchError !== "" ?
                (<CTableRow className="text-center">
                  <CTableDataCell colSpan={6}>{searchError}</CTableDataCell>
                </CTableRow>) :
                searchData && searchData.map((course, index) => (
                  <CTableRow
                    v-for="item in tableItems"
                    key={index}
                  >
                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                      <span>{course.name}</span>
                    </CTableDataCell>
                    <CTableDataCell style={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word'
                    }}>
                      <MouseOverPopup description={course.description}>
                        <span>{truncateText(course.description, 4)}</span>
                      </MouseOverPopup>
                    </CTableDataCell>
                    <CTableDataCell style={{ width: "15vw" }}>
                      {faculty.map((faculty, index) => (
                        course.course_id === faculty.course_id ? (
                          <div key={index}>
                            <span>{faculty.first_name} {faculty.last_name}</span>
                            <br /></div>) : null
                      ))}
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="d-flex mt-2">
                        <div
                          style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                          onClick={(e) => navigate(`/course-details?course_id=${course.course_id}`)}
                        >
                          <CiViewList />
                        </div>
                        <div
                          style={{ color: "darkgreen", marginRight: "1vw", cursor: "pointer" }}
                          onClick={(e) => updateCourse(e, course)}
                        >
                          <AiTwotoneEdit />
                        </div>
                        <span style={{ color: "red", marginRight: "1vw", cursor: "pointer" }}
                          onClick={(e) =>
                            handleCourseDeleteConfirmation(e, course)}
                        >
                          <AiTwotoneDelete />
                        </span>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                ))
              ) : isLoading ? <CTableRow>
                <CTableDataCell className="text-center" colSpan="6">
                  <CSpinner />
                </CTableDataCell>
              </CTableRow> :
                (displayCourses.length === 0 ? <CTableRow>
                  <CTableDataCell className="text-center" colSpan="6">
                    <span>No data to show</span>
                  </CTableDataCell>
                </CTableRow> : displayCourses.map((course, index) => (
                  <CTableRow
                    v-for="item in tableItems"
                    key={index}
                  >
                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                      <span>{course.name}</span>
                    </CTableDataCell>
                    <CTableDataCell style={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word'
                    }}>
                      <MouseOverPopup description={course.description}>
                        <span>{truncateText(course.description, 4)}</span>
                      </MouseOverPopup>
                    </CTableDataCell>
                    <CTableDataCell style={{ width: "15vw" }}>
                      {faculty.map((faculty, index) => (
                        course.course_id === faculty.course_id ? (
                          <div key={index}>
                            <span>{faculty.first_name} {faculty.last_name}</span>
                            <br /></div>) : null
                      ))}
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="d-flex mt-2">
                        <div
                          style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                          onClick={(e) => navigate(`/course-details?course_id=${course.course_id}`)}
                        >
                          <CiViewList />
                        </div>
                        <div
                          style={{ color: "darkgreen", marginRight: "1vw", cursor: "pointer" }}
                          onClick={(e) => updateCourse(e, course)}
                        >
                          <AiTwotoneEdit />
                        </div>
                        <span style={{ color: "red", marginRight: "1vw", cursor: "pointer" }}
                          onClick={(e) =>
                            handleCourseDeleteConfirmation(e, course)}
                        >
                          <AiTwotoneDelete />
                        </span>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                ))
                )}
            </CTableBody>
          </CTable>
        </div>
        {displayCourses.length > 0 && !searchError &&
          (<CPagination align="center" aria-label="Page navigation example" className="cursor-pointer">
            <CPaginationItem
              aria-label="Previous"
              onClick={() => setPageNumber(pageNumber - 1)}
              disabled={pageNumber === 0}
            >
              <span aria-hidden="true">&laquo;</span>
            </CPaginationItem>

            {Array.from({ length: pageCount }, (_, index) => (
              <CPaginationItem
                key={index + 1}
                active={pageNumber === index}
                onClick={() => setPageNumber(index)}
              >
                {index + 1}
              </CPaginationItem>
            ))}

            <CPaginationItem
              aria-label="Next"
              onClick={() => setPageNumber(pageNumber + 1)}
              disabled={pageNumber === pageCount - 1}
            >
              <span aria-hidden="true">&raquo;</span>
            </CPaginationItem>
          </CPagination>)}
      </div>
      <AddCourse
        show={showAddCourseModal}
        onHide={() => { setShowAddCourseModal(false) }}
        addCourseFlag={handleAddCourseFlag}
      />
      <EditCourse
        show={showEditCourseModal}
        onHide={() => setShowEditCourseModal(false)}
        course={selectedCourse}
        courseFaculty={selectedCourseFaculty}
        editCourseFlag={handleEditCourseFlag}
      />
      <ConfirmationModal
        show={showCourseDeleteConfirmModal}
        handleClose={() => { setShowCourseDeleteConfirmModal(false) }}
        handleConfirm={(e) => deleteCourse(e, selectedCourse)}
      />
    </div>
  );
};

export default CoursesList;
