import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

const useUploadStore = create((set) => ({
  files: [],
  addFiles: (newFiles) => set((state) => ({
    files: [
      ...state.files,
      ...newFiles.map(({ file, signedUrl }) => ({
        id: uuidv4(),
        file,
        signedUrl
      }))
    ]
  })),
  cleanFiles: () => set({ files: [] })
}));

export default useUploadStore;
