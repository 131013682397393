import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
  CButton,
  CButtonGroup,
  CContainer,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CPagination, CPaginationItem, CSpinner, CCol, CForm, CFormInput
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPeople, cilMenu } from "@coreui/icons";

import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL } from "../../../const/const";
import axios from "axios";
import randomColor from "randomcolor";
import AddNewUser from "./AddNewUser";
import EditUser from "./EditUser";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import '../../styling/Pagination.css';
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import { GoSearch } from "react-icons/go";

const UserList = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUserDeleteConfirmModal, setShowUserDeleteConfirmModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [users, setUsers] = useState([]);
  const [flag, setFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState();

  const user = localStorage.getItem("user");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("auth user", user)
    const token = JSON.parse(user).token;

    const getUsers = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_BASE_URL}/get-users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("users list", response);
        setUsers(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (user) {
      getUsers();
      setFlag(false);
    }
  }, [flag]);

  const handleAddFlagState = (addFlagState) => {
    console.log("add state", addFlagState)
    setFlag(addFlagState);
  }

  const handleEditUserFlag = (editUserFlag) => {
    console.log("edit state", editUserFlag)
    setFlag(editUserFlag);
  }

  const updateUser = async (e, user) => {
    setShowEditModal(true);
    setSelectedUser(user);
  }

  const handleUserDeleteConfirmation = (e, user) => {
    setShowUserDeleteConfirmModal(true);
    setSelectedUser(user);
  }

  const deleteUser = async (e, user) => {
    try {
      e.preventDefault();
      setShowUserDeleteConfirmModal(false);
      const userAuth = localStorage.getItem("user");
      const token = JSON.parse(userAuth).token;
      const user_id = user.user_id;
      console.log("user id to be deleted", user_id);
      const response = await axios.delete(`${API_BASE_URL}/delete-user/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log("success", response.data.message);
      setFlag(true);
    } catch (error) {
      console.log(error)
    }
  }

  const [stateSearch, setStateSearch] = useState();
  const [searchError, setSearchError] = useState("");
  const [searchData, setSearchData] = useState([]);

  const handleInputChange = (e) => {
    setStateSearch(true);
    setSearchQuery(e.target.value);
    if (e.target.value.trim() === '') {
      setSearchData(displayUsers);
      setSearchError("");
    } else {
      filterData(e.target.value);
    }
  };

  const filterData = (query) => {
    const filteredData = displayUsers.filter(item =>
      item.first_name.toLowerCase().includes(query.toLowerCase()) ||
      item.last_name.toLowerCase().includes(query.toLowerCase()) ||
      item.email.toLowerCase().includes(query.toLowerCase()) ||
      item.phone_number.toString().toLowerCase().includes(query.toLowerCase())
    );

    if (filteredData.length === 0) {
      setSearchError("No data found");
    } else {
      setSearchData(filteredData);
      setSearchError("");
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 25;
  const pageVisited = pageNumber * itemsPerPage;

  const displayUsers = users.slice(pageVisited, pageVisited + itemsPerPage);

  const pageCount = Math.ceil(users.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }

  const tableHeader = {
    '--cui-table-bg': "#0e3f6a",
    '--cui-table-color': "white"
  }

  const btn = {
    '--cui-btn-bg': "#0e3f6a",
    '--cui-btn-color': "white",
    '--cui-btn-hover-bg': "#3c97cb",
    '--cui-btn-active-bg': "#0e3f6a",
    '--cui-btn-padding-y': "5px"
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <CHeader className="d-flex">
          <CContainer>
            <CHeaderToggler
              className="ps-1"
              onClick={() =>
                dispatch({ type: "set", sidebarShow: !sidebarShow })
              }
            >
              <CIcon icon={cilMenu} size="lg" />
            </CHeaderToggler>
            <CHeaderNav className="d-flex me-auto ms-3 mt-2">
              <h4 style={{ marginRight: '3vw' }}>Users List</h4>
              <CCol sm={4}>
                <CForm className="d-flex">
                  <CFormInput
                    type="text"
                    placeholder="Search"
                    className="me-2 rounded-pill"
                    style={{ width: "20vw" }}
                    aria-label="Search"
                    onChange={handleInputChange}
                    value={searchQuery}
                  />
                  <span style={{
                    marginLeft: "-3vw",
                    marginTop: "1vh"
                  }}><GoSearch /> </span>
                </CForm>
              </CCol>
            </CHeaderNav>
            <CHeaderNav>
              <CButtonGroup>
                <CButton
                  style={btn} variant="outline"
                  onClick={() => setShowAddModal(true)}
                >
                  Add User
                </CButton>
              </CButtonGroup>
            </CHeaderNav>
          </CContainer>
        </CHeader>
        <div
          className="ms-4 mt-5 me-4" >
          <CTable
            striped>
            <CTableHead style={tableHeader}>
              <CTableRow>
                <CTableHeaderCell style={{ width: '10%', paddingLeft: "3vw" }}>
                  <CIcon icon={cilPeople} />
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '20%' }}>
                  Name
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '25%' }}>
                  Email
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '15%' }}>
                  Phone Number
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '20%' }}>
                  Address
                </CTableHeaderCell>
                <CTableHeaderCell>
                  Actions
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody style={{ padding: "5px" }}>
              {stateSearch ? (searchError !== "" ?
                (<CTableRow className="text-center">
                  <CTableDataCell colSpan={6}>{searchError}</CTableDataCell>
                </CTableRow>) :
                searchData.length > 0 && searchData.map((user, index) => (
                  <CTableRow
                    v-for="item in tableItems"
                    key={index}
                  >
                    <CTableDataCell style={{ paddingLeft: "2vw" }}>
                      <div
                        style={{
                          backgroundColor: randomColor(),
                          fontFamily: "initial",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {user.first_name.charAt(0)}
                        {user.last_name.charAt(0)}
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>
                      <span>
                        {user.first_name} {user.last_name}
                      </span>
                    </CTableDataCell>
                    <CTableDataCell>
                      <span>{user.email}</span>
                    </CTableDataCell>
                    <CTableDataCell>
                      <span>{user.phone_number}</span>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div>
                        <span>
                          {user.address1} {user.address2}, {user.city},<br />{" "}
                          {user.state} {user.country}
                        </span>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="d-flex mt-2 cursor-pointer">
                        <div
                          style={{ color: "darkgreen", marginRight: "1vw" }}
                          onClick={(e) => updateUser(e, user)}
                        >
                          <AiTwotoneEdit />
                        </div>
                        <span style={{ color: "red", marginRight: "1vw" }}
                          onClick={(e) => handleUserDeleteConfirmation(e, user)}>
                          <AiTwotoneDelete />
                        </span>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                ))
              ) : isLoading ? <CTableRow>
                <CTableDataCell className="text-center" colSpan="6">
                  <CSpinner />
                </CTableDataCell>
              </CTableRow> :
                displayUsers.length === 0 ? (
                  <CTableRow>
                    <CTableDataCell className="text-center" colSpan="6">
                      <span>No data to show</span>
                    </CTableDataCell>
                  </CTableRow>
                ) : displayUsers.map((user, index) => (
                  <CTableRow
                    v-for="item in tableItems"
                    key={index}
                  >
                    <CTableDataCell style={{ paddingLeft: "2vw" }}>
                      <div
                        style={{
                          backgroundColor: randomColor(),
                          fontFamily: "initial",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {user.first_name.charAt(0)}
                        {user.last_name.charAt(0)}
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>
                      <span>
                        {user.first_name} {user.last_name}
                      </span>
                    </CTableDataCell>
                    <CTableDataCell>
                      <span>{user.email}</span>
                    </CTableDataCell>
                    <CTableDataCell>
                      <span>{user.phone_number}</span>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div>
                        <span>
                          {user.address1} {user.address2}, {user.city},<br />{" "}
                          {user.state} {user.country}
                        </span>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="d-flex mt-2 cursor-pointer">
                        <div
                          style={{ color: "darkgreen", marginRight: "1vw" }}
                          onClick={(e) => updateUser(e, user)}
                        >
                          <AiTwotoneEdit />
                        </div>
                        <span style={{ color: "red", marginRight: "1vw" }}
                          onClick={(e) => handleUserDeleteConfirmation(e, user)}>
                          <AiTwotoneDelete />
                        </span>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                ))
              }
            </CTableBody>
          </CTable>
        </div>
        {displayUsers.length > 0 && !searchError &&
          (<CPagination align="center" aria-label="Page navigation example" className="cursor-pointer">
            <CPaginationItem
              aria-label="Previous"
              onClick={() => setPageNumber(pageNumber - 1)}
              disabled={pageNumber === 0}
            >
              <span aria-hidden="true">&laquo;</span>
            </CPaginationItem>

            {Array.from({ length: pageCount }, (_, index) => (
              <CPaginationItem
                key={index + 1}
                active={pageNumber === index}
                onClick={() => setPageNumber(index)}
              >
                {index + 1}
              </CPaginationItem>
            ))}

            <CPaginationItem
              aria-label="Next"
              onClick={() => setPageNumber(pageNumber + 1)}
              disabled={pageNumber === pageCount - 1}
            >
              <span aria-hidden="true">&raquo;</span>
            </CPaginationItem>
          </CPagination>)}
      </div>
      <AddNewUser
        show={showAddModal}
        onHide={() => { setShowAddModal(false) }}
        addFlag={handleAddFlagState} />
      <EditUser
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        user={selectedUser}
        editUserFlag={handleEditUserFlag}
      />
      <ConfirmationModal
        show={showUserDeleteConfirmModal}
        handleClose={() => { setShowUserDeleteConfirmModal(false) }}
        handleConfirm={(e) => deleteUser(e, selectedUser)}
      />
    </div>
  );
};

export default UserList;
